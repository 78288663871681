import { useEffect, useMemo, useRef, useState } from "react";

import { setTimeoutType } from "../../types/common/general";
import { ActivePanelKeys } from "../../types/panels/sidebar";

import { SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";
import { SAVED_SEARCH_VISIBILITY_PUBLIC } from "../../constants/panels/savedSearchPanel/hooks";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useStore from "../../store/useStore";

import { sortWellsDesc } from "../../utils/panel/wellPanel/sorting";

import useSearchCriteria from "../search/useSearchCriteria";
import useSearchPanel from "../search/useSearchPanel";
import useShareSearch from "../shareSearch/useShareSearch";

const useLeftNavPanel = () => {
  const setTimeOutAlertRef = useRef<setTimeoutType | null>(null);

  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

  const selectedSavedSearchData = useStore(
    (state) => state.selectedSavedSearchData
  );

  const wellInfoList = usePanelsStore((state) => state.wellList);
  const permitsInfoList = usePanelsStore((state) => state.permitsList);
  const activePanel = usePanelsStore((state) => state.activePanel);
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const updateActiveWellPanel = usePanelsStore(
    (state) => state.updateActiveWellPanel
  );
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );
  const updateActivePermitPanel = usePanelsStore(
    (state) => state.updateActivePermitPanel
  );

  const updateExportPanelMenuTrigger = useExportCardStore(
    (state) => state.updateExportPanelMenuTrigger
  );

  const { hasSearchCriteria } = useSearchCriteria();

  const { searchTypeByActiveTab, isFilterIncluded } = useSearchPanel();
  const { handleCopyLinkClick, savedSearchDataLoading } = useShareSearch({
    searchType: searchTypeByActiveTab,
    isFilterIncluded,
  });

  const wellsAndPermitPanel = useMemo(() => {
    return [...wellInfoList, ...permitsInfoList];
  }, [
    wellInfoList,
    permitsInfoList,
    wellInfoList.length,
    permitsInfoList.length,
  ]);

  const getIsDisabledMenu = (menuText: ActivePanelKeys) => {
    switch (menuText) {
      case SIDEBAR_MENU_KEYS.EXPORT_PACKAGE_MANAGER:
        if (
          selectedSavedSearchData.visibility ===
            SAVED_SEARCH_VISIBILITY_PUBLIC ||
          !hasSearchCriteria
        )
          return true;
        return false;
      case SIDEBAR_MENU_KEYS.SHARE_SEARCH:
        return !hasSearchCriteria || savedSearchDataLoading;
      default:
        return false;
    }
  };

  const handleMenuClick = (
    menuText: ActivePanelKeys,
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => {
    switch (menuText) {
      case SIDEBAR_MENU_KEYS.EXPORT_PACKAGE_MANAGER:
        break;
      case SIDEBAR_MENU_KEYS.SHARE_SEARCH:
        handleCopyLinkClick();
        break;
      default:
        if (activePanel === SIDEBAR_MENU_KEYS.WELL_PANEL) {
          updateActiveWellPanel(false, undefined);
        }
        updateExportPanelMenuTrigger(false);

        if (activePanel !== menuText) {
          updateActivePanel(menuText);
          toggleLeftSidePanel(true);
          const parentEl = document.querySelector(
            ".sidebar-drawer-style .MuiPaper-root"
          ) as HTMLElement;
          if (parentEl) {
            parentEl.style.width = "50px";
          }
        } else {
          toggleLeftSidePanel();
        }
        break;
    }
  };

  const handleOnMouseEnter = (e: React.MouseEvent) => {
    const childPaperEl = e.currentTarget.querySelector(".MuiDrawer-paper");
    if (childPaperEl) childPaperEl.removeAttribute("style");
    setIsNavbarExpanded(true);
  };

  const handleOnMouseOver = () => {
    setTimeOutAlertRef.current = setTimeout(() => {
      const sidebarEl = document.querySelector(
        ".sidebar-drawer-style .MuiDrawer-paper"
      );
      if (sidebarEl?.getAttribute("style") === "width: 50px;") {
        sidebarEl.removeAttribute("style");
      }
    }, 250);
    setIsNavbarExpanded(true);
  };

  const handleOnMouseOut = () => {
    if (setTimeOutAlertRef.current) {
      clearTimeout(setTimeOutAlertRef.current);
      setTimeOutAlertRef.current = null;
      setIsNavbarExpanded(false);
    }
  };

  const sortedWellsAndPermitPanel = useMemo(
    () =>
      wellsAndPermitPanel.length ? sortWellsDesc(wellsAndPermitPanel) : [],
    [wellsAndPermitPanel, wellsAndPermitPanel.length]
  );

  useEffect(() => {
    if (sortedWellsAndPermitPanel.length > 0) {
      const nonActive = sortedWellsAndPermitPanel.every(
        (wellPermitData) => !wellPermitData.isActive
      );

      if (nonActive) {
        if (sortedWellsAndPermitPanel[0].recordType === RECORD_TYPES.PERMIT) {
          updateActivePermitPanel(true, sortedWellsAndPermitPanel[0]);
        } else if (
          sortedWellsAndPermitPanel[0].recordType === RECORD_TYPES.WELL
        ) {
          updateActiveWellPanel(true, sortedWellsAndPermitPanel[0]);
        }
      }
    }
  }, [sortedWellsAndPermitPanel, sortedWellsAndPermitPanel.length]);

  useEffect(() => {
    if (
      !wellsAndPermitPanel.length &&
      activePanel === SIDEBAR_MENU_KEYS.WELL_PANEL
    ) {
      updateActivePanel(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS);
    }
  }, [wellsAndPermitPanel.length]);

  return {
    wellsAndPermitPanel,
    sortedWellsAndPermitPanel,
    isNavbarExpanded,
    handleMenuClick,
    handleOnMouseOut,
    handleOnMouseOver,
    handleOnMouseEnter,
    getIsDisabledMenu,
  };
};

export default useLeftNavPanel;
