import { useEffect, useMemo } from "react";

import {
  ATTRIBUTES_TAB,
  IDENTIFIER_TAB,
} from "../../../constants/panels/searchPanel/common/accordion";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useSearchPanelStore from "../../../store/search/panel/useSearchPanelStore";
import useSearchPermitsStore from "../../../store/search/permits/searchPermitsStore";

import useSearchCountAttribute from "../../../customHooks/search/useSearchCountAttribute";
import useSearchCountIdentifier from "../../../customHooks/search/useSearchCountIdentifier";

import SearchAttributesContent from "./attributes/SearchAttributesContent";
import AccordionActionButtons from "./common/AccordionActionButtons";
import SearchPanelAccordion from "./common/SearchPanelAccordion";
import SearchIdentifierContent from "./identifier/SearchIdentifierContent";

const SearchPermitsAccordion = () => {
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const searchAttributeCount = useSearchPermitsStore(
    (state) => state.searchAttributeCount
  );
  const searchIdentifierCount = useSearchPermitsStore(
    (state) => state.searchIdentifierCount
  );
  const searchTypeTab = useSearchPermitsStore((state) => state.searchTypeTab);
  const isSearchAccordionToggledOn = useSearchPermitsStore(
    (state) => state.isSearchAccordionToggledOn
  );
  const updateSearchTypeTab = useSearchPermitsStore(
    (state) => state.updateSearchTypeTab
  );
  const toggleIsSearchAccordionToggledOn = useSearchPermitsStore(
    (state) => state.toggleIsSearchAccordionToggledOn
  );

  useSearchCountAttribute({ searchRecordType: RECORD_TYPES.PERMIT });
  useSearchCountIdentifier({
    searchRecordType: RECORD_TYPES.PERMIT,
  });

  const searchCount = useMemo(() => {
    if (searchTypeTab === ATTRIBUTES_TAB) return searchAttributeCount;
    if (searchTypeTab === IDENTIFIER_TAB) return searchIdentifierCount;
    return { count: 0, isLoading: false };
  }, [searchTypeTab, searchAttributeCount, searchIdentifierCount]);

  useEffect(() => {
    if (openedQBEditorPanel !== RECORD_TYPES.PERMIT) return;

    if (searchTypeTab !== ATTRIBUTES_TAB) {
      updateOpenedQBEditorPanel(null);
    }
  }, [searchTypeTab]);

  return (
    <SearchPanelAccordion
      title="Permits"
      searchRecordType={RECORD_TYPES.PERMIT}
      searchCount={searchCount.count}
      isSearchCountLoading={searchCount.isLoading}
      isSearchToggledOn={isSearchAccordionToggledOn}
      toggleIsSearchToggledOn={toggleIsSearchAccordionToggledOn}
    >
      <AccordionActionButtons
        searchRecordType={RECORD_TYPES.PERMIT}
        searchTypeTab={searchTypeTab}
        updateSearchTypeTab={updateSearchTypeTab}
      />
      <div
        hidden={searchTypeTab !== ATTRIBUTES_TAB}
        className="accordion-search-fields-container"
      >
        <SearchAttributesContent searchRecordType={RECORD_TYPES.PERMIT} />
      </div>
      <div
        hidden={searchTypeTab !== IDENTIFIER_TAB}
        className="accordion-search-fields-container"
      >
        <SearchIdentifierContent searchRecordType={RECORD_TYPES.PERMIT} />
      </div>
    </SearchPanelAccordion>
  );
};

export default SearchPermitsAccordion;
