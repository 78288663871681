import { useCallback, useMemo } from "react";

import { IconButton } from "@mui/material";

import classNames from "classnames";

import { WellData } from "../../types/common/wells";
import { GridColumns } from "../../types/grid";
import { WellPanelGridStates } from "../../types/panels/wellPanel/wellPanel";

import { COLUMN_TYPE_BOOLEAN } from "../../constants/grid";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";
import {
  NOT_OPENED_WELL_SYSTEM_OR_PERMIT,
  OPENED_ACTIVE_WELL_OR_PERMIT,
  OPENED_ACTIVE_WELL_SYSTEM_OR_PERMIT,
  OPENED_INACTIVE_WELL_SYSTEM_OR_PERMIT,
} from "../../constants/panels/wellPanel/wellPanel";

import {
  WellPanelNotOpenedIcon,
  WellPanelOpenedActiveIcon,
  WellPanelOpenedInactiveIcon,
} from "../../components/common/icons/grid/WellPanelIcons";

import useDataGridStore from "../../store/grid/dataGridStore";
import usePanelsStore from "../../store/panels/panelsStore";

import {
  convertPermitIdToHeaderKey,
  convertWellIdToHeaderKey,
} from "../../utils/common/wells";

import usePermitPanelViewAction from "../panels/permitsPanel/usePermitPanelViewAction";
import useWellPanelViewAction from "../panels/wellPanel/useWellPanelViewAction";

const useGridWellPanel = () => {
  const permitsList = usePanelsStore((state) => state.permitsList);
  const wellList = usePanelsStore((state) => state.wellList);
  const allWellSelectors = useDataGridStore((state) => state.allWellSelectors);

  const { handleOpenWellPanelViaGrid } = useWellPanelViewAction();
  const { handleOpenPermitPanelViaGrid } = usePermitPanelViewAction();

  const wellsPermitsList = useMemo(() => {
    const wellIds = wellList.flatMap((wellPanelInfo) =>
      (wellPanelInfo.wellSystemData ?? []).map((systemData) =>
        convertWellIdToHeaderKey(systemData.wellId)
      )
    );
    const permitIds = permitsList.map(
      (permitPanelInfo) => permitPanelInfo.wellHeaderPermitKey
    );
    return [...wellIds, ...permitIds];
  }, [wellList, permitsList]);

  const getActiveWellSystem = useCallback(() => {
    const activeWellPanelInfo = wellList.find(
      (wellPanelInfo) => wellPanelInfo.isActive
    );
    if (!activeWellPanelInfo) return null;
    return activeWellPanelInfo;
  }, [wellList]);

  const activeWellPanelSystem = useMemo(() => {
    const activeWellPanelInfo = getActiveWellSystem();
    if (!activeWellPanelInfo) return [];

    const { groupedBWIDs, bottomWellBoreId } = activeWellPanelInfo;
    if (!groupedBWIDs?.length)
      return [convertWellIdToHeaderKey(bottomWellBoreId)];

    return groupedBWIDs.map((wellId) => convertWellIdToHeaderKey(wellId));
  }, [getActiveWellSystem]);

  const activeWellSystemPageId = useMemo(() => {
    const activeWellPanelInfo = getActiveWellSystem();
    if (!activeWellPanelInfo) return null;

    const { wellSystemData, wellId, activePage } = activeWellPanelInfo;
    if (!wellSystemData) return convertWellIdToHeaderKey(wellId);

    const activeWellSystemData = wellSystemData.find(
      (wellData) => wellData.pageNumber === activePage
    );
    if (!activeWellSystemData) return null;

    return convertWellIdToHeaderKey(activeWellSystemData.wellId);
  }, [getActiveWellSystem]);

  const activePermitPanelId = useMemo(() => {
    const activePermitPanelInfo = permitsList.find(
      (permitPanelInfo) => permitPanelInfo.isActive
    );

    if (!activePermitPanelInfo) return null;
    return convertPermitIdToHeaderKey(activePermitPanelInfo.permitID);
  }, [permitsList]);

  const getIsOpenedActiveWellOrPermit = useCallback(
    (wellHeaderPermitKey: string) =>
      wellHeaderPermitKey === activeWellSystemPageId ||
      wellHeaderPermitKey === activePermitPanelId,
    [activeWellSystemPageId, activePermitPanelId]
  );

  const getIsOpenedActiveWellSystemOrPermit = useCallback(
    (wellHeaderPermitKey: string, bottomWellBoreId: string) =>
      activeWellPanelSystem.includes(bottomWellBoreId) ||
      wellHeaderPermitKey === activePermitPanelId,
    [activeWellPanelSystem, activePermitPanelId]
  );

  const getIsOpenedInactiveWellSystemOrPermit = useCallback(
    (wellHeaderPermitKey: string, bottomWellBoreId: string) =>
      wellsPermitsList.includes(wellHeaderPermitKey) &&
      !getIsOpenedActiveWellSystemOrPermit(
        wellHeaderPermitKey,
        bottomWellBoreId
      ),
    [wellsPermitsList, getIsOpenedActiveWellSystemOrPermit]
  );

  const getWellPanelState = useCallback(
    (wellHeaderPermitKey: string, bottomWellBoreId: string) => {
      if (getIsOpenedActiveWellOrPermit(wellHeaderPermitKey)) {
        return OPENED_ACTIVE_WELL_OR_PERMIT;
      } else if (
        getIsOpenedActiveWellSystemOrPermit(
          wellHeaderPermitKey,
          bottomWellBoreId
        )
      ) {
        return OPENED_ACTIVE_WELL_SYSTEM_OR_PERMIT;
      } else if (
        getIsOpenedInactiveWellSystemOrPermit(
          wellHeaderPermitKey,
          bottomWellBoreId
        )
      ) {
        return OPENED_INACTIVE_WELL_SYSTEM_OR_PERMIT;
      } else {
        return NOT_OPENED_WELL_SYSTEM_OR_PERMIT;
      }
    },
    [
      getIsOpenedActiveWellOrPermit,
      getIsOpenedActiveWellSystemOrPermit,
      getIsOpenedInactiveWellSystemOrPermit,
    ]
  );

  const handleOpenWellOrPermitPanel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    wellPanelState: WellPanelGridStates,
    wellData: WellData
  ) => {
    e.stopPropagation();
    if (wellPanelState === OPENED_ACTIVE_WELL_OR_PERMIT) return;

    const isOpenedWellSystem =
      wellPanelState === OPENED_ACTIVE_WELL_SYSTEM_OR_PERMIT ||
      wellPanelState === OPENED_INACTIVE_WELL_SYSTEM_OR_PERMIT;
    const { RecordType, ParentWellID } = wellData;
    if (RecordType === RECORD_TYPES.WELL) {
      const latestWellData =
        allWellSelectors.groupedByParentWellID[ParentWellID];
      const groupedBWIDs =
        allWellSelectors.bottomWellboreIDByPWID[ParentWellID];
      handleOpenWellPanelViaGrid(
        isOpenedWellSystem,
        { ...wellData, groupedBWIDs },
        { ...latestWellData, groupedBWIDs }
      );
    } else {
      handleOpenPermitPanelViaGrid(isOpenedWellSystem, wellData);
    }
  };

  const getWellPanelIcon = (wellPanelState: WellPanelGridStates) => {
    if (
      wellPanelState === OPENED_ACTIVE_WELL_OR_PERMIT ||
      wellPanelState === OPENED_ACTIVE_WELL_SYSTEM_OR_PERMIT
    ) {
      return <WellPanelOpenedActiveIcon />;
    } else if (wellPanelState === OPENED_INACTIVE_WELL_SYSTEM_OR_PERMIT) {
      return <WellPanelOpenedInactiveIcon />;
    }

    return <WellPanelNotOpenedIcon />;
  };

  const wellPanelColumn: GridColumns = {
    field: "isOpenWellPanel",
    headerName: "",
    minWidth: 30,
    maxWidth: 30,
    bigQueryType: COLUMN_TYPE_BOOLEAN,
    disableColumnMenu: true,
    align: "center",
    renderCell: (params) => {
      const { WellHeaderPermitKey, BottomWellboreID } = params.row;
      const bottomWellBoreIdStr = convertWellIdToHeaderKey(BottomWellboreID);
      const wellPanelState = getWellPanelState(
        WellHeaderPermitKey,
        bottomWellBoreIdStr
      );

      return (
        <IconButton
          size="small"
          onClick={(e) =>
            handleOpenWellOrPermitPanel(e, wellPanelState, params.row)
          }
          className={classNames("grid-well-panel-button", {
            "toggled-on":
              wellPanelState === OPENED_ACTIVE_WELL_OR_PERMIT ||
              wellPanelState === OPENED_ACTIVE_WELL_SYSTEM_OR_PERMIT,
          })}
        >
          {getWellPanelIcon(wellPanelState)}
        </IconButton>
      );
    },
  };

  return { wellPanelColumn };
};

export default useGridWellPanel;
