import * as constants from "../../../attributes";
import { defaultDateNumericInfo, defaultVarcharInfo } from "./store";

// FOR STORE USE ONLY
export const defaultAvailableQBAttributes = {
  [constants.HEADER]: [
    { ...constants.COUNTRY, isSelected: true },
    { ...constants.STATE_NAME, isSelected: true },
    { ...constants.COUNTY, isSelected: true },
    { ...constants.WELL_NAME, isSelected: false },
    { ...constants.WELL_NUMBER, isSelected: false },
    { ...constants.FIELD_NAME, isSelected: true },
    { ...constants.BASIN_NAME, isSelected: true },
    { ...constants.OPERATOR_NAME, isSelected: false },
    { ...constants.ULTIMATE_OWNER, isSelected: true },
    { ...constants.WELL_TYPE, isSelected: false },
    { ...constants.SLANT, isSelected: false },
    { ...constants.LEASE_NAME, isSelected: false },
  ],
  [constants.WELL_SPACING]: [],
  [constants.WELL_FLAG]: [],
  [constants.DATES]: [],
  [constants.SURFACE_LOCATION]: [
    { ...constants.SECTION, isSelected: false },
    { ...constants.TOWNSHIP, isSelected: false },
    { ...constants.TOWNSHIP_DIRECTION, isSelected: false },
    { ...constants.RANGE, isSelected: false },
    { ...constants.RANGE_DIRECTION, isSelected: false },
    { ...constants.DISTRICT, isSelected: false },
  ],
  [constants.COMPLETION_DATA]: [],
  [constants.WELL_ECONOMICS]: [],
  [constants.PERMITS]: [
    { ...constants.PERMIT_NUMBER, isSelected: false },
    { ...constants.PERMIT_APPROVAL_DATE, isSelected: true },
    { ...constants.PERMIT_POSTED_DATE, isSelected: false },
    { ...constants.PERMIT_TOTAL_DEPTH, isSelected: true },
    { ...constants.PERMIT_FORMATION_DEPTH, isSelected: false },
    { ...constants.PERMIT_HAS_H2S, isSelected: false },
    {
      ...constants.DISPLAY_FORMATION,
      label: "Permit Formation",
      group: constants.PERMITS,
      isSelected: true,
    },
    {
      ...constants.WELL_STATUS,
      label: "Permit Status",
      group: constants.PERMITS,
      isSelected: false,
    },
  ],
  [constants.STATISTICS]: [],
  [constants.ID]: [],
} as const;

export const defaultQBList = [
  constants.COUNTRY,
  constants.STATE_NAME,
  constants.COUNTY,
  constants.BASIN_NAME,
  constants.FIELD_NAME,
  {
    ...constants.DISPLAY_FORMATION,
    label: "Permit Formation",
    group: constants.PERMITS,
  },
  constants.PERMIT_TOTAL_DEPTH,
  constants.ULTIMATE_OWNER,
  constants.PERMIT_APPROVAL_DATE,
] as const;

export const defaultQBInfo = {
  [constants.COUNTRY.key]: defaultVarcharInfo,
  [constants.STATE_NAME.key]: defaultVarcharInfo,
  [constants.COUNTY.key]: defaultVarcharInfo,
  [constants.BASIN_NAME.key]: defaultVarcharInfo,
  [constants.FIELD_NAME.key]: defaultVarcharInfo,
  [constants.DISPLAY_FORMATION.key]: defaultVarcharInfo,
  [constants.PERMIT_TOTAL_DEPTH.key]: defaultDateNumericInfo,
  [constants.ULTIMATE_OWNER.key]: defaultVarcharInfo,
  [constants.PERMIT_APPROVAL_DATE.key]: defaultDateNumericInfo,
} as const;
