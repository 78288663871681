import { useDebouncedCallback } from "use-debounce";

import { IdentifierFormError } from "../../../types/panels/searchPanel/identifier/formError";
import { RecordType } from "../../../types/panels/searchPanel/search";

import {
  FILE_EXTENSIONS,
  FILE_TYPES,
} from "../../../constants/common/fileMetadata";
import { IDENTIFIER_SEARCH_MODE } from "../../../constants/panels/searchPanel/identifier/types";
import {
  IDENTIFIER_PASTED_TEXT_LIMIT,
  INVALID_FORMAT_ERROR_STATE,
} from "../../../constants/panels/searchPanel/identifier/validation";
import { REGEXP_IDENTIFIER_DELIMITERS } from "../../../constants/regexp";

import useExportCardStore from "../../../store/exportCard/exportCardStore";

import { hasInvalidIdentifiers } from "../../../utils/search/validation";

import useSearchStore from "../useSearchStore";
import useIdentifierFileUpload from "./useIdentifierFileUpload";

const useIdentifierChangeHandler = (searchRecordType: RecordType) => {
  //For Export Package Manager
  const updateIsSearchCriteriaChange = useExportCardStore(
    (state) => state.updateIsSearchCriteriaChange
  );
  const updateExportUwiList = useExportCardStore(
    (state) => state.updateExportUwiList
  );

  const searchStore = useSearchStore({ searchRecordType });

  //TODO: Decoupling | Check if still needed
  // const updateIsQBUpdated = searchStore((state) => state.updateIsQBUpdated);

  const updateIsLoadingIdentifierFileUpload = searchStore(
    (state) => state.updateIsLoadingIdentifierFileUpload
  );
  const identifierFileTimeStamp = searchStore(
    (state) => state.identifierFileTimeStamp
  );
  const updateIdentifierFileTimeStamp = searchStore(
    (state) => state.updateIdentifierFileTimeStamp
  );
  const updateIdentifierSearchPastedValueText = searchStore(
    (state) => state.updateIdentifierSearchPastedValueText
  );
  const updateIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIdentifierSearchUploadedFile
  );
  const updateIdentifierSearchUploadedFileId = searchStore(
    (state) => state.updateIdentifierSearchUploadedFileId
  );
  const updateIdentifierSearchPastedValueList = searchStore(
    (state) => state.updateIdentifierSearchPastedValueList
  );
  const updateIdentifierFormSearchMode = searchStore(
    (state) => state.updateIdentifierFormSearchMode
  );
  const resetIdentifierFileUploadStates = searchStore(
    (state) => state.resetIdentifierFileUploadStates
  );

  const { getPresignedUrl } = useIdentifierFileUpload(searchRecordType);

  const getIdentifierListFromText = (text: string) => {
    return text.trim().split(REGEXP_IDENTIFIER_DELIMITERS).filter(Boolean);
  };

  const validateAndSanitizeIdentifiers = (text: string) => {
    // updateExportUwiList([]);
    const list = getIdentifierListFromText(text);
    const sanitizedText = list.join("\n");

    const isInvalid = hasInvalidIdentifiers(sanitizedText, list.length);

    // updateIsQBUpdated(!isInvalid);
    // updateIsSearchCriteriaChange(!isInvalid);
    return {
      identifierList: isInvalid ? null : list,
      identifierText: isInvalid ? "" : sanitizedText,
      isInvalid,
    };
  };

  const updateIdentifierStates = (
    identifierList: string[] | null,
    identifierText: string,
    identifierSearchUploadedFile: File | null = null
  ) => {
    const shouldAutoConvertToFile =
      identifierList && identifierList.length > IDENTIFIER_PASTED_TEXT_LIMIT;
    let file = identifierSearchUploadedFile;

    if (shouldAutoConvertToFile) {
      if (!Boolean(file)) {
        let timeStamp = identifierFileTimeStamp;
        if (!timeStamp) {
          timeStamp = Date.now();
          updateIdentifierFileTimeStamp(timeStamp);
        }
        file = new File(
          [identifierText],
          `uwi-file-${timeStamp}${FILE_EXTENSIONS.TEXT}`,
          {
            type: `${FILE_TYPES.TEXT}`,
          }
        );
        updateIdentifierSearchUploadedFile(file);
        updateIdentifierFormSearchMode(
          IDENTIFIER_SEARCH_MODE.FILE_CONVERTED_TEXT
        );
      } else {
        updateIdentifierFormSearchMode(IDENTIFIER_SEARCH_MODE.FILE);
      }
    } else {
      updateIdentifierFormSearchMode(IDENTIFIER_SEARCH_MODE.TEXT);
    }

    if (file) {
      getPresignedUrl(file);
      updateIdentifierSearchPastedValueList([]);
    } else {
      updateIdentifierSearchPastedValueList(identifierList ?? []);
    }
  };

  // Saving for Reference for processing identifiers on blur
  // const handleUwiTextOnBlur = useDebouncedCallback((uwiText: string) => {
  //   if (isIdentifierFormInvalid || uwiText === uwiTextOnBlur) return;
  //   setUwiTextOnBlur(uwiText);
  //   resetUWIUploadStates();
  //   updateUWIStates(uwiText);
  // }, 1000);

  //Used for checking and sanitizing pasted identifier text
  const validateAndSanitizeWithDebounce = useDebouncedCallback(
    (
      text: string,
      setError: React.Dispatch<React.SetStateAction<IdentifierFormError>>
    ) => {
      const { identifierText, isInvalid, identifierList } =
        validateAndSanitizeIdentifiers(text);
      if (isInvalid) {
        setError(INVALID_FORMAT_ERROR_STATE);
        return;
      }

      updateIdentifierSearchPastedValueText(identifierText);

      // Comment these function calls when processing identifiers on blur
      resetIdentifierFileUploadStates();
      updateIdentifierStates(identifierList, identifierText);
    },
    1000
  );

  const handleIdentifierTextOnChange = (
    identifierText: string,
    setError: React.Dispatch<React.SetStateAction<IdentifierFormError>>
  ) => {
    //reset form error state on change of text
    setError("");
    updateIdentifierSearchPastedValueText(identifierText);

    if (!identifierText) {
      updateIdentifierSearchPastedValueList([]);
      //Note: this gets updated if it's a file-converted-text so we need to make sure to remove value
      updateIdentifierSearchUploadedFile(null);
      updateIdentifierSearchUploadedFileId(null);
      return;
    }

    validateAndSanitizeWithDebounce(identifierText, setError);
  };

  const handleIdentifierFileOnChange = (
    identifierText: string,
    file: File,
    setError: React.Dispatch<React.SetStateAction<IdentifierFormError>>
  ) => {
    const { identifierList, isInvalid } =
      validateAndSanitizeIdentifiers(identifierText);
    if (isInvalid) {
      setError(INVALID_FORMAT_ERROR_STATE);
      updateIsLoadingIdentifierFileUpload(false);
      return;
    }

    updateIdentifierStates(identifierList, identifierText, file);
  };

  return {
    handleIdentifierTextOnChange,
    handleIdentifierFileOnChange,
  };
};

export default useIdentifierChangeHandler;
