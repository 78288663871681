import { FC, useEffect } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { Drawer, IconButton, Typography } from "@mui/material";

import { RecordType } from "../../../../../../types/panels/searchPanel/search";

import usePanelsStore from "../../../../../../store/panels/panelsStore";
import useSearchPanelStore from "../../../../../../store/search/panel/useSearchPanelStore";

import QBEditorAvailAttributes from "./QBEditorAvailAttributes";

interface QBEditorPanelProps {
  searchRecordType: RecordType;
}

const QBEditorPanel: FC<QBEditorPanelProps> = ({ searchRecordType }) => {
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const activePanel = usePanelsStore((state) => state.activePanel);

  useEffect(() => {
    if (activePanel !== "search") {
      updateOpenedQBEditorPanel(null);
    }
  }, [activePanel]);

  return (
    <Drawer
      open={openedQBEditorPanel === searchRecordType}
      onClose={() => updateOpenedQBEditorPanel(null)}
      className="custom-drawer qb-editor-panel"
      ModalProps={{ disableEnforceFocus: true }}
    >
      <div className="qb-editor-container">
        <div className="qb-editor-header">
          <div className="qb-editor-header-content">
            <Typography variant="h5">Add/ Remove Attributes</Typography>
            <IconButton
              aria-label="close-button"
              size="small"
              onClick={() => updateOpenedQBEditorPanel(null)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <div className="qb-editor-content">
          <QBEditorAvailAttributes searchRecordType={searchRecordType} />
        </div>
      </div>
    </Drawer>
  );
};

export default QBEditorPanel;
