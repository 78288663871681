import { StateCreator } from "zustand";

import { SearchCriteriaSlice } from "../../../types/panels/searchPanel/search";

import { clone } from "../../../utils";

const initialSearchCriteriaState = {
  // Note: wells and permits share the same drawnPolygons, polygonType and shapeId.
  drawnPolygons: [],
  polygonType: "",
  shapeId: "",
  fileId: "",
  currentBounds: [],
  searchedUWIs: [],
};

// Store for currently searched criterias and record type
const searchCriteriaSlice: StateCreator<SearchCriteriaSlice> = (set, get) => ({
  searchCriteria: clone(initialSearchCriteriaState),
  // update some object from searchCriteria
  updateSearchCriteria: (searchCriteria) =>
    set((state) => ({
      searchCriteria: {
        ...get().searchCriteria,
        ...searchCriteria,
      },
    })),
  // override searchCriteria
  updateNewSearchCriteria: (searchCriteria) => set(() => ({ searchCriteria })),
  resetSearchCriteria: () =>
    set(() => ({
      searchCriteria: clone(initialSearchCriteriaState),
    })),

  isSearchedRecordType: false,
  updateIsSearchedRecordType: (isSearchedRecordType) =>
    set(() => ({ isSearchedRecordType })),
});

export { searchCriteriaSlice };
