const ModifyAddIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6H3V3.75H0L0 2.25H3V0H4.5V6ZM14 3.75H6V2.25H14V3.75ZM14 11.75H11V14H9.5V8H11V10.25H14V11.75ZM8 11.75H0L0 10.25H8V11.75Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ModifyAddIcon;
