export const REGEX_UP_TO_TWO_DECIMAL = "1,2";
export const REGEX_UP_TO_THREE_DECIMAL = "1,3";

// pattern for splitting uwis by delimeters
// includes spaces, commas, tabs and linebreaks
export const REGEXP_IDENTIFIER_DELIMITERS = /[,\s\r\n]/gm;

// pattern for valid uwi characters and length
// US have 10 to 14 digits, CA have 16 digits/letters
export const REGEXP_VALID_UWI_INPUT = /(^\d{10,14}$)|(^[a-zA-Z0-9]{16}$)/gm;

// Regular expression pattern to match scientific notation
export const REGEXP_UWI_CSV_FILE_SCIENTIFIC_NOTATION =
  /([+-]?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+-]?\d+))/g;

//required parenthesis, no decimal places, commas become optional as long as they're consistent,
export const regexParenthesisNoDecimal = new RegExp(
  "(^$)|\\((\\d+|\\d{1,3}(,\\d{3})*)?\\)$"
);

//commas become optional as long as they're consistent, no decimals, allow negative(-)
export const regexNumberNoDecimal = new RegExp(
  "(^$)|^-?(\\d+|\\d{1,3}(\\,\\d{3})*)?$"
);

//allow numbers 0-9, comma, dot, dash, and parenthesis
export const regexNumberAndSpecialChar = new RegExp("^[0-9-.,()]*$");

//allow numbers 0-9,
//allow negative(-) but do not allow -0
export const regexWholeNumberOnly = new RegExp("(^$)|^-?(0|[1-9]\\d*)(?<!-0)$");
