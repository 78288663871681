import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import { DashboardChartType } from "../../types/charts/dashboardChartType/dashboardChartType";
import {
  BaseSearchRequestNoPaginationPayload,
  ChartsSearchRequestPayload,
  SearchRequestWithFileID,
  SearchRequestWithShapes,
  SearchRequestWithUWIs,
} from "../../types/common/api";
import { Bounds } from "../../types/common/attributes";
import { BaseSearchCriteria, SearchCriteria } from "../../types/common/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import { getGeometryFromPolygonArray } from "../../data/map/mapUtils";
import { clone } from "../../utils";
import useSearchStore from "../search/useSearchStore";

// TODO: Decoupling | Reconstruct Request
const useSearchRequest = () => {
  // TEMP: Temporarily using useSearchWellsStore
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const isFromIdentifierSearchUploadedFile = searchStore(
    (state) => state.isFromIdentifierSearchUploadedFile
  );
  // END TEMP: temporarily set to well

  const buildSearchRequestByParam = useCallback(
    (searchCriteria: BaseSearchCriteria, viewport?: any) => {
      if (searchCriteria) {
        if (!isFromIdentifierSearchUploadedFile && !searchCriteria.fileId) {
          //uwi: for uwi search
          if (searchCriteria.searchedUWIs.length) {
            const uwiSearchRequest: SearchRequestWithUWIs<BaseSearchRequestNoPaginationPayload> =
              {
                ...(searchCriteria.currentBounds.length && {
                  bounds: searchCriteria.currentBounds,
                }),
                ...(searchCriteria.filters?.length && {
                  filters: searchCriteria.filters,
                }),
                uwis: searchCriteria.searchedUWIs,
              };
            return uwiSearchRequest;
          }

          if (searchCriteria.shapeId || searchCriteria.drawnPolygons.length) {
            //shape id & drawnPolygon: for polygon search or shape file upload
            let geometry;
            if (viewport) {
              geometry = getGeometryFromPolygonArray(
                searchCriteria.drawnPolygons,
                viewport
              );
            } else {
              geometry = getGeometryFromPolygonArray(
                searchCriteria.drawnPolygons
              );
            }

            const polygonSearchRequest: SearchRequestWithShapes<BaseSearchRequestNoPaginationPayload> =
              {
                ...(geometry && { geometry: geometry }),
                ...(searchCriteria.shapeId && {
                  shapeId: searchCriteria.shapeId,
                }),
                ...(searchCriteria.currentBounds.length && {
                  bounds: searchCriteria.currentBounds,
                }),
                ...(searchCriteria.filters?.length && {
                  filters: searchCriteria.filters,
                }),
              };
            return polygonSearchRequest;
          }

          //bounds search
          if (searchCriteria.currentBounds.length) {
            const boundsSearchRequest: BaseSearchRequestNoPaginationPayload = {
              bounds: searchCriteria.currentBounds,
              ...(searchCriteria.filters?.length && {
                filters: searchCriteria.filters,
              }),
            };

            return boundsSearchRequest;
          }
        } else if (searchCriteria.fileId) {
          //fileid: for uwi upload
          const uwiFileUploadSearch: SearchRequestWithFileID<BaseSearchRequestNoPaginationPayload> =
            {
              ...(searchCriteria.currentBounds.length && {
                bounds: searchCriteria.currentBounds,
              }),
              ...(searchCriteria.filters?.length && {
                filters: searchCriteria.filters,
              }),
              fileId: searchCriteria.fileId,
            };
          return uwiFileUploadSearch;
        } else {
          // current bounds fallback
          const boundsSearchRequest: BaseSearchRequestNoPaginationPayload = {
            ...(searchCriteria.currentBounds.length && {
              bounds: searchCriteria.currentBounds,
            }),
            ...(searchCriteria.filters?.length && {
              filters: searchCriteria.filters,
            }),
          };

          return boundsSearchRequest;
        }
      }
    },
    [isFromIdentifierSearchUploadedFile]
  );

  const buildSearchRequest = useCallback(
    (chartType: DashboardChartType | ChartType) => {
      if (searchCriteria) {
        let copiedSearchCriteria: SearchCriteria = clone(searchCriteria);

        if (searchCriteria.currentBounds.length > 0) {
          const currentBoundsCopy: Bounds = clone(searchCriteria.currentBounds);

          // TODO: Check if this can be removed.
          // Saving for reference
          // if (!PERMIT_CHARTS.includes(chartType)) {
          //   currentBoundsCopy = currentBoundsCopy?.map((bound) => {
          //     if (bound.bound === RECORD_TYPE.key) {
          //       bound = createRecordTypeBounds(RECORD_TYPES.WELL);
          //     }
          //     return bound;
          //   });
          // }

          copiedSearchCriteria = {
            ...copiedSearchCriteria,
            currentBounds: currentBoundsCopy,
          };

          const request = {
            ...buildSearchRequestByParam(copiedSearchCriteria),
          } as ChartsSearchRequestPayload<BaseSearchRequestNoPaginationPayload>;

          return request;
        }
      }
    },
    [searchCriteria, isFromIdentifierSearchUploadedFile]
  );

  return {
    buildSearchRequest,
    buildSearchRequestByParam,
  };
};

export default useSearchRequest;
