import { FC } from "react";

import { RecordType } from "../../../../types/panels/searchPanel/search";

import QueryBuilder from "./queryBuilder/QueryBuilder";
import QBEditorPanel from "./queryBuilder/qbEditor/QBEditorPanel";

interface SearchAttributesContentProps {
  searchRecordType: RecordType;
}

const SearchAttributesContent: FC<SearchAttributesContentProps> = ({
  searchRecordType,
}) => {
  return (
    <div className="search-attributes-list">
      <QueryBuilder searchRecordType={searchRecordType} />
      <QBEditorPanel searchRecordType={searchRecordType} />
    </div>
  );
};

export default SearchAttributesContent;
