import { useCallback, useState } from "react";

import { LegendClickEvent, PlotData } from "plotly.js";

import {
  FieldInfo,
  HorizontalBarChartDataFieldsTraceInfo,
  HorizontalBarChartField,
  HorizontalBarChartResponseData,
  HorizontantalBarChartRequestBody,
} from "../../types/charts/chartHooks/chartData";
import { IChartData } from "../../types/charts/chartType/chartType";
import {
  DashboardChartDataField,
  DashboardChartState,
  DashboardChartType,
} from "../../types/charts/dashboardChartType/dashboardChartType";

import config from "../../configs/appSettings";

import { CHART_DATA_STATE } from "../../constants/charts/charts";
import { DASHBOARD_CHARTS_FIELDS_MAPPER } from "../../constants/charts/dashboardCharts";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useChartStore from "../../store/chart/chartStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";

import { callServiceAPI } from "../../action/callServiceAPI";
import { clone } from "../../utils";
import useAttributeUnit from "../common/useAttributeUnit";
import useSearchRequest from "../common/useSearchRequest";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

const chartDataCalculation = (
  dataType: string,
  value: number,
  dividend: number
) => {
  let computedValue;
  switch (dataType) {
    case "CumGas":
      computedValue = value / dividend;
      break;
    default:
      computedValue = value;
      break;
  }

  return computedValue;
};

export const useChartData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<HorizontalBarChartResponseData[]>([]);
  const [formattedChartData, setFormattedChartData] = useState<
    Partial<PlotData>[]
  >([]);
  const [error, setError] = useState(null);
  const identifierSearchUploadedFile = useSearchWellsStore(
    (state) => state.identifierSearchUploadedFile
  );
  const isFromIdentifierSearchUploadedFile = useSearchWellsStore(
    (state) => state.isFromIdentifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } = useIdentifierFileUpload(
    RECORD_TYPES.WELL
  );
  const [currentTickYData, setCurrentTickYData] = useState<string[]>([]);

  const { isMetricOnSearch } = useUnitOfMeasure();
  const { getAttributeUnit } = useAttributeUnit();

  const { buildSearchRequest } = useSearchRequest();
  const { buildChartPostRequest, isDashboardChartEmpty } =
    useChartSearchRequest();
  const updateTraceLegendVisibilityByKey = useChartStore(
    (state) => state.updateTraceLegendVisibilityByKey
  );

  const formatFieldsData = (displayDataFields: DashboardChartDataField[]) => {
    const fields: FieldInfo[] = [];
    displayDataFields.forEach((data) => {
      if (data.fieldInfo) {
        const dataObj: FieldInfo = {
          fieldName: data.fieldInfo.name,
          aggregateType: data.aggregate,
        };

        fields.push(dataObj);
      }
    });

    return fields;
  };

  const formatChartTrace = (
    displayDataFields: DashboardChartDataField[],
    data: HorizontalBarChartResponseData[],
    grouping: string[],
    chartDataState: DashboardChartState
  ) => {
    if (!data.length) {
      setFormattedChartData([]);
      return;
    }

    const dataFieldsChartInfo: HorizontalBarChartDataFieldsTraceInfo[] = [];

    displayDataFields.forEach((field) => {
      if (field.fieldInfo) {
        const trace: Partial<PlotData> = {
          type: "bar",
          x: [],
          y: [],
          orientation: "h",
          name: field.fieldInfo.displayName,
          text: [],
          textposition: "none",
          hovertemplate: "%{text}",
          marker: {
            color: field.fieldInfo.color,
            width: 1,
          },
        };
        const fieldInfo: HorizontalBarChartDataFieldsTraceInfo = {
          trace: trace,
          name: field.fieldInfo.name,
          aggregate: field.aggregate,
          unit: getAttributeUnit(field.fieldInfo.name),
        };

        dataFieldsChartInfo.push(fieldInfo);
      }
    });

    const currentYTick: string[] = [];
    const cumGasDividend = isMetricOnSearch ? 1069 : 6;
    data.forEach((group) => {
      const yTrace = group.groupings.find(
        (groupInfo) => groupInfo.name === grouping[0]
      ); //For now, just handling one group by
      currentYTick.push(yTrace?.val ?? "");

      dataFieldsChartInfo.forEach((fieldInfo) => {
        let xTraceValue: HorizontalBarChartField | undefined;

        if (chartDataState === CHART_DATA_STATE.PRE_SEARCH) {
          //if data is for pre-search do not conside aggregate type
          xTraceValue = group.fields.find(
            (field) => field.name === fieldInfo.name
          );
        } else {
          xTraceValue = group.fields.find(
            (field) =>
              field.name === fieldInfo.name &&
              field.aggregation === fieldInfo.aggregate
          );
        }

        if (xTraceValue && yTrace) {
          const xValue = xTraceValue.val
            ? chartDataCalculation(
                fieldInfo.name,
                Number(xTraceValue.val),
                cumGasDividend
              )
            : 0;

          (fieldInfo.trace.x as number[]).push(xValue);
          (fieldInfo.trace.y as string[]).push(yTrace.val ?? "");

          const traceText = `${yTrace.val} <br> ${
            xTraceValue.val
              ? Math.round(Number(xTraceValue.val)).toLocaleString()
              : 0
          } ${fieldInfo.unit}`;
          (fieldInfo.trace.text as string[]).push(traceText);
        }
      });
    });

    const chartTrace = dataFieldsChartInfo.map(({ trace }) => trace);

    setCurrentTickYData(currentYTick);

    setFormattedChartData(chartTrace);
  };

  const getChartData = async (
    chartDataState: DashboardChartState,
    chartType: DashboardChartType,
    displayDataFields: DashboardChartDataField[],
    groupBy: string[]
  ) => {
    setIsLoading(true);
    setData([]);
    setError(null);

    try {
      if (isDashboardChartEmpty(chartDataState)) {
        setData([]);
        setFormattedChartData([]);
        setCurrentTickYData([]);
        setIsLoading(false);
        return;
      }

      const body: HorizontantalBarChartRequestBody = {
        fields: formatFieldsData(displayDataFields),
        groupByFields: groupBy,
        chartType: DASHBOARD_CHARTS_FIELDS_MAPPER[chartType],
      };

      switch (chartDataState) {
        case CHART_DATA_STATE.PRE_SEARCH:
          body.dataForDefaultDashboard = true;

          if (config.isUoMEnabled && isMetricOnSearch)
            body.searchRequest = {
              isMetric: isMetricOnSearch,
            };
          break;
        case CHART_DATA_STATE.INTER_SEARCH:
          body.searchRequest = {
            ...buildSearchRequest(chartType),
            ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
          };
          break;
        case CHART_DATA_STATE.POST_SEARCH:
          body.searchRequest = {
            ...buildChartPostRequest(chartType),
            ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
          };
          break;
        default:
          break;
      }

      const response = await callServiceAPI<HorizontalBarChartResponseData[]>(
        `${config.endpoints.wellService}api/wells/chart`,
        body,
        getIdentifierFileIdWithRetry,
        identifierSearchUploadedFile,
        isFromIdentifierSearchUploadedFile
      );

      if (!response || !("data" in response)) {
        setData([]);
        return;
      }

      setData(response.data);
      formatChartTrace(
        displayDataFields,
        response.data,
        groupBy,
        chartDataState
      );
      setIsLoading(false);
    } catch (error: any) {
      console.debug("getChartDataError error", error);
      setData([]);
      setFormattedChartData([]);
      setError(error);
      setIsLoading(false);
    }
  };

  const onLegendClick = useCallback(
    (
      chartId: IChartData["chartId"],
      legendEvent: LegendClickEvent,
      legendGroupClick?: boolean
    ) => {
      const dataTarget = clone(legendEvent.data[legendEvent.curveNumber]);
      if (dataTarget.type === "scatter" || dataTarget.type === "scattergl") {
        const newVisibility =
          typeof dataTarget.visible === "boolean" ? "legendonly" : true;
        updateTraceLegendVisibilityByKey(
          chartId,
          newVisibility,
          legendEvent.curveNumber,
          legendGroupClick,
          dataTarget.legendgroup
        );
      }
    },
    [updateTraceLegendVisibilityByKey]
  );

  return {
    isLoading,
    data,
    formattedChartData,
    error,
    currentTickYData,
    onLegendClick,
    getChartData,
  };
};
