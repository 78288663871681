import { useMemo } from "react";

import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchPermitsStore from "../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";

// TODO: Decoupling | Might need to already remove this
const useRecordType = () => {
  const searchWellsStore = useSearchWellsStore();
  const searchPermitsStore = useSearchPermitsStore();

  const searchedRecordTypes = useMemo(() => {
    const recordTypes: RecordType[] = [];
    if (searchWellsStore.isSearchedRecordType)
      recordTypes.push(RECORD_TYPES.WELL);
    if (searchPermitsStore.isSearchedRecordType)
      recordTypes.push(RECORD_TYPES.PERMIT);
    return recordTypes;
  }, [
    searchWellsStore.isSearchedRecordType,
    searchPermitsStore.isSearchedRecordType,
  ]);

  const isWellRecord = (type: RecordType) => {
    return type === RECORD_TYPES.WELL;
  };

  const isPermitRecord = (type: RecordType) => {
    return type === RECORD_TYPES.PERMIT;
  };

  const getRecordTypeLabel = (type: string) => {
    let label = "";
    switch (type) {
      case RECORD_TYPES.WELL:
        label = "Wells";
        break;
      case RECORD_TYPES.PERMIT:
        label = "Permits";
        break;
      default:
        label = type;
        break;
    }

    return label;
  };

  return {
    searchedRecordTypes,
    isPermitRecord,
    isWellRecord,
    getRecordTypeLabel,
  };
};

export default useRecordType;
