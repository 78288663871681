import { useMemo } from "react";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchStore from "./useSearchStore";

const useSearchCriteria = () => {
  // TEMP: Temporarily using useSearchWellsStore
  const searchStore = useSearchStore({ searchRecordType: RECORD_TYPES.WELL });
  const searchCriteria = searchStore((state) => state.searchCriteria);

  const hasSearchCriteria = useMemo(
    () =>
      searchCriteria.currentBounds.length > 0 ||
      searchCriteria.drawnPolygons.length > 0 ||
      searchCriteria.searchedUWIs.length > 0 ||
      !!searchCriteria.fileId ||
      !!searchCriteria.shapeId,
    [searchCriteria]
  );

  return { hasSearchCriteria };
};

export default useSearchCriteria;
