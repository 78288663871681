import { StateCreator } from "zustand";

import { SearchAccordionSlice } from "../../../types/panels/searchPanel/accordion/store";
import { SearchPanelTab } from "../../../types/panels/searchPanel/search";

import { ATTRIBUTES_TAB } from "../../../constants/panels/searchPanel/common/accordion";

import { clone } from "../../../utils";

const sliceResetFns = new Set<() => void>();

const resetSearchAccordionSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialSearchAccordionSlice = {
  searchTypeTab: ATTRIBUTES_TAB as SearchPanelTab,
  isSearchAccordionToggledOn: true,
  searchAttributeCount: {
    count: 0,
    isLoading: false,
  },
  searchIdentifierCount: {
    count: 0,
    isLoading: false,
  },
};

const searchAccordionSlice: StateCreator<SearchAccordionSlice> = (set, get) => {
  sliceResetFns.add(() => set(clone(initialSearchAccordionSlice)));
  return {
    ...initialSearchAccordionSlice,
    updateSearchTypeTab: (searchTypeTab) => set(() => ({ searchTypeTab })),
    toggleIsSearchAccordionToggledOn: (status) =>
      set(() => ({
        isSearchAccordionToggledOn: status ?? !get().isSearchAccordionToggledOn,
      })),
    updateSearchAttributeCount: (count, isLoading) =>
      set(() => ({ searchAttributeCount: { count, isLoading } })),
    updateSearchIdentifierCount: (count, isLoading) =>
      set(() => ({ searchIdentifierCount: { count, isLoading } })),
  };
};

export { resetSearchAccordionSlice, searchAccordionSlice };
