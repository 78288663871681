import { useEffect, useState } from "react";

import { MAX_EXPORT } from "../../constants/constants";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";

import useSearchStore from "../search/useSearchStore";

const useR360GridExport = () => {
  const [isR360Disabled, setIsR360Disabled] = useState(true);

  const allWellGridDataLoading = useDataGridStore(
    (state) => state.allWellGridDataLoading
  );
  const gridTotalCount = useDataGridStore((state) => state.gridTotalCount);

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const isIdentifierFormInvalid = searchStore(
    (state) => state.isIdentifierFormInvalid
  );
  // END TEMP: temporarily set to well

  const isSearchCriteriaChange = useExportCardStore(
    (state) => state.isSearchCriteriaChange
  );

  const allGridData = useDataGridStore((state) => state.allGridData);

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  useEffect(() => {
    if (
      allGridData.length === 0 ||
      (allGridData.length > 0 && allGridData.length > MAX_EXPORT) ||
      isIdentifierFormInvalid ||
      allWellGridDataLoading ||
      gridTotalCount === 0 ||
      isSearchCriteriaChange
    ) {
      if (
        selectedGridDataKeys.length > 0 &&
        selectedGridDataKeys.length <= MAX_EXPORT
      ) {
        setIsR360Disabled(false);
      } else {
        setIsR360Disabled(true);
      }
    } else {
      setIsR360Disabled(false);
    }
  }, [
    allGridData,
    isIdentifierFormInvalid,
    allWellGridDataLoading,
    gridTotalCount,
    selectedGridDataKeys,
  ]);

  return {
    isR360Disabled,
  };
};

export default useR360GridExport;
