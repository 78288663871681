import { FC, useMemo } from "react";

import { Card, CardContent, CardHeader, Slide } from "@mui/material";

import classNames from "classnames";

import { MAP_SETTINGS } from "../../constants/map/mapSettings";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import usePanelsStore from "../../store/panels/panelsStore";

import useRecordType from "../../customHooks/common/useRecordType";

import CustomCloseButton from "../common/CustomCloseButton";
import BaseMapAccordion from "../mapSettingsAccordions/BaseMapAccordion";
import BubbleMapAccordion from "../mapSettingsAccordions/BubbleMapAccordion";
import CultureLayersAccordion from "../mapSettingsAccordions/CultureLayersAccordion";
import WellStyleAccordion from "../mapSettingsAccordions/WellStyleAccordion";

import "../../assets/scss/mapOverlays/mapSettingsOverlay.scss";

const MapSettingsOverlay: FC = () => {
  const mapOverlayOpened = useMapSettingsStore(
    (state) => state.mapOverlayOpened
  );
  const removeMapOverlayOpened = useMapSettingsStore(
    (state) => state.removeMapOverlayOpened
  );
  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  const open = useMemo(
    () => mapOverlayOpened.includes(MAP_SETTINGS.key),
    [mapOverlayOpened]
  );

  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  const { searchedRecordTypes } = useRecordType();

  const getBottomPosition = useMemo(() => {
    let bottom = `calc(${gridHeight} + 84px)`;

    if (!showGrid && !showGridHeader) {
      bottom = "84px";
    } else if (!showGrid && showGridHeader) {
      bottom = "144px";
    }

    return bottom;
  }, [gridHeight, showGrid, showGridHeader]);

  const getHeight = useMemo(() => {
    let height = `calc(100vh - (${gridHeight} + 180px))`;
    if (!showGrid && !showGridHeader) {
      height = `calc(100vh - 195px)`;
    } else if (!showGrid && showGridHeader) {
      height = `calc(100vh - 240px)`;
    }

    return height;
  }, [gridHeight, showGrid, showGridHeader]);

  const showBubbleMap = useMemo(() => {
    return searchedRecordTypes.includes(RECORD_TYPES.WELL);
  }, [searchedRecordTypes]);

  return (
    <Slide direction="left" in={open}>
      <Card
        className={classNames("map-settings-card", {
          "analysis-open": isOpenAnalysisPanel,
          open,
        })}
        sx={{ bottom: getBottomPosition, height: getHeight }}
      >
        <CardHeader
          action={
            <CustomCloseButton
              aria-label="close"
              onClick={() => removeMapOverlayOpened(MAP_SETTINGS.key)}
            />
          }
          title={MAP_SETTINGS.label}
        />
        <CardContent>
          <WellStyleAccordion />
          {showBubbleMap && <BubbleMapAccordion />}
          <CultureLayersAccordion />
          <BaseMapAccordion />
        </CardContent>
      </Card>
    </Slide>
  );
};

export default MapSettingsOverlay;
