import { useMemo } from "react";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useRecordType from "../../../customHooks/common/useRecordType";

import { LEGENDS_SYMBOLS } from "./LegendSymbol";

const SymbolList = () => {
  const { searchedRecordTypes } = useRecordType();

  const symbols = useMemo(() => {
    const symbols = [];
    if (searchedRecordTypes.includes(RECORD_TYPES.WELL)) {
      symbols.push(LEGENDS_SYMBOLS.WellSpotSymbol);
    }
    if (searchedRecordTypes.includes(RECORD_TYPES.PERMIT)) {
      symbols.push(LEGENDS_SYMBOLS.PermitsSymbol);
    }
    return symbols;
  }, [searchedRecordTypes]);

  return (
    <div className="list">
      {symbols.map((symbol, index) => (
        <div key={`symbol-${index}`} className="symbols-container">
          <div>{symbol.symbol}</div>
          <div className="symbols-label">{symbol.text}</div>
        </div>
      ))}
    </div>
  );
};

export default SymbolList;
