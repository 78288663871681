import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchPermitsStore from "../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";

interface UseSearchStoreProps {
  searchRecordType: RecordType;
}

const useSearchStore = ({ searchRecordType }: UseSearchStoreProps) => {
  const searchWellsStore = useSearchWellsStore;
  const searchPermitsStore = useSearchPermitsStore;

  const getSearchStore = () => {
    if (searchRecordType === RECORD_TYPES.PERMIT) {
      return searchPermitsStore;
    }

    return searchWellsStore;
  };

  return getSearchStore();
};

export default useSearchStore;
