import { FC, useCallback, useEffect } from "react";

import {
  Attribute,
  AttributeBound,
} from "../../../../../../types/common/attributes";
import {
  OperationTypeKeys,
  QBAttributeInfo,
} from "../../../../../../types/panels/searchPanel/queryBuilder";
import { SortInfoTypeOrder } from "../../../../../../types/panels/searchPanel/queryBuilder/sortPerAttribute";
import { RecordType } from "../../../../../../types/panels/searchPanel/search";

import {
  BOOLEAN,
  DATE,
  FLOAT,
  INTEGER,
  SPECIAL,
  TEXT,
  TEXT_SELECT,
} from "../../../../../../constants/attributes";
import { ATTRIBUTE_SORT_OPTIONS } from "../../../../../../constants/panels/searchPanel/queryBuilder/sortAttributes";

import useExportCardStore from "../../../../../../store/exportCard/exportCardStore";
import useMapDrawStore from "../../../../../../store/map/draw/mapDrawStore";

import useAttributeCountData from "../../../../../../customHooks/common/useAttributeCountData";
import useUnitOfMeasure from "../../../../../../customHooks/common/useUnitOfMeasure";
import useSearchStore from "../../../../../../customHooks/search/useSearchStore";
import usePrevious from "../../../../../../customHooks/usePrevious";

import { createBoundsByKey } from "../../../../../../utils/common/boundsData";

import QBBooleanAttribute from "./QBBooleanAttribute";
import QBDateAttribute from "./QBDateAttribute";
import QBNumericAttribute from "./QBNumericAttribute";
import QBSpecialAttribute from "./QBSpecialAttribute";
import QBTextSelectAttribute from "./QBTextSelectAttribute";
import QBVarcharAttribute from "./QBVarcharAttribute";

interface SearchAttributeProps {
  attribute: Attribute;
  attributeQBInfo: QBAttributeInfo;
  searchRecordType: RecordType;
}

const QBAttribute: FC<SearchAttributeProps> = ({
  attribute,
  attributeQBInfo,
  searchRecordType,
}) => {
  const { isLoading, getAttributeCount } = useAttributeCountData({
    searchRecordType,
  });
  const { isMetricOnSelection } = useUnitOfMeasure();

  const searchStore = useSearchStore({ searchRecordType });
  const currentBounds = searchStore((state) => state.currentBounds);
  const updateCurrentBounds = searchStore((state) => state.updateCurrentBounds);
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);
  const resetCurrentQBInfo = searchStore((state) => state.resetCurrentQBInfo);
  const updateIsQBUpdated = searchStore((state) => state.updateIsQBUpdated);

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);
  const updateIsSearchCriteriaChange = useExportCardStore(
    (state) => state.updateIsSearchCriteriaChange
  );
  const prevIsMetricOnSelection = usePrevious(isMetricOnSelection);

  // Update bounds when adding/removing selected.
  const modifyAttributeBounds = useCallback(
    (values: string[] | number[], operationType: OperationTypeKeys | "") => {
      const newBounds = [...currentBounds];
      const boundIndex = currentBounds.findIndex(
        (cBounds: AttributeBound) => cBounds.bound === attribute.key
      );
      // check if there are still values selected for that attribute
      if (values.length > 0 && operationType !== "") {
        const newAttributeBound = createBoundsByKey(
          attribute,
          values,
          operationType
        );
        if (boundIndex > -1) {
          // check if there is already a bounds for the attribute
          newBounds[boundIndex] = newAttributeBound;
        } else {
          // create new bound if there isn't
          newBounds.push(newAttributeBound);
          // insert isUpdatedFlag
        }
      } else {
        // remove the bound for the attribute
        newBounds.splice(boundIndex, 1);
      }
      updateIsQBUpdated(true);
      updateIsSearchCriteriaChange(true);
      updateCurrentBounds(newBounds);
      resetCurrentQBInfo(attribute.key, "options");
      resetCurrentQBInfo(attribute.key, "isUpdated");
    },
    [attribute, currentBounds, updateCurrentBounds, resetCurrentQBInfo]
  );

  const getOptionsWithWellCounts = useCallback(
    (sort?: SortInfoTypeOrder) => {
      if (attributeQBInfo.isUpdated && !sort) return;
      const filteredBounds = currentBounds.filter(
        ({ bound }: AttributeBound) => bound !== attribute.key
      );

      //if attribute has sort applied
      if (!sort && attribute.dataType === TEXT && attributeQBInfo.sortKey) {
        const { type, order } = ATTRIBUTE_SORT_OPTIONS[attributeQBInfo.sortKey];
        sort = { type, order };
      }

      getAttributeCount({
        bounds: filteredBounds,
        searchType: attribute.key,
        drawnPolygons,
        shapeId,
        isBasedOnIdentifierFileUpload: false,
        isMetric: isMetricOnSelection,
        ...(sort && { sort }),
      });
      updateCurrentQBInfo(attribute.key, "isUpdated", true);
    },
    [
      attribute.key,
      attributeQBInfo.isUpdated,
      isMetricOnSelection,
      currentBounds,
      drawnPolygons,
      shapeId,
      getAttributeCount,
      updateCurrentQBInfo,
    ]
  );

  //reset options and isUpdated flag to refetch attribute options on change of Unit of Measure
  useEffect(() => {
    if (prevIsMetricOnSelection !== isMetricOnSelection) {
      resetCurrentQBInfo(attribute.key, "options");
      resetCurrentQBInfo(attribute.key, "isUpdated");
    }
  }, [isMetricOnSelection]);

  const renderAttribute = useCallback(() => {
    switch (attribute.dataType) {
      case INTEGER:
      case FLOAT:
        return (
          <QBNumericAttribute
            attribute={attribute}
            attributeQBInfo={attributeQBInfo}
            searchRecordType={searchRecordType}
            modifyAttributeBounds={modifyAttributeBounds}
          />
        );
      case TEXT_SELECT:
        return (
          <QBTextSelectAttribute
            attribute={attribute}
            attributeQBInfo={attributeQBInfo}
            searchRecordType={searchRecordType}
            isOptionsLoading={isLoading}
            getOptionsWithWellCounts={getOptionsWithWellCounts}
            modifyAttributeBounds={modifyAttributeBounds}
          />
        );
      case BOOLEAN:
        return (
          <QBBooleanAttribute
            attribute={attribute}
            attributeQBInfo={attributeQBInfo}
            searchRecordType={searchRecordType}
            isOptionsLoading={isLoading}
            getOptionsWithWellCounts={getOptionsWithWellCounts}
            modifyAttributeBounds={modifyAttributeBounds}
          />
        );
      case DATE:
        return (
          <QBDateAttribute
            attribute={attribute}
            attributeQBInfo={attributeQBInfo}
            searchRecordType={searchRecordType}
            modifyAttributeBounds={modifyAttributeBounds}
          />
        );
      case SPECIAL:
        return (
          <QBSpecialAttribute
            attribute={attribute}
            attributeQBInfo={attributeQBInfo}
            searchRecordType={searchRecordType}
            modifyAttributeBounds={modifyAttributeBounds}
          />
        );

      default:
        return (
          <QBVarcharAttribute
            attribute={attribute}
            attributeQBInfo={attributeQBInfo}
            searchRecordType={searchRecordType}
            isOptionsLoading={isLoading}
            getOptionsWithWellCounts={(sort) => getOptionsWithWellCounts(sort)}
            modifyAttributeBounds={modifyAttributeBounds}
          />
        );
    }
  }, [
    attribute,
    attributeQBInfo,
    isLoading,
    getOptionsWithWellCounts,
    modifyAttributeBounds,
  ]);

  return renderAttribute();
};

export default QBAttribute;
