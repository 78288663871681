import { useEffect } from "react";

import { RecordType } from "../../types/panels/searchPanel/search";

import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useWellCountData from "../useWellCountData";
import useSearchStore from "./useSearchStore";

interface UseSearchCountIdentifierProps {
  searchRecordType: RecordType;
}

const useSearchCountIdentifier = ({
  searchRecordType,
}: UseSearchCountIdentifierProps) => {
  const { isMetricOnSelection } = useUnitOfMeasure();

  const { data, isLoading, getExpectedWellCount } = useWellCountData({
    searchRecordType,
  });

  const searchStore = useSearchStore({ searchRecordType });
  const updateSearchIdentifierCount = searchStore(
    (state) => state.updateSearchIdentifierCount
  );
  const identifierSearchPastedValueList = searchStore(
    (state) => state.identifierSearchPastedValueList
  );
  const identifierSearchUploadedFileId = searchStore(
    (state) => state.identifierSearchUploadedFileId
  );
  const isLoadingIdentifierFileUpload = searchStore(
    (state) => state.isLoadingIdentifierFileUpload
  );

  useEffect(() => {
    updateSearchIdentifierCount(data, isLoading);
  }, [data, isLoading]);

  useEffect(() => {
    if (
      !identifierSearchPastedValueList?.length &&
      !identifierSearchUploadedFileId
    ) {
      updateSearchIdentifierCount(0, false);
      return;
    }

    getExpectedWellCount({
      bounds: [],
      searchedUWIs: identifierSearchPastedValueList,
      fileId: identifierSearchUploadedFileId ?? "",
      drawnPolygons: [],
      shapeId: "",
      fetchedSavedSearchTrigger: false,
      isMetric: isMetricOnSelection,
    });
  }, [
    identifierSearchPastedValueList,
    identifierSearchUploadedFileId,
    isMetricOnSelection,
  ]);

  return { data, isLoading: isLoading || isLoadingIdentifierFileUpload };
};

export default useSearchCountIdentifier;
