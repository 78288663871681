import { create } from "zustand";

import { SearchPanelStates } from "../../../types/panels/searchPanel/search";

import { searchPanelSlice } from "./useSearchPanelSlice";

const useSearchPanelStore = create<SearchPanelStates>()((...a) => ({
  ...searchPanelSlice(...a),
}));

export default useSearchPanelStore;
