import { RecordType } from "../../../../../types/panels/searchPanel/search";

import useSearchStore from "../../../../../customHooks/search/useSearchStore";

import QBAttribute from "./qbAttributes/QBAttribute";

interface QueryBuilderProps {
  searchRecordType: RecordType;
}

const QueryBuilder = ({ searchRecordType }: QueryBuilderProps) => {
  const searchStore = useSearchStore({ searchRecordType });
  const currentQBList = searchStore((state) => state.currentQBList);
  const currentQBInfo = searchStore((state) => state.currentQBInfo);

  return currentQBList.map((attribute) => (
    <div key={attribute.key} className="qb-attribute">
      <QBAttribute
        key={attribute.key}
        attribute={attribute}
        attributeQBInfo={currentQBInfo[attribute.key]}
        searchRecordType={searchRecordType}
      />
    </div>
  ));
};

export default QueryBuilder;
