import { SearchCriteria } from "../../../types/common/search";
import { RecordType } from "../../../types/panels/searchPanel/search";

import { IDENTIFIER_TAB } from "../../../constants/panels/searchPanel/common/accordion";
import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useSearchPanelStore from "../../../store/search/panel/useSearchPanelStore";
import useStore from "../../../store/useStore";

import { containsAtLeastOne } from "../../../utils/helper2";
import { getPolygonType } from "../../../utils/map/draw/polygon";

import useSearchPanelActions from "../useSearchPanelActions";
import useSearchStore from "../useSearchStore";

interface UseAttributeActionButtonsProps {
  searchRecordType: RecordType;
}

const useAttributeActionButtons = ({
  searchRecordType,
}: UseAttributeActionButtonsProps) => {
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);
  const isUploadingShapeFile = useMapDrawStore(
    (state) => state.isUploadingShapeFile
  );

  const searchStore = useSearchStore({ searchRecordType });
  const currentQBInfo = searchStore((state) => state.currentQBInfo);
  const currentBounds = searchStore((state) => state.currentBounds);
  const updateSearchTypeTab = searchStore((state) => state.updateSearchTypeTab);

  const updateIsFromIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIsFromIdentifierSearchUploadedFile
  );
  const resetIdentifierStates = searchStore(
    (state) => state.resetIdentifierStates
  );

  const lastSearchedBy = useStore((state) => state.lastSearchedBy);
  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);

  const {
    searchCallback,
    resetCallback,
    resetAttribsPolygons,
    hasLoadedAttribSavedSearch,
    hasLoadedIdentifierSavedSearch,
  } = useSearchPanelActions();

  const isSearchDisabled = (isReset: boolean) => {
    let hasValue = false;
    if (containsAtLeastOne(drawnPolygons) || shapeId) hasValue = true;

    if (!isReset && !currentBounds.length && !hasValue) return true;

    const attributeValues = Object.values(currentQBInfo);
    let hasErrorValue = false;

    for (let i = 0; i < attributeValues.length; i++) {
      const { hasError, values } = attributeValues[i];

      // check if any attribute has error
      if (hasError) {
        hasErrorValue = true;
        break;
      }

      // check if any attribute has value and operationType
      if (!hasValue && containsAtLeastOne(values)) {
        hasValue = true;
      }
    }

    return hasErrorValue || !hasValue || isUploadingShapeFile;
  };

  const handleSearchAttribute = () => {
    const newSearchCriteria: Partial<SearchCriteria> = {
      drawnPolygons,
      polygonType: getPolygonType(drawnPolygons),
      currentBounds,
      searchedUWIs: [],
      shapeId,
      fileId: "",
    };
    searchCallback(newSearchCriteria, !hasLoadedIdentifierSavedSearch);
    closeAttributesPanel();
    updateIsFromIdentifierSearchUploadedFile(false);
    updateLastSearchedBy(SEARCH_TYPES.ATTRIBUTE_SEARCH);
    resetIdentifierStates();
  };

  const handleResetAttribute = () => {
    resetAttribsPolygons();
    resetCallback(hasLoadedAttribSavedSearch);
  };

  const closeAttributesPanel = () => {
    if (openedQBEditorPanel) {
      updateOpenedQBEditorPanel(null);
    }
  };

  return {
    isResetDisabled: isSearchDisabled(true),
    isSaveDisabled: isSearchDisabled(false),
    isSearchDisabled: isSearchDisabled(false),
    isShowPrompt:
      lastSearchedBy === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH ||
      lastSearchedBy === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH ||
      lastSearchedBy === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH,
    hasLoadedSavedSearch: hasLoadedAttribSavedSearch,
    handleResetClick: handleResetAttribute,
    onClickSaveCallback: resetIdentifierStates,
    handleSearchClick: handleSearchAttribute,
    handleCancelClick: () => updateSearchTypeTab(IDENTIFIER_TAB),
  };
};

export default useAttributeActionButtons;
