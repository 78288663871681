import { useCallback, useMemo, useState } from "react";

import { MapData, WellData } from "../../types/common/wells";
import { GridDataObj } from "../../types/grid/useGridData";
import {
  SelectedKeys,
  SelectedKeysWithData,
} from "../../types/map/selection/store";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import useRecordType from "../common/useRecordType";

export const useGridSelectRow = () => {
  const { isWellRecord, isPermitRecord } = useRecordType();
  const [gridWellObj, setGridWellsObj] = useState<
    GridDataObj | Record<string, never>
  >({});

  const allGridData = useDataGridStore((state) => state.allGridData);

  const selectedWellIds = useMapSelectionStore(
    (state) => state.selectedWellIds
  );

  const selectedPermitIds = useMapSelectionStore(
    (state) => state.selectedPermitIds
  );
  const allSelectedGridData = useMapSelectionStore(
    (state) => state.allSelectedGridData
  );
  const updateSelectedGridDataKeys = useMapSelectionStore(
    (state) => state.updateSelectedGridDataKeys
  );

  const selectedWellUwis = useMapSelectionStore(
    (state) => state.selectedWellUwis
  );
  const updateAllSelectedGridData = useMapSelectionStore(
    (state) => state.updateAllSelectedGridData
  );
  const removeAllCurrentlySelectedIds = useMapSelectionStore(
    (state) => state.removeAllCurrentlySelectedIds
  );
  const updateSelectedWellIds = useMapSelectionStore(
    (state) => state.updateSelectedWellIds
  );
  const updateSelectedPermitIds = useMapSelectionStore(
    (state) => state.updateSelectedPermitIds
  );
  const gridFilteredCount = useDataGridStore(
    (state) => state.gridFilteredCount
  );
  const updateDeselectedWellIds = useMapSelectionStore(
    (state) => state.updateDeselectedWellIds
  );

  const updateDeselectedPermitIds = useMapSelectionStore(
    (state) => state.updateDeselectedPermitIds
  );

  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );

  const selectedGridDataKeys = useMemo(() => {
    const selectedKeys = [
      ...Object.keys(selectedWellIds),
      ...Object.keys(selectedPermitIds),
    ];

    if (!selectedKeys.length) toggleIsHighlightSelectedSpots(true);

    if (selectedKeys.length && selectedKeys.length < gridFilteredCount) {
      const midpointCount = gridFilteredCount / 2;

      if (selectedKeys.length > midpointCount) {
        const deselectedWellIds: number[] = [];
        const deselectedPermitIds: number[] = [];
        allGridData.forEach((data) => {
          if (
            isWellRecord(data.RecordType) &&
            !selectedWellIds[data.WellHeaderPermitKey] &&
            data.WellID
          ) {
            deselectedWellIds.push(data.WellID);
          } else if (
            isPermitRecord(data.RecordType) &&
            !selectedPermitIds[data.WellHeaderPermitKey] &&
            data.PermitID
          ) {
            deselectedPermitIds.push(data.PermitID);
          }
        });

        updateDeselectedWellIds(deselectedWellIds);

        updateDeselectedPermitIds(deselectedPermitIds);
      } else {
        updateDeselectedWellIds([]);
        updateDeselectedPermitIds([]);
      }
    } else {
      updateDeselectedWellIds([]);
      updateDeselectedPermitIds([]);
    }

    updateSelectedGridDataKeys(selectedKeys);

    return selectedKeys;
  }, [selectedWellIds, selectedPermitIds]);

  const convertWellDataArrayToObject = (gridData: WellData[]) => {
    const gridObj = gridData.reduce((a: GridDataObj, v: WellData) => {
      a[v.WellHeaderPermitKey] = v;

      return a;
    }, {});

    setGridWellsObj(gridObj);
  };

  const isSelectedWellsInGrid = (Ids: string[]) => {
    return Ids.every((id) => gridWellObj.hasOwnProperty(id));
  };

  // getting of data based on the selected WellHeaderPermitKey
  const getSelectedData = useCallback(
    (selectedIds: { [key: string]: number | boolean }) => {
      const selectedMapData: { [key: string]: MapData } = {};
      allGridData
        .filter((data) => selectedIds[data.WellHeaderPermitKey])
        .forEach((data) => {
          selectedMapData[data.WellHeaderPermitKey] = {
            WellID: data.WellID,
            PermitID: data.PermitID,
            ParentWellID: data.ParentWellID,
            BottomWellboreID: data.BottomWellboreID,
            UWI: data.UWI ?? "",
            RecordType: data.RecordType,
          };
        });

      return selectedMapData;
    },
    [allGridData]
  );

  const allSelectedWellIdsKeys = useMemo(() => {
    const wellIDsToKeys = [
      ...Object.keys(allSelectedGridData).map((key) => parseInt(key)),
    ];
    return wellIDsToKeys;
  }, [allSelectedGridData]);

  const headerSelectCallback = () => {
    if (selectedGridDataKeys.length > 0) {
      // deselect all
      removeAllCurrentlySelectedIds();
    } else {
      // select all
      const wellIds: SelectedKeys = {};
      const wellData: SelectedKeysWithData = {};
      const wellIdKeys: number[] = [];
      const wellUwis: string[] = [];

      const permitIds: SelectedKeys = {};
      const permitData: SelectedKeysWithData = {};
      const permitIdKeys: number[] = [];

      allGridData.forEach((data) => {
        const key = data.WellHeaderPermitKey;
        const mapData = {
          WellID: data.WellID,
          PermitID: data.PermitID,
          ParentWellID: data.ParentWellID,
          BottomWellboreID: data.BottomWellboreID,
          ...(data.UWI && { UWI: data.UWI }), //make UWI optional
          RecordType: data.RecordType,
        };
        if (isWellRecord(data.RecordType) && data.WellID) {
          wellIds[key] = data.WellID;
          wellData[key] = mapData;
          wellIdKeys.push(data.WellID);
          if (data.UWI && !selectedWellUwis.includes(data.UWI))
            wellUwis.push(data.UWI);
        } else if (isPermitRecord(data.RecordType) && data.PermitID) {
          permitIds[key] = data.PermitID;
          permitData[key] = mapData;
          permitIdKeys.push(data.PermitID);
        }
      });

      updateSelectedWellIds(wellIds, wellData, wellIdKeys, wellUwis);
      updateSelectedPermitIds(permitIds, permitData, permitIdKeys);
      updateAllSelectedGridData({ ...wellIds, ...permitIds });
    }
  };

  const getCheckBoxElements = () => {
    const checkboxContainer = document.querySelector(
      ".MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root"
    );
    const svgCheckbox = document.querySelector(
      ".MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root svg"
    );
    const SVGCheckboxPath = document.querySelector(
      ".MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root svg path"
    );

    return { checkboxContainer, svgCheckbox, SVGCheckboxPath };
  };

  const setCheckboxHeaderInterdetminate = (determinateCheckbox: Element) => {
    const indeterminateSVGPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    indeterminateSVGPath.setAttribute(
      "d",
      "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z"
    );

    const checkBoxElements = getCheckBoxElements();

    if (checkBoxElements.checkboxContainer)
      checkBoxElements.checkboxContainer.classList.add(
        "MuiCheckbox-indeterminate"
      );
    if (checkBoxElements.svgCheckbox && checkBoxElements.SVGCheckboxPath)
      checkBoxElements.svgCheckbox.replaceChild(
        indeterminateSVGPath,
        checkBoxElements.SVGCheckboxPath
      );

    determinateCheckbox.setAttribute("data-indeterminate", "true");
  };

  const setCheckboxHeaderDeterminate = (indeterminateCheckbox: Element) => {
    const newSVGPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    newSVGPath.setAttribute(
      "d",
      "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
    );

    const checkBoxElements = getCheckBoxElements();

    if (checkBoxElements.checkboxContainer)
      checkBoxElements.checkboxContainer.classList.remove(
        "MuiCheckbox-indeterminate"
      );
    if (checkBoxElements.svgCheckbox && checkBoxElements.SVGCheckboxPath)
      checkBoxElements.svgCheckbox.replaceChild(
        newSVGPath,
        checkBoxElements.SVGCheckboxPath
      );
    indeterminateCheckbox.setAttribute("data-indeterminate", "false");
  };

  const handleNewSelection = (
    selectedObj: SelectedKeys | { [key: string]: boolean }
  ) => {
    const newselectedData = getSelectedData(selectedObj);
    const newSelectedPermitData: SelectedKeysWithData = {};
    const newSelectedPermitIdKeys: SelectedKeys = {};
    const newSelectedWellData: SelectedKeysWithData = {};
    const newSelectedWellIdKeys: SelectedKeys = {};
    const newSelectedWellIds: number[] = [];
    const newSelectedWellUwis: string[] = [];
    const newSelectedPermitIds: number[] = [];

    for (const [key, value] of Object.entries(newselectedData)) {
      switch (value.RecordType) {
        case RECORD_TYPES.WELL:
          if (value.WellID) {
            newSelectedWellIdKeys[key] = value.WellID;
            newSelectedWellData[key] = value;
            newSelectedWellIds.push(value.WellID);

            if (value.UWI) newSelectedWellUwis.push(value.UWI);
          }
          break;
        case RECORD_TYPES.PERMIT:
          if (value.PermitID) {
            newSelectedPermitIdKeys[key] = value.PermitID;
            newSelectedPermitData[key] = value;
            newSelectedPermitIds.push(value.PermitID);
          }
          break;
      }
    }

    return {
      newSelectedPermitData,
      newSelectedPermitIdKeys,
      newSelectedPermitIds,
      newSelectedWellData,
      newSelectedWellIdKeys,
      newSelectedWellIds,
      newSelectedWellUwis,
    };
  };

  return {
    selectedGridDataKeys,
    allSelectedWellIdsKeys,
    getSelectedData,
    headerSelectCallback,
    setCheckboxHeaderInterdetminate,
    setCheckboxHeaderDeterminate,
    convertWellDataArrayToObject,
    isSelectedWellsInGrid,
    handleNewSelection,
  };
};
