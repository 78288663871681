import { useCallback, useState } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import { GetTopAttributesResponse } from "../../types/map/mapSettings/useTopAttributeValues";

import config from "../../configs/appSettings";

import { CUM_BOE } from "../../constants/attributes";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";

import { callServiceAPI } from "../../action/callServiceAPI";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import { useGridSelectedData } from "../grid/useGridSelectedData";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";
import useSearchStore from "../search/useSearchStore";
import useChartSearchRequest from "./useChartSearchRequest";

export const useChartTopAttribute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<GetTopAttributesResponse | undefined>(
    undefined
  );

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } = useIdentifierFileUpload(
    RECORD_TYPES.WELL
  );

  const isFromIdentifierSearchUploadedFile = searchStore(
    (state) => state.isFromIdentifierSearchUploadedFile
  );
  // END TEMP: temporarily set to well

  const { isSelectedAllGrid } = useGridSelectedData();

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const { buildChartPostRequest } = useChartSearchRequest();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const getChartTopAttributes = useCallback(
    async (attribute: string, chartType: ChartType) => {
      setIsLoading(true);

      try {
        setIsError(false);
        const body = {
          ...buildChartPostRequest(chartType),
          ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
        };
        const sortBy = CUM_BOE.key;
        const topAttributesUtl = `${config.endpoints.wellService}api/wells/top/${attribute}/by/${sortBy}`;

        const response = await callServiceAPI<GetTopAttributesResponse>(
          topAttributesUtl,
          body ?? {},
          getIdentifierFileIdWithRetry,
          identifierSearchUploadedFile,
          isFromIdentifierSearchUploadedFile
        );

        if (!response || !("data" in response)) return;

        setData(response.data);
      } catch (e) {
        setIsError(true);
        setData([]);
        console.log("getChartData error", e);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [
      isSelectedAllGrid,
      selectedGridDataKeys,
      isFromIdentifierSearchUploadedFile,
      identifierSearchUploadedFile,
      getIdentifierFileIdWithRetry,
    ]
  );
  return {
    isLoading,
    data,
    isError,
    getChartTopAttributes,
  };
};
