import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { Button } from "component-library";

import {
  RecordType,
  SearchPanelTab,
} from "../../../../types/panels/searchPanel/search";

import {
  ATTRIBUTES_TAB,
  IDENTIFIER_TAB,
} from "../../../../constants/panels/searchPanel/common/accordion";

import useSearchPanelStore from "../../../../store/search/panel/useSearchPanelStore";

import CustomCircularButton from "../../../common/CustomCircularButton";
import ModifyAddIcon from "../../../common/icons/searchPanel/ModifyAddIcon";

interface AccordionActionButtonsProps {
  searchTypeTab: SearchPanelTab;
  searchRecordType: RecordType;
  updateSearchTypeTab: (tab: SearchPanelTab) => void;
}

const AccordionActionButtons = ({
  searchTypeTab,
  searchRecordType,
  updateSearchTypeTab,
}: AccordionActionButtonsProps) => {
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );
  return (
    <div className="accordion-action-button-group">
      <ToggleButtonGroup
        exclusive
        size="small"
        value={searchTypeTab}
        onChange={(_, newValue) => {
          if (newValue !== null) {
            updateSearchTypeTab(newValue as SearchPanelTab);
          }
        }}
        className="search-type-toggle"
      >
        <ToggleButton value={ATTRIBUTES_TAB} aria-label="left aligned">
          ATTRIBUTE
        </ToggleButton>
        <ToggleButton value={IDENTIFIER_TAB} aria-label="centered">
          IDENTIFIER
        </ToggleButton>
      </ToggleButtonGroup>
      <div className="clear-modify-btn-group">
        <Button
          text="CLEAR"
          type="tertiary"
          state="enabled"
          onClick={() => null}
        />
        {searchTypeTab === ATTRIBUTES_TAB && (
          <CustomCircularButton
            width={28}
            height={28}
            isActive={openedQBEditorPanel === searchRecordType}
            onClick={() => {
              if (openedQBEditorPanel === searchRecordType) {
                updateOpenedQBEditorPanel(null);
              } else {
                updateOpenedQBEditorPanel(searchRecordType);
              }
            }}
            className="modify-add-more-btn"
          >
            <ModifyAddIcon />
          </CustomCircularButton>
        )}
      </div>
    </div>
  );
};

export default AccordionActionButtons;
