import { Accordion as CLAccordion } from "component-library";

import {
  BOTTOM_PERMIT_SPOTS,
  BOTTOM_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../constants/constants";
import * as mapSettings from "../../constants/map/mapSettings";

import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import useRecordType from "../../customHooks/common/useRecordType";
import useWellStyleAccordion from "../../customHooks/map/mapSettings/useWellStyleAccordion";

import { isLayerSelected } from "../../utils/map/mapSettings";
import { getWellStylingControls } from "../../utils/map/settings/settings";

import AccordionSection from "../common/AccordionSection";
import Select from "../common/Select";
import MapSettingsCheckbox from "./MapSettingsCheckbox";
import ColorByAccordion from "./colorByAccordion/ColorByAccordion";

const WellStyleAccordion = () => {
  const layers = useMapStore((state) => state.layers);
  const currentZoom = useMapStore((state) => state.currentZoom);

  const isHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.isHighlightSelectedSpots
  );
  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const updateMapHoverEnabled = useMapHoverStore(
    (state) => state.updateMapHoverEnabled
  );
  const mapHoverEnabled = useMapHoverStore((state) => state.mapHoverEnabled);
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);

  const { wellPermitCardDisabled, handleLayerStyleChange, handleToggleLayer } =
    useWellStyleAccordion();

  const { searchedRecordTypes } = useRecordType();

  return (
    <CLAccordion
      isDefaultOpen
      title="Well & Permit Styling"
      className="cl-custom-accordion"
    >
      {getWellStylingControls(searchedRecordTypes)?.HighlightSelectedWell && (
        <MapSettingsCheckbox
          label={mapSettings.WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL.label}
          tooltipText="Search required and select well/s"
          isChecked={isHighlightSelectedSpots}
          isDisabled={!selectedGridDataKeys.length}
          handleChange={() => {
            toggleIsHighlightSelectedSpots();
          }}
        />
      )}

      {getWellStylingControls(searchedRecordTypes)?.WellPermitCard && (
        <MapSettingsCheckbox
          label={mapSettings.WELL_STYLING_WELL_PERMIT_CARD_CONTROL.label}
          tooltipText="Search required, and turn on Surface Hole Location or Bottom Hole Location or Well Path"
          isChecked={mapHoverEnabled}
          isDisabled={wellPermitCardDisabled}
          handleChange={() => {
            updateMapHoverEnabled(!mapHoverEnabled);
          }}
        />
      )}

      {getWellStylingControls(searchedRecordTypes)?.WellPaths && (
        <MapSettingsCheckbox
          label={mapSettings.WELL_STYLING_WELL_PATHS_CONTROL.label}
          tooltipText="Min zoom 12"
          isChecked={isLayerSelected(WELL_PATH_AND_STICKS, layers)}
          isDisabled={currentZoom < 12}
          handleChange={() => {
            handleToggleLayer(WELL_PATH_AND_STICKS);
          }}
        />
      )}

      {getWellStylingControls(searchedRecordTypes)
        ?.PermitSurfaceHoleLocation && (
        <MapSettingsCheckbox
          label={
            mapSettings.WELL_STYLING_PERMIT_SURFACE_HOLE_LOCATION_CONTROL.label
          }
          isChecked={isLayerSelected(PERMIT_SPOTS, layers)}
          handleChange={() => {
            handleToggleLayer(PERMIT_SPOTS);
          }}
        />
      )}

      {getWellStylingControls(searchedRecordTypes)
        ?.PermitBottomHoleLocation && (
        <MapSettingsCheckbox
          label={
            mapSettings.WELL_STYLING_PERMIT_BOTTOM_HOLE_LOCATION_CONTROL.label
          }
          tooltipText="Min zoom 12"
          isChecked={isLayerSelected(BOTTOM_PERMIT_SPOTS, layers)}
          isDisabled={currentZoom < 12}
          handleChange={() => {
            handleToggleLayer(BOTTOM_PERMIT_SPOTS);
          }}
        />
      )}

      {getWellStylingControls(searchedRecordTypes)?.SurfaceHoleLocation && (
        <AccordionSection
          hasCheckbox
          title={mapSettings.WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL.label}
          isSelected={layers[0].isSelected}
          onSelect={() => handleToggleLayer(WELL_SPOTS)}
        >
          <Select
            label="Size"
            labelId="surface-size"
            value={layerStyles[WELL_SPOTS].size}
            options={mapSettings.WELL_SPOT_SIZES}
            onChange={(e) =>
              handleLayerStyleChange(e, WELL_SPOTS, mapSettings.SIZE)
            }
            className="custom-select"
          />
        </AccordionSection>
      )}

      {getWellStylingControls(searchedRecordTypes)?.BottomHoleLocation && (
        <AccordionSection
          hasCheckbox
          title={mapSettings.WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL.label}
          infoMessage={currentZoom < 12 ? "Min zoom 12" : undefined}
          disabled={currentZoom < 12}
          isSelected={layers[1].isSelected}
          onSelect={() => handleToggleLayer(BOTTOM_WELL_SPOTS)}
        >
          <Select
            label="Size"
            labelId="bottom-size"
            disabled={currentZoom < 12}
            value={layerStyles[BOTTOM_WELL_SPOTS].size}
            options={mapSettings.WELL_SPOT_SIZES}
            onChange={(e) =>
              handleLayerStyleChange(e, BOTTOM_WELL_SPOTS, mapSettings.SIZE)
            }
            className="custom-select"
          />
        </AccordionSection>
      )}
      <ColorByAccordion />
    </CLAccordion>
  );
};

export default WellStyleAccordion;
