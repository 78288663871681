import { FC } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import classNames from "classnames";

import {
  DefaultAttributeProps,
  OperationTypeKeys,
} from "../../../../../../types/panels/searchPanel/queryBuilder";

import { DATE_OPTIONS } from "../../../../../../constants/panels/searchPanel/queryBuilder/opTypes";

import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import QBDateFields from "./QBDateFields";

const QBDateAttribute: FC<DefaultAttributeProps> = ({
  attribute,
  attributeQBInfo,
  searchRecordType,
  modifyAttributeBounds,
}) => {
  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);
  const updateIsQBUpdated = searchStore((state) => state.updateIsQBUpdated);

  const handleOperationChange = (e: SelectChangeEvent) => {
    const operationType = e.target.value as OperationTypeKeys;
    updateCurrentQBInfo(attribute.key, "operationType", operationType);

    if (attributeQBInfo.values.length < 1) return;
    updateCurrentQBInfo(attribute.key, "values", []);
    updateIsQBUpdated(true);
    modifyAttributeBounds([], operationType);
  };

  return (
    <div
      className={classNames("numeric-date-attribute", {
        "has-optype-selected": !!attributeQBInfo.operationType,
      })}
    >
      <FormControl className="select-operation-type">
        <InputLabel id="select-label">{attribute.label}</InputLabel>
        <Select
          labelId="select-label"
          variant="outlined"
          value={attributeQBInfo.operationType}
          label={attribute.label}
          onChange={handleOperationChange}
        >
          {DATE_OPTIONS.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <QBDateFields
        attribute={attribute}
        attributeQBInfo={attributeQBInfo}
        operationType={attributeQBInfo.operationType}
        searchRecordType={searchRecordType}
        modifyAttributeBounds={modifyAttributeBounds}
      />
    </div>
  );
};

export default QBDateAttribute;
