import { StateCreator } from "zustand";

import {
  IdentifierCountSlice,
  IdentifierFileUploadRetrySlice,
  IdentifierFileUploadSlice,
  IdentifierPastedSlice,
  IdentifierSearchResetSlice,
  IdentifierSearchState,
  IdentifierTypeSlice,
  IdentifierValidationSlice,
} from "../../../types/panels/searchPanel/identifier/store";

import { IDENTIFIER_TYPE_KEY } from "../../../constants/panels/searchPanel/identifier/types";

const identifierTypeSlice: StateCreator<IdentifierTypeSlice> = (set) => ({
  selectedIdentifier: IDENTIFIER_TYPE_KEY.UWI,
  updateSelectedIdentifier: (selectedIdentifier) => set({ selectedIdentifier }),

  //form state without triggering the search
  identifierFormSearchMode: null,
  updateIdentifierFormSearchMode: (identifierFormSearchMode) =>
    set({ identifierFormSearchMode }),
});

const identifierCountSlice: StateCreator<IdentifierCountSlice> = (set) => ({
  isLoadingIdentifierSearchCount: false,
  updateisLoadingIdentifierSearchCount: (isLoadingIdentifierSearchCount) =>
    set({ isLoadingIdentifierSearchCount }),
});

const identifierValidationSlice: StateCreator<IdentifierValidationSlice> = (
  set
) => ({
  isIdentifierFormInvalid: false,
  updateIsIdentifierFormInvalid: (isIdentifierFormInvalid) =>
    set({ isIdentifierFormInvalid }),
});

const identifierPastedSlice: StateCreator<
  IdentifierSearchState,
  [],
  [],
  IdentifierPastedSlice
> = (set) => ({
  identifierSearchPastedValueText: "",
  updateIdentifierSearchPastedValueText: (identifierSearchPastedValueText) =>
    set({ identifierSearchPastedValueText }),
  identifierSearchPastedValueList: [],
  updateIdentifierSearchPastedValueList: (identifierSearchPastedValueList) =>
    set({ identifierSearchPastedValueList }),
  //TODO: Decoupling | Move a different slice
  loadSavedIdentifiers: (identifiers, isFileUpload) =>
    set({
      identifierSearchPastedValueText: identifiers.join("\n"),
      isIdentifierFormInvalid: false,
      identifierSearchPastedValueList: isFileUpload ? [] : identifiers,
    }),
});

const identifierFileUploadSlice: StateCreator<IdentifierFileUploadSlice> = (
  set
) => ({
  identifierSearchUploadedFile: null,
  updateIdentifierSearchUploadedFile: (identifierSearchUploadedFile) =>
    set({ identifierSearchUploadedFile }),
  identifierSearchUploadedFileId: null,
  updateIdentifierSearchUploadedFileId: (fileId) =>
    set({ identifierSearchUploadedFileId: fileId }),
  uploadFileProgress: 0,
  updateUploadFileProgress: (progress) => set({ uploadFileProgress: progress }),
  identifierFileTimeStamp: null,
  updateIdentifierFileTimeStamp: (identifierFileTimeStamp) =>
    set({ identifierFileTimeStamp }),

  isLoadingSavedSearchFileUpload: false,
  updateIsLoadingSavedSearchFileUpload: (isLoading) =>
    set({ isLoadingSavedSearchFileUpload: isLoading }),
  isLoadingIdentifierFileUpload: false,
  updateIsLoadingIdentifierFileUpload: (isLoading) =>
    set({ isLoadingIdentifierFileUpload: isLoading }),
});

//TODO: Decoupling remove this use another state to reference
const identifierFileUploadRetrySlice: StateCreator<
  IdentifierFileUploadRetrySlice
> = (set) => ({
  isFromIdentifierSearchUploadedFile: false,
  updateIsFromIdentifierSearchUploadedFile: (isUploaded) =>
    set({ isFromIdentifierSearchUploadedFile: isUploaded }),
});

//TODO: Decoupling | Re-group reset states
const identifierSearchResetSlice: StateCreator<
  IdentifierSearchState,
  [],
  [],
  IdentifierSearchResetSlice
> = (set) => ({
  resetIdentifierSearchPastedValueList: () =>
    set({
      identifierSearchPastedValueText: "",
      isIdentifierFormInvalid: false,
      identifierSearchPastedValueList: [],
    }),
  resetIdentifierFileUploadStates: () =>
    set({
      identifierSearchUploadedFile: null,
      identifierSearchUploadedFileId: "",
      uploadFileProgress: 0,
      isFromIdentifierSearchUploadedFile: false,
    }),
  resetIdentifierStates: () =>
    set({
      identifierSearchPastedValueText: "",
      isFromIdentifierSearchUploadedFile: false,
      isIdentifierFormInvalid: false,
      identifierSearchPastedValueList: [],
      identifierSearchUploadedFile: null,
      identifierSearchUploadedFileId: "",
      uploadFileProgress: 0,
    }),
});

export {
  identifierTypeSlice,
  identifierCountSlice,
  identifierPastedSlice,
  identifierFileUploadSlice,
  identifierSearchResetSlice,
  identifierValidationSlice,
  identifierFileUploadRetrySlice,
};
