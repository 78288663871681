import { useEffect, useMemo, useState } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import classNames from "classnames";

import { Attribute } from "../../../types/common/attributes";

import {
  BY_ATTRIBUTE,
  COLOR_BY_OPTIONS,
  LEGEND,
  UNIFORM_COLOR,
} from "../../../constants/map/mapSettings";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import useRecordType from "../../../customHooks/common/useRecordType";
import useSearchCriteria from "../../../customHooks/search/useSearchCriteria";

import { getWellStylingControls } from "../../../utils/map/settings/settings";

import TooltipInfo from "../../common/TooltipInfo";
import ApplyWellColorToPermits from "../ApplyWellColorToPermits";
import AttributeSelect from "./AttributeSelect";

interface ColorBySelectProps {
  layerName: string;
  colorByOptions: Attribute[];
  updateLayerStyleColors: (color: string) => void;
}

const ColorBySelect = ({
  layerName,
  colorByOptions,
  updateLayerStyleColors,
}: ColorBySelectProps) => {
  const [colorBy, setColorBy] = useState(UNIFORM_COLOR.key);
  const [disabledOptions, setDisabledOptions] = useState({
    [UNIFORM_COLOR.key]: false,
    [BY_ATTRIBUTE.key]: true,
  });

  const appendMapOverlayOpened = useMapSettingsStore(
    (state) => state.appendMapOverlayOpened
  );
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);

  const { hasSearchCriteria } = useSearchCriteria();
  const { searchedRecordTypes } = useRecordType();

  const attributeKey = useMemo(
    () => layerStyles[layerName].color,
    [layerName, layerStyles]
  );

  const handleColorByOptionChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const prevColorBy = attributeKey;

    //prevent click if disabled option
    if (disabledOptions[value]) return;

    // Only update to uniform when previously not uniform
    if (value === UNIFORM_COLOR.key && prevColorBy !== UNIFORM_COLOR.key) {
      handleUniformSelect(value);
    }
    setColorBy(e.target.value);
  };

  // triggers when uniform color is selected
  const handleUniformSelect = (value: string) => {
    updateLayerStyleColors(value);
    appendMapOverlayOpened(LEGEND.key);
  };

  // triggers when an attribute is selected
  const handleAttributeSelect = (attribute: string) => {
    updateLayerStyleColors(attribute);
    appendMapOverlayOpened(LEGEND.key);
  };

  useEffect(() => {
    if (attributeKey === UNIFORM_COLOR.key) {
      setColorBy(UNIFORM_COLOR.key);
      return;
    }
    setColorBy(BY_ATTRIBUTE.key);
  }, [attributeKey]);

  useEffect(() => {
    setDisabledOptions((prevState) => ({
      ...prevState,
      [BY_ATTRIBUTE.key]: !hasSearchCriteria,
    }));
  }, [hasSearchCriteria]);

  return (
    <div className="layer-color-select-container">
      <FormControl className="custom-select">
        <InputLabel id="layer-color-select">Color By</InputLabel>
        <Select
          labelId="layer-color-select"
          label="Color By"
          value={colorBy}
          onChange={handleColorByOptionChange}
          MenuProps={{
            className: "custom-color-select-menu",
          }}
        >
          {COLOR_BY_OPTIONS.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {disabledOptions[key] ? (
                <>
                  <span
                    className={classNames({
                      "disabled-option full-width": disabledOptions[key],
                    })}
                  >
                    {label}
                  </span>
                  <TooltipInfo text={"Search required"} position="left" />
                </>
              ) : (
                label
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {colorBy === BY_ATTRIBUTE.key && (
        <AttributeSelect
          layerName={layerName}
          colorByOptions={colorByOptions}
          onAttributeSelect={handleAttributeSelect}
        />
      )}

      {getWellStylingControls(searchedRecordTypes)?.ApplyColorToPermits &&
        colorBy === BY_ATTRIBUTE.key && <ApplyWellColorToPermits />}
    </div>
  );
};

export default ColorBySelect;
