import { StateCreator } from "zustand";

import { SearchCriteriaSlice } from "../../../types/panels/searchPanel/search";

// Store for currently searched criterias and record type
const wellsSearchCriteriaSlice: StateCreator<
  Partial<SearchCriteriaSlice>
> = () => ({
  isSearchedRecordType: true,
});

export default wellsSearchCriteriaSlice;
