import { useMemo } from "react";

import classNames from "classnames";

import { WellPermitPanelState } from "../../types/panels/sidebar";

import { SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import usePanelsStore from "../../store/panels/panelsStore";

import { formatUWI10ByCountry } from "../../utils/common/wells";

import WellPanelBubbleIcon from "../common/icons/WellPanelBubbleIcon";
import WellPanelDashedLineIcon from "../common/icons/WellPanelDashedLineIcon";

interface SidebarWellPanelSlotProps {
  wellPermitData: WellPermitPanelState;
}
const SidebarWellPanelSlot = ({
  wellPermitData,
}: SidebarWellPanelSlotProps) => {
  const updateActiveWellPanel = usePanelsStore(
    (state) => state.updateActiveWellPanel
  );
  const updateActivePermitPanel = usePanelsStore(
    (state) => state.updateActivePermitPanel
  );
  const activePanel = usePanelsStore((state) => state.activePanel);
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );

  const onClickHandle = () => {
    if (activePanel !== SIDEBAR_MENU_KEYS.WELL_PANEL) {
      updateActivePanel(SIDEBAR_MENU_KEYS.WELL_PANEL);
    }

    const sidebarPaperEl = document.querySelector(
      ".sidebar-drawer-style-paper"
    ) as HTMLElement;
    if (sidebarPaperEl) sidebarPaperEl.style.width = "50px";

    if (wellPermitData && !wellPermitData.isActive) {
      switch (wellPermitData.recordType) {
        case RECORD_TYPES.WELL:
          updateActiveWellPanel(true, wellPermitData);
          toggleLeftSidePanel(true);
          updateActivePermitPanel(false);
          return;
        case RECORD_TYPES.PERMIT:
          updateActiveWellPanel(false);
          toggleLeftSidePanel(true);
          updateActivePermitPanel(true, wellPermitData);
          return;
        default:
          return;
      }
    } else {
      if (isOpenLeftSidePanel) {
        switch (wellPermitData.recordType) {
          case RECORD_TYPES.WELL:
            updateActiveWellPanel(false, wellPermitData);
            return;
          default:
            return;
        }
      }
      toggleLeftSidePanel();
    }
  };

  const isActiveBubble = useMemo(
    () =>
      wellPermitData.isActive && activePanel === SIDEBAR_MENU_KEYS.WELL_PANEL,
    [wellPermitData.isActive, activePanel]
  );

  const uwi10 = useMemo(
    () => formatUWI10ByCountry(wellPermitData.uwi, wellPermitData.country),
    [wellPermitData.uwi, wellPermitData.country]
  );

  return (
    <div className="well-panel-bubble">
      <WellPanelDashedLineIcon />
      <div
        className="well-panel-uwi-bubble-container"
        onClick={onClickHandle}
        role="presentation"
      >
        <WellPanelBubbleIcon filled={isActiveBubble} />
        <div
          className={classNames("well-panel-side-text", {
            active: isActiveBubble,
          })}
        >
          {uwi10}
        </div>
      </div>
    </div>
  );
};

export default SidebarWellPanelSlot;
