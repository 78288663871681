import * as constants from "../../../attributes";
import { EQ, IN } from "./opTypes";

export const defaultVarcharInfo = {
  options: [],
  operationType: IN.key,
  values: [],
  input: "",
  hasError: false,
  isUpdated: false,
} as const;

export const defaultDateNumericInfo = {
  ...defaultVarcharInfo,
  operationType: "",
} as const;

export const initialOperationType = {
  [constants.TEXT]: IN.key,
  [constants.TEXT_SELECT]: IN.key,
  [constants.FLOAT]: "",
  [constants.INTEGER]: "",
  [constants.BOOLEAN]: EQ.key,
  [constants.DATE]: "",
  [constants.SPECIAL]: IN.key,
} as const;
