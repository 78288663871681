import { StateCreator } from "zustand";

import { SearchAccordionSlice } from "../../../types/panels/searchPanel/accordion/store";

const permitsAccordionSlice: StateCreator<
  Partial<SearchAccordionSlice>
> = () => ({
  isSearchAccordionToggledOn: false,
});

export default permitsAccordionSlice;
