import { StateCreator } from "zustand";

import {
  BulkResetStateStore,
  DataGridAllWellDataStore,
  DataGridBatchWellDataStore,
  DataGridColumnStore,
  DataGridCountStore,
  DataGridFilterStore,
  DataGridSortStore,
  DataGridStateStore,
  SearchCriteriaStore,
} from "../../types/grid";

import {
  columnVisibilityList,
  defaultColumnsModel,
  defaultColumnsOrder,
  defaultFilterModel,
  defaultSortModel,
} from "../../utils/datagrid";

import { dataGridColumnSlice } from "./dataGridColumnSlice";
import {
  dataGridAllWellDataSlice,
  dataGridBatchWellDataSlice,
} from "./dataGridDataSlice";
import { dataGridFilterSlice } from "./dataGridFilterSlice";
import { dataGridCountSlice, dataGridStateStore } from "./dataGridSlice";
import { dataGridSortSlice } from "./dataGridSortSlice";
import { searchCriteriaSlice } from "./searchCriteriaSlice";

const bulkResetStateSlice: StateCreator<
  BulkResetStateStore &
    SearchCriteriaStore &
    DataGridFilterStore &
    DataGridSortStore &
    DataGridColumnStore &
    DataGridStateStore &
    DataGridAllWellDataStore &
    DataGridBatchWellDataStore &
    DataGridCountStore,
  [],
  [],
  BulkResetStateStore
> = (...a) => ({
  resetSortFilterModels: () => {
    dataGridFilterSlice(...a).updateFilterModel(defaultFilterModel);
    dataGridFilterSlice(...a).updateAllSelectedFilterAttributes([]);
    dataGridFilterSlice(...a).resetColumnsFilterType();
    dataGridFilterSlice(...a).resetColumnFilterAttributes();
    dataGridSortSlice(...a).updateSortModel(defaultSortModel);
    dataGridSortSlice(...a).updateSortByAnalysis(false);
    dataGridSortSlice(...a).updateSortPayload([]);
  },
  resetDefaultGridModels: () => {
    dataGridFilterSlice(...a).updateFilterModel(defaultFilterModel);
    dataGridFilterSlice(...a).updateAllSelectedFilterAttributes([]);
    dataGridFilterSlice(...a).resetColumnsFilterType();
    dataGridFilterSlice(...a).resetColumnFilterAttributes();
    dataGridSortSlice(...a).updateSortModel(defaultSortModel);
    dataGridSortSlice(...a).updateSortByAnalysis(false);
    dataGridColumnSlice(...a).updateColumnVisibilityModel(columnVisibilityList);
    dataGridColumnSlice(...a).updateColumnsOrder(defaultColumnsOrder);
    dataGridColumnSlice(...a).updateColumnsFetched(defaultColumnsModel);
  },

  // reset grid data and hide grid (same as before triggering the search)
  resetGridStates: () => {
    dataGridStateStore(...a).toggleGrid(false);
    dataGridStateStore(...a).toggleGridHeader(false);
    dataGridAllWellDataSlice(...a).updateAllGridData([]);
    dataGridBatchWellDataSlice(...a).updateBatchWellGridData([]);
    dataGridCountSlice(...a).updateGridFilteredCount(0);
    dataGridCountSlice(...a).updateGridTotalCount(0);
    searchCriteriaSlice(...a).updateInitialGridSearchMade(false);
    dataGridStateStore(...a).toggleFullScreenGrid(false);
  },
});

export { bulkResetStateSlice };
