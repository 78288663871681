import { FC, useCallback, useEffect, useState } from "react";

import { DateValidationError } from "@mui/x-date-pickers";

import dayjs, { Dayjs } from "dayjs";

import { DateFormats } from "../../../../../../types/common/common";
import { NumDateFieldProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import {
  YEAR_DATE_FORMAT,
  YEAR_FORMAT,
} from "../../../../../../constants/constants";
import {
  BETWEEN,
  YR,
} from "../../../../../../constants/panels/searchPanel/queryBuilder/opTypes";

import useStore from "../../../../../../store/useStore";

import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import { getFormattedDate } from "../../../../../../utils/helper";

import DateField from "../../../../../common/DateField";
import YearField from "../../../../../common/YearField";

const QBDateFields: FC<NumDateFieldProps> = ({
  attribute,
  attributeQBInfo,
  operationType,
  searchRecordType,
  modifyAttributeBounds,
}) => {
  const INVALID_DATE = "Invalid Date";
  const MIN_DATE = dayjs("01/01/1900");
  const MAX_DATE = dayjs("12/31/2099");

  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);

  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );

  const getStoreValue = useCallback(
    (index: number, isYearOnly = false) => {
      if (attributeQBInfo.values.length <= index) return null;

      const value = attributeQBInfo.values[index];
      return dayjs(isYearOnly ? `${value}-01-01` : value);
    },
    [attributeQBInfo.values]
  );

  // Date Values
  const [value, setValue] = useState<Dayjs | null>(getStoreValue(0));
  const [start, setStart] = useState<Dayjs | null>(getStoreValue(0));
  const [end, setEnd] = useState<Dayjs | null>(getStoreValue(1));
  const [year, setYear] = useState<Dayjs | null>(getStoreValue(0));

  const [startError, setStartError] = useState<boolean>(false);
  const [endError, setEndError] = useState<boolean>(false);

  useEffect(() => {
    if (fetchedSavedSearchTrigger) return;
    setValue(getStoreValue(0));
    setStart(getStoreValue(0));
    setEnd(getStoreValue(1));
    setYear(getStoreValue(0, true));
  }, [operationType, fetchedSavedSearchTrigger, getStoreValue]);

  const updateBoundsAndQBInfo = (values: number[]) => {
    updateCurrentQBInfo(attribute.key, "values", values);
    modifyAttributeBounds(values, operationType);
  };

  const isSameValue = (value: string, i: number) =>
    attributeQBInfo.values[i] && attributeQBInfo.values[i] === value;

  const handleChange = (
    newValue: Dayjs,
    changeFunc: (value: Dayjs) => void,
    format: DateFormats
  ) => {
    changeFunc(newValue);

    const newDate = getFormattedDate(newValue, format);
    if (attributeQBInfo.values.length && newDate === INVALID_DATE) {
      updateBoundsAndQBInfo([]);
    }
  };

  const handleStartEndChange = (
    newValue: Dayjs,
    changeFunc: (value: Dayjs) => void,
    otherValue: Dayjs | null
  ) => {
    changeFunc(newValue);

    const newDate = getFormattedDate(newValue, YEAR_DATE_FORMAT);
    const otherDate = getFormattedDate(otherValue, YEAR_DATE_FORMAT);
    if (
      attributeQBInfo.values.length > 0 &&
      (newDate === INVALID_DATE || otherDate === INVALID_DATE)
    ) {
      updateBoundsAndQBInfo([]);
    }
  };

  // error handling
  const handleError = (error: DateValidationError) => {
    updateCurrentQBInfo(attribute.key, "hasError", error ? true : false);
  };
  const handleStartEndError = (error: DateValidationError, type: string) => {
    type === "start"
      ? setStartError(error ? true : false)
      : setEndError(error ? true : false);
  };

  useEffect(() => {
    updateCurrentQBInfo(
      attribute.key,
      "hasError",
      startError || endError ? true : false
    );
  }, [startError, endError, attribute.key, updateCurrentQBInfo]);

  // Updating the search attribute values onBlur
  const handleBlur = (newValue: Dayjs | null, format: DateFormats) => {
    if (attributeQBInfo.hasError || !newValue) return;

    let formattedValue = getFormattedDate(newValue, format);
    if (formattedValue === INVALID_DATE) return;

    if (format === YEAR_FORMAT) {
      formattedValue = parseInt(formattedValue);
    }
    if (isSameValue(formattedValue, 0)) return;

    updateBoundsAndQBInfo([formattedValue]);
  };
  const handleStartEndBlur = () => {
    if (attributeQBInfo.hasError || !start || !end) return;

    const startDate = getFormattedDate(start, YEAR_DATE_FORMAT);
    const endDate = getFormattedDate(end, YEAR_DATE_FORMAT);
    if (
      startDate === INVALID_DATE ||
      endDate === INVALID_DATE ||
      (isSameValue(startDate, 0) && isSameValue(endDate, 1))
    ) {
      return;
    }

    updateBoundsAndQBInfo([startDate, endDate]);
  };

  const renderField = () => {
    switch (operationType) {
      case "":
        return null;
      case BETWEEN.key:
        return (
          <div className="row-fields">
            <div className="is-between-fields">
              <DateField
                label="Start"
                value={start}
                maxDate={end || MAX_DATE}
                minDate={MIN_DATE}
                handleChange={(newValue: Dayjs) =>
                  handleStartEndChange(newValue, setStart, end)
                }
                handleBlur={handleStartEndBlur}
                handleError={(error: DateValidationError) =>
                  handleStartEndError(error, "start")
                }
              />
              <DateField
                label="End"
                value={end}
                maxDate={MAX_DATE}
                minDate={start || MIN_DATE}
                handleChange={(newValue: Dayjs) =>
                  handleStartEndChange(newValue, setEnd, start)
                }
                handleBlur={handleStartEndBlur}
                handleError={(error: DateValidationError) =>
                  handleStartEndError(error, "end")
                }
              />
            </div>
          </div>
        );
      case YR.key:
        return (
          <YearField
            label="Year"
            value={year}
            handleChange={(newValue: Dayjs) =>
              handleChange(newValue, setYear, YEAR_FORMAT)
            }
            handleBlur={() => handleBlur(year, YEAR_FORMAT)}
            handleError={handleError}
          />
        );
      default:
        return (
          <DateField
            label="Date"
            value={value}
            handleChange={(newValue: Dayjs) =>
              handleChange(newValue, setValue, YEAR_DATE_FORMAT)
            }
            handleBlur={() => handleBlur(value, YEAR_DATE_FORMAT)}
            handleError={handleError}
          />
        );
    }
  };

  return renderField();
};

export default QBDateFields;
