import { useMemo } from "react";

import {
  SAVED_SEARCH_VISIBILITY_PRIVATE,
  SAVED_SEARCH_VISIBILITY_PUBLIC,
} from "../../constants/panels/savedSearchPanel/hooks";
import {
  ATTRIBUTES_TAB,
  IDENTIFIER_TAB,
} from "../../constants/panels/searchPanel/common/accordion";
import { IDENTIFIER_SEARCH_MODE } from "../../constants/panels/searchPanel/identifier/types";
import {
  IDENTIFIER_SEARCH_TYPES,
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../constants/panels/searchPanel/search";

import useDataGridStore from "../../store/grid/dataGridStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";
import useStore from "../../store/useStore";

import { formatToNonAutoSearchType } from "../../utils/savedSearch/savedData";

import useSearchStore from "./useSearchStore";

const useSearchPanel = () => {
  const selectedSavedSearchData = useStore(
    (state) => state.selectedSavedSearchData
  );
  const savedSearchFileName = useStore((state) => state.savedSearchFileName);

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchTypeTab = searchStore((state) => state.searchTypeTab);
  const identifierFormSearchMode = searchStore(
    (state) => state.identifierFormSearchMode
  );
  // END TEMP: temporarily set to well

  const allGridData = useDataGridStore((state) => state.allGridData);

  const nonAutoSaveSearchType = useMemo(
    () => formatToNonAutoSearchType(selectedSavedSearchData?.searchType ?? ""),
    [selectedSavedSearchData]
  );

  const hasLoadedAttribSavedSearch = useMemo(
    () => nonAutoSaveSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH,
    [nonAutoSaveSearchType]
  );

  const hasLoadedIdentifierSavedSearch = useMemo(
    () => IDENTIFIER_SEARCH_TYPES.includes(nonAutoSaveSearchType),
    [nonAutoSaveSearchType]
  );
  const identifierSearchType = useMemo(() => {
    let searchType = SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH;
    if (
      identifierFormSearchMode === IDENTIFIER_SEARCH_MODE.FILE_CONVERTED_TEXT
    ) {
      searchType = SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH;
    } else if (identifierFormSearchMode === IDENTIFIER_SEARCH_MODE.FILE) {
      searchType = SEARCH_TYPES.IDENTIFIER_FILE_SEARCH;
    }
    return searchType;
  }, [identifierFormSearchMode]);

  const searchTypeByActiveTab = useMemo(
    () =>
      searchTypeTab === ATTRIBUTES_TAB
        ? SEARCH_TYPES.ATTRIBUTE_SEARCH
        : identifierSearchType,
    [searchTypeTab, identifierSearchType]
  );

  const searchTitle = useMemo(() => {
    const searchType = nonAutoSaveSearchType;

    if (
      (savedSearchFileName &&
        searchTypeTab === ATTRIBUTES_TAB &&
        searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) ||
      (savedSearchFileName &&
        searchTypeTab === IDENTIFIER_TAB &&
        (searchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH ||
          searchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH ||
          searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH))
    ) {
      return savedSearchFileName;
    } else {
      return "SEARCH";
    }
  }, [nonAutoSaveSearchType, searchTypeTab, savedSearchFileName]);

  const disableSharedSearch = useMemo(
    () => !Boolean(allGridData.length),
    [allGridData]
  );

  const isFilterIncluded = useMemo(() => {
    const checkSearchTitle = Boolean(
      (selectedSavedSearchData &&
        selectedSavedSearchData.visibility ===
          SAVED_SEARCH_VISIBILITY_PRIVATE &&
        searchTitle !== "SEARCH") ||
        (selectedSavedSearchData.visibility ===
          SAVED_SEARCH_VISIBILITY_PUBLIC &&
          searchTitle === "SEARCH")
    );

    return !selectedSavedSearchData?.searchType || checkSearchTitle;
  }, [searchTitle, selectedSavedSearchData]);

  return {
    hasLoadedAttribSavedSearch,
    hasLoadedIdentifierSavedSearch,
    searchTitle,
    searchTypeByActiveTab,
    identifierSearchType,
    disableSharedSearch,
    isFilterIncluded,
  };
};

export default useSearchPanel;
