import {
  IdentifierTypeInfo,
  IdentifierTypeKey,
} from "../../../../types/panels/searchPanel/identifier/typesInfo";

import { FILE_EXTENSIONS } from "../../../common/fileMetadata";
import { FILE_FORMAT_ERROR } from "./validation";

export const IDENTIFIER_TYPE_KEY = {
  UWI: "uwi",
} as const;

export const IDENTIFIER_SEARCH_MODE = {
  FILE: "file",
  TEXT: "text",
  FILE_CONVERTED_TEXT: "file-converted-text", //for pasted text converted to file
} as const;

export const IDENTIFIER_TYPE_INFO: IdentifierTypeInfo = {
  [IDENTIFIER_TYPE_KEY.UWI]: {
    label: "UWI",
    allowedFileTypes: [FILE_EXTENSIONS.TEXT, FILE_EXTENSIONS.CSV],
    placeholder: "Comma Delimited UWI14s or UWI16s",
    messageError: {
      format:
        "UWI’s must be between 10-14 or 16 characters and cannot include any of the following characters: `!@#$%^&*()_+-=[]{};':\"\\|.<>/?~",
      fileType: FILE_FORMAT_ERROR,
    },
  },
};

export const IDENTIFIER_TYPE_KEY_LIST = Object.keys(
  IDENTIFIER_TYPE_INFO
) as IdentifierTypeKey[];
