import { useEffect, useState } from "react";

import { AxiosError, AxiosResponse } from "axios";

import { APIErrorResponse, SearchRequestPayload } from "../types/common/api";
import {
  BaseSearchCriteria,
  WellCountDataPayload,
} from "../types/common/search";
import { GetWellCountDataResponse } from "../types/common/useSearchData";
import { RecordType } from "../types/panels/searchPanel/search";

import config from "../configs/appSettings";

import { callServiceAPI } from "../action/callServiceAPI";
import useSearchRequest from "./common/useSearchRequest";
import useIdentifierFileUpload from "./search/identifier/useIdentifierFileUpload";
import useSearchStore from "./search/useSearchStore";

interface UseWellCountDataProps {
  searchRecordType: RecordType;
}

const useWellCountData = ({ searchRecordType }: UseWellCountDataProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const searchStore = useSearchStore({
    searchRecordType,
  });
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const updateisLoadingIdentifierSearchCount = searchStore(
    (state) => state.updateisLoadingIdentifierSearchCount
  );

  const [dataPromise, setDataPromise] =
    useState<
      Promise<
        | AxiosError<APIErrorResponse, any>
        | AxiosResponse<GetWellCountDataResponse, any>
        | undefined
      >
    >();
  // const [searchType, setSearchType] = useState<SearchTypes>();
  const [data, setData] = useState(0);
  const [error, setError] = useState(null);
  const { getIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(searchRecordType);
  const { buildSearchRequestByParam } = useSearchRequest();

  // const updateLoadingState = useCallback(
  //   (searchType: SearchTypes | undefined, status: boolean) => {
  //     if (
  //       searchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH ||
  //       searchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH ||
  //       searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH
  //     ) {
  //       updateisLoadingIdentifierSearchCount(status);
  //     }
  //   },
  //   [updateisLoadingIdentifierSearchCount]
  // );

  const awaitDataPromise = async (
    dataPromise: Promise<
      | AxiosError<APIErrorResponse, any>
      | AxiosResponse<GetWellCountDataResponse, any>
      | undefined
    >
  ) => {
    const response = (await dataPromise) as AxiosResponse<
      GetWellCountDataResponse,
      any
    >;

    if (response?.data) {
      setData(response.data.totalCount);
    } else {
      setData(0);
    }
    setIsLoading(false);
    // updateLoadingState(searchType, false);
  };

  useEffect(() => {
    if (dataPromise) {
      awaitDataPromise(dataPromise);
    } else {
      setData(0);
      setIsLoading(false);
      // updateLoadingState(searchType, false);
    }
  }, [dataPromise]);

  let debouncedGetExpectedWellCountTimer: NodeJS.Timeout;
  const getExpectedWellCount = async ({
    drawnPolygons = [],
    bounds = [],
    searchedUWIs = [],
    shapeId = "",
    fileId = "",
    fetchedSavedSearchTrigger = false,
    isMetric,
    searchType,
  }: WellCountDataPayload) => {
    clearTimeout(debouncedGetExpectedWellCountTimer);

    debouncedGetExpectedWellCountTimer = setTimeout(() => {
      // setSearchType(searchType);
      setIsLoading(true);
      // updateLoadingState(searchType, true);
      setData(0);
      setError(null);

      // Update grid total count only after loading a saved search
      if (fetchedSavedSearchTrigger) {
        setData(0);
        setIsLoading(false);
        // updateLoadingState(searchType, false);
        return;
      }

      const requestBodySearchCriteria: BaseSearchCriteria = {
        drawnPolygons,
        shapeId,
        currentBounds: bounds,
        fileId,
        searchedUWIs,
      };

      const searchRequestBody = buildSearchRequestByParam(
        requestBodySearchCriteria
      );

      const body: SearchRequestPayload = {
        ...searchRequestBody,
        recordType: searchRecordType,
        ...(config.isUoMEnabled && { isMetric }),
      };

      try {
        const totalCountUrl = `${config.endpoints.wellService}api/records/count`;

        const response = callServiceAPI<GetWellCountDataResponse>(
          totalCountUrl,
          body,
          getIdentifierFileIdWithRetry,
          identifierSearchUploadedFile,
          !!fileId
        );

        setDataPromise(response);
      } catch (e) {
        console.error(e);
      }
    }, 100);
  };

  return {
    isLoading,
    data,
    error,
    getExpectedWellCount,
  };
};

export default useWellCountData;
