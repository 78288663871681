import { WellData } from "../../types/common/wells";

import config from "../../configs/appSettings";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useDataGridStore from "../../store/grid/dataGridStore";

import useSearchStore from "../search/useSearchStore";
import { useGridColumn } from "./useGridColumn";
import { useGridData } from "./useGridData";

export const useGridInfiniteLoader = () => {
  const pageLimit = config.searchPageLimit;
  const updateGridWellDataTrigger = useDataGridStore(
    (state) => state.updateGridWellDataTrigger
  );
  const sortPayload = useDataGridStore((state) => state.sortPayload);

  // TEMP: Temporarily using useSearchWellsStore
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const { searchGridData } = useGridData();
  const { getColumnsPayloadData } = useGridColumn();

  const getNextBatchWellData = (newOffset: number) => {
    const columns = getColumnsPayloadData();
    updateGridWellDataTrigger("pagination");
    searchGridData({
      ...searchCriteria,
      offset: newOffset,
      pageLimit: pageLimit,
      columns: columns,
      sort: sortPayload,
      optimizeColumns: true,
    });
  };

  const getNext500WellData = (
    currentRows: number,
    rowSize: number,
    totalFetchedRows: number,
    mapGridData: WellData[]
  ) => {
    const next500rows = currentRows + rowSize;
    let nextRowsCount = next500rows;
    if (next500rows > totalFetchedRows) {
      const remainingCount = totalFetchedRows - currentRows;
      nextRowsCount = remainingCount + currentRows;
    }

    const nextSetOfData = mapGridData.slice(currentRows, next500rows);

    return {
      nextRowsCount,
      nextSetOfData,
    };
  };

  return {
    getNextBatchWellData,
    getNext500WellData,
  };
};
