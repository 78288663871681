import { FC, useEffect, useState } from "react";

import { Box, Divider } from "@mui/material";

import classNames from "classnames";

import { GridPlotHeaderProps } from "../../../../types/grid";

import { RECORD_TYPES } from "../../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../../store/grid/dataGridStore";
import useMapSettingsStore from "../../../../store/map/settings/mapSettingsStore";
import useStore from "../../../../store/useStore";

import useUnitOfMeasure from "../../../../customHooks/common/useUnitOfMeasure";
import useSearchStore from "../../../../customHooks/search/useSearchStore";
import useWellCountData from "../../../../customHooks/useWellCountData";

import { getNumberWithComma } from "../../../../utils/formatters/numberFormatter";

import AnalysisChartToggle from "../../../panels/analysisChartsPanel/AnalysisChartToggle";
import GridToolBarControls from "./GridToolBarControls";
import ResultCount from "./ResultCount";
import SelectedForAnalysis from "./SelectedForAnalysis";

const GridPlotHeader: FC<GridPlotHeaderProps> = ({
  exportAsExcel,
  resetFilters,
}) => {
  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const updateToastMessage = useStore((state) => state.updateToastMessage);
  const gridTotalCount = useDataGridStore((state) => state.gridTotalCount);
  const updateGridTotalCount = useDataGridStore(
    (state) => state.updateGridTotalCount
  );
  const gridFilteredCount = useDataGridStore(
    (state) => state.gridFilteredCount
  );

  const batchWellGridDataLoading = useDataGridStore(
    (state) => state.batchWellGridDataLoading
  );
  const allWellGridDataLoading = useDataGridStore(
    (state) => state.allWellGridDataLoading
  );
  const gridFilteredLoading = useDataGridStore(
    (state) => state.gridFilteredLoading
  );
  const isFullScreenGrid = useDataGridStore((state) => state.isFullScreenGrid);

  const isProcessingStyles = useMapSettingsStore(
    (state) => state.isProcessingStyles
  );

  // Loading states for DVT
  const DVTProcessing = useStore((state) => state.DVTProcessing);

  const isLoadingDrawToSelect = useStore(
    (state) => state.isDrawToSelectLoading
  );

  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );

  const [shouldCallWellCount, setShouldCallWellCount] = useState(false);

  const {
    data: wellCountData,
    isLoading,
    getExpectedWellCount,
  } = useWellCountData({
    searchRecordType: RECORD_TYPES.WELL,
  });

  const { isMetricOnSearch } = useUnitOfMeasure();

  useEffect(() => {
    if (
      !allWellGridDataLoading &&
      !DVTProcessing &&
      !isLoadingDrawToSelect &&
      !isProcessingStyles &&
      gridTotalCount > 250000
    ) {
      updateToastMessage("Please zoom to see all wells in your search area ");
    }
  }, [
    allWellGridDataLoading,
    DVTProcessing,
    isLoadingDrawToSelect,
    isProcessingStyles,
    gridTotalCount,
  ]);

  // Update search result count when api response is ready
  useEffect(() => {
    if (isLoading || !wellCountData) return;
    updateGridTotalCount(wellCountData);
  }, [isLoading, wellCountData]);

  // Update flag for calling the well count api when loading saved search
  useEffect(() => {
    if (!fetchedSavedSearchTrigger) return;
    setShouldCallWellCount(true);
  }, [fetchedSavedSearchTrigger]);

  // Call search api without filters to get the search result count
  // when loading saved search with filters
  useEffect(() => {
    if (
      !shouldCallWellCount ||
      fetchedSavedSearchTrigger ||
      !searchCriteria.filters?.length
    )
      return;
    const { drawnPolygons, currentBounds, searchedUWIs, fileId, shapeId } =
      searchCriteria;
    getExpectedWellCount({
      drawnPolygons,
      bounds: currentBounds,
      fileId,
      shapeId,
      searchedUWIs,
      fetchedSavedSearchTrigger,
      isMetric: isMetricOnSearch,
    });
    setShouldCallWellCount(false);
  }, [
    shouldCallWellCount,
    fetchedSavedSearchTrigger,
    searchCriteria.drawnPolygons,
    searchCriteria.currentBounds,
    searchCriteria.searchedUWIs,
    searchCriteria.fileId,
    searchCriteria.shapeId,
  ]);

  return (
    <Box
      data-testid="grid-header-container"
      className={classNames("grid-header-section", {
        "full-screen-mode": isFullScreenGrid,
      })}
    >
      <div className="grid-analysis-button">
        <AnalysisChartToggle />
      </div>
      <div className="grid-count-container">
        <ResultCount
          title="SEARCH RESULT"
          value={
            !batchWellGridDataLoading
              ? getNumberWithComma(gridTotalCount)
              : "..."
          }
        />
        <Divider orientation="horizontal" />
        <ResultCount
          title="FILTERED"
          value={
            !batchWellGridDataLoading && !gridFilteredLoading
              ? getNumberWithComma(gridFilteredCount)
              : "..."
          }
        />
        <SelectedForAnalysis />
      </div>

      <GridToolBarControls
        componentType={"grid"}
        dropdown={{
          export: {
            showMenu: false,
            onClickExportExcel: exportAsExcel,
          },
          filter: {
            onClickResetFilter: resetFilters,
          },
        }}
      />
    </Box>
  );
};

export default GridPlotHeader;
