import { SearchCriteria } from "../../types/common/search";
import { RecordType } from "../../types/panels/searchPanel/search";

import {
  BOTTOM_PERMIT_SPOTS,
  BOTTOM_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../constants/constants";
import { SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";
import { ATTRIBUTES_TAB } from "../../constants/panels/searchPanel/common/accordion";
import {
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../constants/panels/searchPanel/search";

import useAppModeStore from "../../store/common/appModeStore";
import useExportCardStore from "../../store/exportCard/exportCardStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useMapDrawStore from "../../store/map/draw/mapDrawStore";
import useExtentInfoStore from "../../store/map/gis/extent/extentStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import { resetCarbonStorageSlice } from "../../store/map/settings/carbonStorageSlice";
import { resetMapOverlaySlice } from "../../store/map/settings/mapOverlaySlice";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import { resetStratModelsSlice } from "../../store/map/settings/stratigraphicModelsSlice";
import usePanelsStore from "../../store/panels/panelsStore";
import useSearchPanelStore from "../../store/search/panel/useSearchPanelStore";
import useSearchPermitsStore from "../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";
import useStore from "../../store/useStore";

import useRecordType from "../common/useRecordType";
import useSagaMap from "../map/useSagaMap";
import usePrevious from "../usePrevious";
import useDrawPolygon from "./useDrawPolygon";
import useSearchPanel from "./useSearchPanel";
import useSearchStore from "./useSearchStore";

const useSearchPanelActions = () => {
  const { resetMapViewBaseMap } = useSagaMap();
  const { removeAllPolygonsFromSource } = useDrawPolygon();

  const polygons = useMapDrawStore((state) => state.polygons);
  const resetAllPolygonStates = useMapDrawStore(
    (state) => state.resetAllPolygonStates
  );

  const resetAllMapSelections = useMapSelectionStore(
    (state) => state.resetAllMapSelections
  );

  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);

  const resetWellCard = useStore((state) => state.resetWellCard);
  const resetSelectedSavedSearch = useStore(
    (state) => state.resetSelectedSavedSearch
  );

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({ searchRecordType: RECORD_TYPES.WELL });
  const updateSearchCriteria = searchStore(
    (state) => state.updateSearchCriteria
  );

  const resetSearchCriteria = searchStore((state) => state.resetSearchCriteria);

  // TEMP: Temporarily using useSearchWellsStore to update tab
  const searchWellsStore = useSearchWellsStore();
  // const activeSearchPanelTab = usePanelsStore(
  //   (state) => state.activeSearchPanelTab
  // );

  const searchPermitsStore = useSearchPermitsStore();
  const resetWellList = usePanelsStore((state) => state.resetWellList);
  const resetPermitList = usePanelsStore((state) => state.resetPermitList);
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const updateExtentInfo = useExtentInfoStore(
    (state) => state.updateExtentInfo
  );

  const updateIsSearchCriteriaChange = useExportCardStore(
    (state) => state.updateIsSearchCriteriaChange
  );
  const resetExportStates = useExportCardStore(
    (state) => state.resetExportStates
  );

  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const drawLayerSource = useMapStore((state) => state.drawLayerSource);
  const resetMapStates = useMapStore((state) => state.resetMapStates);
  const setDVTUpdated = useMapStore((state) => state.setDVTUpdated);
  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );
  const resetLayerStylingStates = useMapSettingsStore(
    (state) => state.resetLayerStylingStates
  );

  const updateGridWellDataTrigger = useDataGridStore(
    (state) => state.updateGridWellDataTrigger
  );
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );

  const updateSortByAnalysis = useDataGridStore(
    (state) => state.updateSortByAnalysis
  );
  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);

  const resetSortFilterModels = useDataGridStore(
    (state) => state.resetSortFilterModels
  );
  const resetDefaultGridModels = useDataGridStore(
    (state) => state.resetDefaultGridModels
  );
  const resetGridStates = useDataGridStore((state) => state.resetGridStates);

  const UoMValueOnSelection = useAppModeStore(
    (state) => state.UoMValueOnSelection
  );
  const updateUoMValueOnSearch = useAppModeStore(
    (state) => state.updateUoMValueOnSearch
  );
  const updateUoMAlertState = useAppModeStore(
    (state) => state.updateUoMAlertState
  );

  const { searchedRecordTypes } = useRecordType();
  const prevRecordTypes = usePrevious(searchedRecordTypes);
  const {
    hasLoadedAttribSavedSearch,
    hasLoadedIdentifierSavedSearch,
    identifierSearchType,
  } = useSearchPanel();

  const resetPolygons = () => {
    if (polygons.length) {
      resetAllPolygonStates();
      removeAllPolygonsFromSource(polygons, drawLayerSource);
    }
  };

  const resetAttribsPolygons = () => {
    searchWellsStore.clearQBValues();
    searchWellsStore.updateCurrentBounds([]);
    resetPolygons();
  };

  const resetDefaultLayersByRecordType = (recordTypes: RecordType[]) => {
    if (
      recordTypes.includes(RECORD_TYPES.WELL) &&
      recordTypes.includes(RECORD_TYPES.PERMIT)
    ) {
      toggleLayer(PERMIT_SPOTS, true);
      toggleLayer(BOTTOM_PERMIT_SPOTS, false);
      toggleLayer(WELL_SPOTS, true);
      toggleLayer(BOTTOM_WELL_SPOTS, false);
      toggleLayer(WELL_PATH_AND_STICKS, true);
      return;
    }

    if (recordTypes.includes(RECORD_TYPES.PERMIT)) {
      toggleLayer(PERMIT_SPOTS, true);
      toggleLayer(BOTTOM_PERMIT_SPOTS, false);
      toggleLayer(WELL_SPOTS, false);
      toggleLayer(BOTTOM_WELL_SPOTS, false);
      toggleLayer(WELL_PATH_AND_STICKS, false);
      return;
    }

    toggleLayer(PERMIT_SPOTS, false);
    toggleLayer(BOTTOM_PERMIT_SPOTS, false);
    toggleLayer(WELL_SPOTS, true);
    toggleLayer(BOTTOM_WELL_SPOTS, false);
    toggleLayer(WELL_PATH_AND_STICKS, true);
  };

  const searchCallback = (
    searchCriteria: Partial<SearchCriteria>,
    hasNoLoadedSavedSearch: boolean
  ) => {
    // const newRecordType = getRecordTypeOnBound(
    //   searchCriteria.currentBounds as Bounds
    // );

    // TODO: Update to a better comparison
    if (
      JSON.stringify(prevRecordTypes) !== JSON.stringify(searchedRecordTypes)
    ) {
      resetLayerStylingStates();
      resetDefaultLayersByRecordType(searchedRecordTypes);
    }

    updateUoMValueOnSearch(UoMValueOnSelection);
    updateUoMAlertState(undefined);

    updateExtentInfo({
      isLoading: true,
      data: [],
      error: "",
    });

    // TODO: Check if this is correct
    if (
      searchedRecordTypes.includes(RECORD_TYPES.WELL) &&
      !searchedRecordTypes.includes(RECORD_TYPES.PERMIT)
    ) {
      updateActivePanel(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS);
    }
    // if (newRecordTypes && !isPermitRecord(newRecordType)) {
    //   updateActivePanel(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS);
    // }

    updateInitialGridSearchMade(true);
    const newSearchCriteria = {
      ...searchCriteria,
      offset: 0,
      filters: [],
    };

    resetSortFilterModels();
    toggleGrid(true);
    toggleGridHeader(true);
    toggleIsHighlightSelectedSpots(true);
    updateSearchCriteria(newSearchCriteria);
    searchWellsStore.updateIsSearchedRecordType(
      searchWellsStore.isSearchAccordionToggledOn
    );
    searchPermitsStore.updateIsSearchedRecordType(
      searchPermitsStore.isSearchAccordionToggledOn
    );
    resetAllMapSelections();
    updateIsSearchCriteriaChange(false);
    updateSortByAnalysis(false);
    updateGridWellDataTrigger("searchPanel");
    resetWellCard();
    resetWellList();
    resetPermitList();

    if (hasNoLoadedSavedSearch) return;
    resetSelectedSavedSearch();
  };

  // TODO: Update to include resetting of Permits
  // This is triggered by the save dialog
  const saveCallback = () => {
    let hasLoadedSavedSearch = false;
    if (searchWellsStore.searchTypeTab === ATTRIBUTES_TAB) {
      updateLastSearchedBy(SEARCH_TYPES.ATTRIBUTE_SEARCH);
      searchWellsStore.resetIdentifierStates();
      hasLoadedSavedSearch = hasLoadedIdentifierSavedSearch;
    } else {
      updateLastSearchedBy(identifierSearchType);
      resetAttribsPolygons();
      hasLoadedSavedSearch = hasLoadedAttribSavedSearch;
    }

    // if saving with a loaded saved search and has a different search type
    // Do partial reset
    if (!hasLoadedSavedSearch) return;

    setDVTUpdated(false);
    resetSelectedSavedSearch();
    resetAllMapSelections();
    resetSortFilterModels();
    resetSearchCriteria();
    resetGridStates();
    resetExportStates();
  };

  const resetCallback = (hasLoadedSavedSearch: boolean) => {
    if (hasLoadedSavedSearch) {
      resetSelectedSavedSearch();
    }
    updateGridWellDataTrigger("");
    updateOpenedQBEditorPanel(null);
    updateLastSearchedBy("");
    resetSearchCriteria();
    resetDefaultGridModels();
    resetMapViewBaseMap();
    resetMapOverlaySlice();
    resetLayerStylingStates();
    resetCarbonStorageSlice();
    resetStratModelsSlice();
    resetMapStates();
    resetAllMapSelections();
    resetExportStates();
    resetGridStates();
    resetWellCard();
    searchWellsStore.updateIsQBUpdated(false);
    resetWellList();
  };

  return {
    searchCallback,
    saveCallback,
    resetCallback,
    resetPolygons,
    resetAttribsPolygons,
    hasLoadedAttribSavedSearch,
    hasLoadedIdentifierSavedSearch,
    identifierSearchType,
  };
};

export default useSearchPanelActions;
