import { IdentifierFormError } from "../../../types/panels/searchPanel/identifier/formError";
import { RecordType } from "../../../types/panels/searchPanel/search";

import {
  FILE_EXTENSIONS,
  FILE_TYPES,
} from "../../../constants/common/fileMetadata";
import { INVALID_FILE_TYPE_ERROR_STATE } from "../../../constants/panels/searchPanel/identifier/validation";

import useExportCardStore from "../../../store/exportCard/exportCardStore";

import useSearchStore from "../useSearchStore";
import useIdentifierChangeHandler from "./useIdentifierChangeHandler";

interface UseIdentifierFileUploadHandlerProps {
  searchRecordType: RecordType;
  setError: React.Dispatch<React.SetStateAction<IdentifierFormError>>;
}

const useIdentifierFileUploadHandler = ({
  searchRecordType,
  setError,
}: UseIdentifierFileUploadHandlerProps) => {
  //For Export Package Manager
  const updateExportUwiList = useExportCardStore(
    (state) => state.updateExportUwiList
  );

  //TODO: Decoupling | Check if still needed
  // const updateIsQBUpdated = searchStore((state) => state.updateIsQBUpdated);
  const searchStore = useSearchStore({ searchRecordType });
  const updateIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIdentifierSearchUploadedFile
  );
  const isLoadingIdentifierFileUpload = searchStore(
    (state) => state.isLoadingIdentifierFileUpload
  );
  const updateIsLoadingIdentifierFileUpload = searchStore(
    (state) => state.updateIsLoadingIdentifierFileUpload
  );

  const resetIdentifierSearchPastedValueList = searchStore(
    (state) => state.resetIdentifierSearchPastedValueList
  );
  const resetIdentifierStates = searchStore(
    (state) => state.resetIdentifierStates
  );

  const { handleIdentifierFileOnChange } =
    useIdentifierChangeHandler(searchRecordType);

  const handleUpload = async (
    file: File,
    setError: React.Dispatch<React.SetStateAction<IdentifierFormError>>
  ) => {
    //reset form error state on change of text
    setError("");

    updateIdentifierSearchUploadedFile(null);
    resetIdentifierStates();
    updateIsLoadingIdentifierFileUpload(true);

    const spinnerDisplayedPromise: Promise<void> = new Promise((resolve) => {
      // Resolve the promise when the spinner is displayed
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    spinnerDisplayedPromise.then(() => {
      const reader = new FileReader();
      reader.onloadend = async (e: ProgressEvent<FileReader>) => {
        if (
          file.type === FILE_TYPES.TEXT ||
          file.name.endsWith(FILE_EXTENSIONS.CSV)
        ) {
          handleIdentifierFileOnChange(
            e.target?.result as string,
            file,
            setError
          );
        } else {
          setError(INVALID_FILE_TYPE_ERROR_STATE);
          updateIsLoadingIdentifierFileUpload(false);
        }
      };
      reader.readAsText(file);
    });

    // updateIsQBUpdated(true);
    updateIdentifierSearchUploadedFile(file);
  };

  const handleRemoveUpload = () => {
    if (isLoadingIdentifierFileUpload) return;
    setError("");
    updateExportUwiList([]);
    resetIdentifierSearchPastedValueList();
    resetIdentifierStates();
  };

  return {
    handleRemoveUpload,
    handleUpload,
  };
};

export default useIdentifierFileUploadHandler;
