import { WellCounts } from "../../types/common/wells";

import {
  ATTRIBUTE_INFO_BY_KEY,
  BOOLEAN,
  RECORD_TYPE,
} from "../../constants/attributes";

import { capitalizeFirstLetterString } from "../../utils/helper2";

import useRecordType from "./useRecordType";

const useAttributeOptionsPostProcess = () => {
  const { getRecordTypeLabel } = useRecordType();

  const formatOptions = (attribute: string, options: WellCounts[]) => {
    const dataType = ATTRIBUTE_INFO_BY_KEY[attribute].dataType;
    let formattedOptions: WellCounts[] = [];
    switch (dataType) {
      case BOOLEAN:
        formattedOptions = options.map((option) => ({
          ...option,
          attributeValue: capitalizeFirstLetterString(option.attributeValue),
        }));
        break;
      default:
        formattedOptions = options;
        break;
    }

    return formattedOptions;
  };

  const getOptionLabelByAttribute = (attribute: string, option: string) => {
    if (attribute === RECORD_TYPE.key) {
      return getRecordTypeLabel(option);
    }
  };

  return { formatOptions, getOptionLabelByAttribute };
};

export default useAttributeOptionsPostProcess;
