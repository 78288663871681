import {
  ChangeEvent,
  FC,
  FocusEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TextField, TextFieldVariants, Typography } from "@mui/material";

import { NumDateFieldProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import { INTEGER } from "../../../../../../constants/attributes";
import { BTW } from "../../../../../../constants/panels/searchPanel/queryBuilder/opTypes";

import useStore from "../../../../../../store/useStore";

import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import {
  integersOnlyKeyDown,
  removeDecimal,
} from "../../../../../../utils/common/fieldBehavior";

interface AdditionalTextFieldProps {
  variant: TextFieldVariants;
  type: HTMLInputTypeAttribute;
  onKeyDown: KeyboardEventHandler<HTMLInputElement> | undefined;
}

const QBNumberFields: FC<NumDateFieldProps> = ({
  attribute,
  attributeQBInfo,
  operationType,
  searchRecordType,
  modifyAttributeBounds,
}) => {
  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );

  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);

  const isInteger = useMemo(() => attribute.dataType === INTEGER, [attribute]);

  const getStoreValue = useCallback(
    (index: number) => {
      return attributeQBInfo.values.length > index
        ? attributeQBInfo.values[index].toString()
        : "";
    },
    [attributeQBInfo.values]
  );

  const [value, setValue] = useState<string>(getStoreValue(0));
  const [start, setStart] = useState<string>(getStoreValue(0));
  const [end, setEnd] = useState<string>(getStoreValue(1));

  useEffect(() => {
    if (fetchedSavedSearchTrigger) return;
    setValue(getStoreValue(0));
    setStart(getStoreValue(0));
    setEnd(getStoreValue(1));
    updateCurrentQBInfo(attribute.key, "hasError", false);
  }, [
    operationType,
    fetchedSavedSearchTrigger,
    attribute.key,
    getStoreValue,
    updateCurrentQBInfo,
  ]);

  const updateBoundsAndQBInfo = (values: number[]) => {
    updateCurrentQBInfo(attribute.key, "values", values);
    modifyAttributeBounds(values, operationType);
  };

  const isSameValue = (value: string, i: number) =>
    attributeQBInfo.values[i] &&
    parseFloat(value) === attributeQBInfo.values[i];

  const convertValueFormat = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newValue = e.target.value;
    if (isInteger) {
      newValue = removeDecimal(e.target.value);
    }
    return newValue;
  };

  // Updating state of value/start/end onChange
  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    changeFunc: (value: string) => void
  ) => {
    const newValue = convertValueFormat(e);
    changeFunc(newValue);

    if (attributeQBInfo.values.length > 0 && !newValue) {
      updateBoundsAndQBInfo([]);
    }
  };
  const handleStartEndChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    changeFunc: (value: string) => void,
    type: string
  ) => {
    const newValue = convertValueFormat(e);
    changeFunc(newValue);

    const startVal = parseFloat(type === "start" ? newValue : start);
    const endVal = parseFloat(type === "start" ? end : newValue);

    updateCurrentQBInfo(attribute.key, "hasError", startVal > endVal);

    if (attributeQBInfo.values.length > 0 && (!startVal || !endVal)) {
      updateBoundsAndQBInfo([]);
    }
  };

  // Updating the search attribute values onBlur
  const handleUpdateAttributeValue = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // check if same value to prevent updating of attribute values
    if (!value || isSameValue(value, 0)) return;

    updateBoundsAndQBInfo([parseFloat(e.target.value)]);
  };
  const handleUpdateAttributeStartEnd = () => {
    if (!start || !end) return;

    const numStart = parseFloat(start);
    const numEnd = parseFloat(end);
    if (
      attributeQBInfo.hasError ||
      (isSameValue(start, 0) && isSameValue(end, 1))
    )
      return;

    updateBoundsAndQBInfo([numStart, numEnd]);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isInteger) integersOnlyKeyDown(e);
  };

  const commonTextFieldProps: AdditionalTextFieldProps = {
    variant: "outlined",
    type: "number",
    onKeyDown: handleKeyDown,
  };

  // Adding of has value classname
  const hasValue = (state: string) => (state !== "" ? "has-value" : "");

  const renderField = () => {
    switch (operationType) {
      case "":
        return null;
      case BTW.key:
        return (
          <div className="row-fields">
            <div className="is-between-fields numeric">
              <TextField
                {...commonTextFieldProps}
                label="Start"
                value={start}
                onChange={(e) => handleStartEndChange(e, setStart, "start")}
                onBlur={handleUpdateAttributeStartEnd}
                className="custom-textfield"
                error={attributeQBInfo.hasError}
              />
              <Typography variant="caption">AND</Typography>
              <TextField
                {...commonTextFieldProps}
                label="End"
                value={end}
                onChange={(e) => handleStartEndChange(e, setEnd, "end")}
                onBlur={handleUpdateAttributeStartEnd}
                className="custom-textfield"
                error={attributeQBInfo.hasError}
              />
            </div>
            <div
              className={`helper-text ${
                attributeQBInfo.hasError ? "show" : ""
              }`}
            >
              <Typography>Start should be less than End</Typography>
            </div>
          </div>
        );
      default:
        return (
          <TextField
            {...commonTextFieldProps}
            label="Value"
            value={value}
            onChange={(e) => handleValueChange(e, setValue)}
            onBlur={handleUpdateAttributeValue}
            className="custom-textfield"
          />
        );
    }
  };

  return renderField();
};

export default QBNumberFields;
