import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { ExpandMore } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { DashboardChartState } from "../../../types/charts/dashboardChartType/dashboardChartType";
import { HorizontalBarChartProps } from "../../../types/charts/dashboardChartType/horizontalBarChart";

import { CHART_DATA_STATE } from "../../../constants/charts/charts";
import {
  HORIZONTAL_BAR_ATTRIBUTES,
  HORIZONTAL_BAR_CHART_DISPLAY_MAPPER,
} from "../../../constants/charts/dashboardCharts";

import useChartStore from "../../../store/chart/chartStore";
import useMapSelectionStore from "../../../store/map/selection/mapSelectionStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";
import useStore from "../../../store/useStore";

import { useChartData } from "../../../customHooks/charts/useChartData";
import useSearchCriteria from "../../../customHooks/search/useSearchCriteria";

import {
  defaultConfig,
  fontLayout,
  generalLayout,
  legendLayout,
  marginLayout,
  xAxisLayout,
  yAxisLayout,
} from "../../../utils/charts/layouts/HorizontalBarLayout";
import {
  getLargestSignificantNumber,
  transformToTick,
} from "../../../utils/helper";
import { truncateString } from "../../../utils/stringUtils";

import LoadingBackDrop from "../../common/LoadingBackDrop";

const HorizontalBarChart = ({
  chartData,
  defaultXAxisLabel,
}: HorizontalBarChartProps) => {
  const [currentTickData, setCurrentTickData] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openAttributeSelection = Boolean(anchorEl);
  const {
    formattedChartData,
    data,
    getChartData,
    isLoading,
    currentTickYData,
  } = useChartData();

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const { hasSearchCriteria } = useSearchCriteria();

  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );

  //Chart
  const updateDashboardChartAttribute = useChartStore(
    (state) => state.updateDashboardChartAttribute
  );

  //Search
  const isLoadingSavedSearchFileUpload = useSearchWellsStore(
    (state) => state.isLoadingSavedSearchFileUpload
  );
  const searchCriteria = useSearchWellsStore((state) => state.searchCriteria);

  const fetchChartData = async (state: DashboardChartState) => {
    await getChartData(
      state,
      chartData.chartType,
      chartData.chartDisplayedDataFields,
      chartData.groupBy
    );
  };

  useEffect(() => {
    if (!formattedChartData?.length) {
      setCurrentTickData([-Infinity, -Infinity, -Infinity]);
      return;
    }

    try {
      const sums: { [key: number]: number } = {};
      for (let i = 0; i < formattedChartData.length; i++) {
        for (
          let xi = 0;
          xi < (formattedChartData[i].x as number[]).length;
          xi++
        ) {
          if (!sums[xi]) {
            sums[xi] = (formattedChartData[i].x as number[])[xi];
          } else {
            sums[xi] += (formattedChartData[i].x as number[])[xi];
          }
        }
      }

      const largestNumber = Math.round(Math.max(...Object.values(sums)));
      const largestSignificantNumber =
        getLargestSignificantNumber(largestNumber);

      const newXData = [];
      const tickDivisor = 5;

      const initialTickVal = largestSignificantNumber / tickDivisor;
      let currentTickVal = initialTickVal;
      for (let i = 0; i < tickDivisor; i++) {
        newXData.push(currentTickVal);
        currentTickVal += initialTickVal;
      }

      setCurrentTickData(newXData);
    } catch (error) {
      console.debug(error);
    }
  }, [formattedChartData]);

  useEffect(() => {
    if (!chartData || fetchedSavedSearchTrigger) {
      return;
    }

    if (selectedGridDataKeys.length) {
      fetchChartData(CHART_DATA_STATE.POST_SEARCH);
    } else {
      if (hasSearchCriteria) {
        fetchChartData(CHART_DATA_STATE.INTER_SEARCH);
      } else {
        fetchChartData(CHART_DATA_STATE.PRE_SEARCH);
      }
    }
  }, [
    chartData,
    chartData.groupBy,
    JSON.stringify(selectedGridDataKeys),
    searchCriteria,
    hasSearchCriteria,
    fetchedSavedSearchTrigger,
  ]);

  const toggleAttributeList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAttributeListClose = () => {
    setAnchorEl(null);
  };

  const onAttributeSelection = (attribute: string) => {
    updateDashboardChartAttribute(
      chartData.chartId,
      chartData.chartType,
      attribute
    );
    handleAttributeListClose();
  };

  return (
    <div className="dashboard-chart-main horizontal-bar-chart">
      <>
        <div className="chart-header stacked-bar">
          <IconButton
            size="small"
            onClick={(e) => toggleAttributeList(e)}
            className="attr-list-button"
          >
            <ExpandMore fontSize="small" />
          </IconButton>
          <Typography fontSize="12px" className="chart-title">
            TOP 5{" "}
            {HORIZONTAL_BAR_CHART_DISPLAY_MAPPER[chartData.title].toUpperCase()}
          </Typography>
          <Menu
            anchorEl={anchorEl}
            open={openAttributeSelection}
            onClose={() => handleAttributeListClose()}
            anchorOrigin={{ vertical: 40, horizontal: "left" }}
          >
            {HORIZONTAL_BAR_ATTRIBUTES.filter(
              (attr) => attr.attribute !== chartData.groupBy[0]
            ).map((attr) => (
              <MenuItem
                key={attr.attribute}
                onClick={() => onAttributeSelection(attr.attribute)}
              >
                {attr.title}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className="chart-container">
          {!isLoading &&
          chartData !== undefined &&
          !isLoadingSavedSearchFileUpload ? (
            <Plot
              className="plot-chart"
              config={defaultConfig}
              useResizeHandler
              data={formattedChartData}
              layout={{
                ...generalLayout,
                legend: legendLayout,
                margin: marginLayout,
                font: fontLayout,
                xaxis: {
                  ...xAxisLayout(defaultXAxisLabel),
                  tickmode: "array", // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
                  tickvals: currentTickData,
                  ticktext: currentTickData?.map((tickData) =>
                    transformToTick(tickData)
                  ),

                  ...(data?.length === 0 && {
                    range: [0.1, 5],
                  }),
                },
                yaxis: {
                  ...yAxisLayout,
                  showticklabels: data?.length > 0,
                  tickmode: "array",
                  tickvals: currentTickYData,
                  ticktext: currentTickYData?.map((tickData) =>
                    truncateString(tickData, 10)
                  ),
                },
              }}
            />
          ) : (
            <LoadingBackDrop className={"chart-container loader"} isOpen />
          )}
        </div>
      </>
    </div>
  );
};

export default HorizontalBarChart;
