import { useCallback, useEffect, useRef, useState } from "react";

import { SearchRequestPayload } from "../../types/common/api";
import { setTimeoutType, useRefType } from "../../types/common/general";
import {
  BaseSearchCriteria,
  SearchWellDataPayload,
  SortBySelected,
} from "../../types/common/search";

import config from "../../configs/appSettings";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useStore from "../../store/useStore";

import { defaultColumnsModel } from "../../utils/datagrid";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";
import useSearchCriteria from "../search/useSearchCriteria";
import useSearchStore from "../search/useSearchStore";
import usePrevious from "../usePrevious";
import useFetchFirstBatch from "./useFetchFirstBatch";
import { useGridColumn } from "./useGridColumn";
import { useGridSelectedData } from "./useGridSelectedData";

const useAllWellData = () => {
  const [error, setError] = useState("");

  const { getRequiredColumns, getColumnsPayloadData } = useGridColumn();
  const { hasSearchCriteria } = useSearchCriteria();

  const map = useMapStore((state) => state.map);
  const updateDVTProcessing = useStore((state) => state.updateDVTProcessing);

  // TEMP: Temporarily using useSearchWellsStore
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );

  const isFromIdentifierSearchUploadedFile = searchStore(
    (state) => state.isFromIdentifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } = useIdentifierFileUpload(
    RECORD_TYPES.WELL
  );
  // END TEMP: temporarily set to well

  const updateAllWellGridDataLoading = useDataGridStore(
    (state) => state.updateAllWellGridDataLoading
  );
  const updateAllWellGridDataSuccess = useDataGridStore(
    (state) => state.updateAllWellGridDataSuccess
  );
  const updateAllWellGridDataError = useDataGridStore(
    (state) => state.updateAllWellGridDataError
  );
  const updateGridFilteredLoading = useDataGridStore(
    (state) => state.updateGridFilteredLoading
  );
  const updateGridTotalCount = useDataGridStore(
    (state) => state.updateGridTotalCount
  );
  const updateGridFilteredCount = useDataGridStore(
    (state) => state.updateGridFilteredCount
  );
  const updateBatchWellGridDataSuccess = useDataGridStore(
    (state) => state.updateBatchWellGridDataSuccess
  );
  const updateBatchWellGridDataLoading = useDataGridStore(
    (state) => state.updateBatchWellGridDataLoading
  );
  const sortPayload = useDataGridStore((state) => state.sortPayload);
  const sortByAnalysis = useDataGridStore((state) => state.sortByAnalysis);

  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );

  const selectedPermitIdsKeys = useMapSelectionStore(
    (state) => state.selectedPermitIdsKeys
  );

  const deselectedWellIdsKeys = useMapSelectionStore(
    (state) => state.deselectedWellIdsKeys
  );
  const deselectedPermitIdsKeys = useMapSelectionStore(
    (state) => state.deselectedPermitIdsKeys
  );

  const { fetchFirstBatch } = useFetchFirstBatch(
    getIdentifierFileIdWithRetry,
    identifierSearchUploadedFile,
    isFromIdentifierSearchUploadedFile
  );

  const { isDeselectedIds } = useGridSelectedData();
  const { buildSearchRequestByParam } = useSearchRequest();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const getAllWellData = useCallback(
    async ({
      drawnPolygons = [],
      currentBounds = [],
      searchedUWIs = [],
      fileId = "",
      shapeId = "",
      filters = [],
    }: SearchWellDataPayload) => {
      // Loading States
      updateDVTProcessing(true);
      setError("");
      updateAllWellGridDataLoading(true);
      updateBatchWellGridDataLoading(true);
      if (filters?.length) updateGridFilteredLoading(true);

      // Success States
      updateAllWellGridDataSuccess(false);
      updateBatchWellGridDataSuccess(false);

      const requestBodySearchCriteria: BaseSearchCriteria = {
        drawnPolygons,
        shapeId,
        currentBounds,
        fileId,
        searchedUWIs,
        filters,
      };

      const searchRequestBody = buildSearchRequestByParam(
        requestBodySearchCriteria
      );

      const body: SearchRequestPayload = {
        ...searchRequestBody,
        offset: 0,
        pageLimit: 1,
        // TEMP: Temporarily set to well
        recordType: RECORD_TYPES.WELL,
        ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
      };

      if (sortPayload?.length) {
        body.sort = sortPayload;
      }

      // Grid Columns
      const columns = getColumnsPayloadData();
      if (columns?.length) {
        const finalColumnsPayload = getRequiredColumns(columns);
        body.columns = finalColumnsPayload;

        //remove else condition if the ff are all included in default columns
        // Ultimate Owner
        // First Month
        // TotalVerticalDepth
        // InterpretedProducingFormation
        // CumBOE
      } else {
        const columnsWithWellHover = getRequiredColumns(defaultColumnsModel);
        body.columns = columnsWithWellHover;
      }

      if (sortByAnalysis) {
        const sortBySelectedRequest: SortBySelected = {
          prioritizeSelected: !isDeselectedIds,
          wellIds: isDeselectedIds
            ? deselectedWellIdsKeys
            : selectedWellIdsKeys,
          permitIds: isDeselectedIds
            ? deselectedPermitIdsKeys
            : selectedPermitIdsKeys,
        };

        body.sortBySelected = sortBySelectedRequest;
      }

      const totalCountUrl = `${config.endpoints.wellService}api/records/count`;

      try {
        // Need to get totalCount first for query
        const initialResponse: any = await callServiceAPI(
          totalCountUrl,
          body,
          getIdentifierFileIdWithRetry,
          identifierSearchUploadedFile,
          isFromIdentifierSearchUploadedFile
        );

        const totalCount = initialResponse.data.totalCount;
        const pageLimit = config.searchPageLimit;
        const totalIterations = Math.ceil(totalCount / pageLimit);

        if (filters?.length) {
          updateGridFilteredLoading(false);
        } else {
          updateGridTotalCount(totalCount);
        }
        updateGridFilteredCount(totalCount);

        // call first batch
        // will include dynamic columns every first call
        const getfetchFirstBatchProps = {
          body: {
            ...body,
            offset: 0,
            pageLimit,
          },
          pageLimit,
          totalIterations,
        };
        fetchFirstBatch(getfetchFirstBatchProps);
      } catch (error: any) {
        console.debug("getAllWellData error", error);
        setError(error);
        updateAllWellGridDataError(error);
        updateGridFilteredLoading(false);
        updateBatchWellGridDataLoading(false);
      }
    },
    [
      getIdentifierFileIdWithRetry,
      fetchFirstBatch,
      updateBatchWellGridDataSuccess,
      isFromIdentifierSearchUploadedFile,
      updateAllWellGridDataError,
      updateAllWellGridDataLoading,
      updateAllWellGridDataSuccess,
      updateBatchWellGridDataLoading,
      updateDVTProcessing,
      updateGridFilteredCount,
      updateGridFilteredLoading,
      updateGridTotalCount,
      identifierSearchUploadedFile,
    ]
  );

  const getAllWellDataTimeout: useRefType<setTimeoutType> = useRef();
  const debouncedGetAllWellData = useCallback(() => {
    clearTimeout(getAllWellDataTimeout.current);
    getAllWellDataTimeout.current = setTimeout(() => {
      getAllWellData(searchCriteria);
    }, 200);
  }, [searchCriteria, getAllWellData]);
  const prevSearchCriteria = usePrevious(searchCriteria);

  useEffect(() => {
    if (!map) return;
    if (hasSearchCriteria && searchCriteria !== prevSearchCriteria) {
      debouncedGetAllWellData();
    }
  }, [
    map,
    hasSearchCriteria,
    searchCriteria,
    prevSearchCriteria,
    debouncedGetAllWellData,
  ]);

  return {
    error,
    getAllWellData,
  };
};

export default useAllWellData;
