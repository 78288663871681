import { StateCreator } from "zustand";

import { SearchPanelStates } from "../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import { clone } from "../../../utils";

const sliceResetFns = new Set<() => void>();

const resetSearchPanelSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialSearchPanelStates = {
  openedSearchAccordion: RECORD_TYPES.WELL,
  openedQBEditorPanel: null,
};

const searchPanelSlice: StateCreator<SearchPanelStates> = (set) => {
  sliceResetFns.add(() => set(clone(initialSearchPanelStates)));
  return {
    ...initialSearchPanelStates,
    updateOpenedSearchAccordion: (openedSearchAccordion) =>
      set(() => ({ openedSearchAccordion })),
    updateOpenedQBEditorPanel: (openedQBEditorPanel) =>
      set(() => ({ openedQBEditorPanel })),
  };
};

export { resetSearchPanelSlice, searchPanelSlice };
