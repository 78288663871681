import { Box, CircularProgress, Typography } from "@mui/material";

const IdentifierUploadProgress = ({ progress }: { progress: number }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        className="circular-loader"
        size={20}
        variant="determinate"
        value={progress}
      />
      <Box className="upload-progress-container">
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          className="upload-progress"
        >{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default IdentifierUploadProgress;
