import { StateCreator } from "zustand";

import {
  AttributeWithState,
  AvailableQBAttributes,
  CurrentBoundsSlice,
  QBAttributeList,
  QBAttributeStates,
  QBEditorSlice,
  QBInfoSlice,
  QBUtilitySlice,
  QueryBuilderState,
} from "../../../types/panels/searchPanel/queryBuilder";

import { ATTRIBUTE_INFO_BY_KEY } from "../../../constants/attributes";
import {
  defaultAvailableQBAttributes,
  defaultQBInfo,
  defaultQBList,
} from "../../../constants/panels/searchPanel/queryBuilder/wellsStore";

import { parseBoundValueByDataType } from "../../../utils/common/boundsData";
import { getDefaultAttributeInfo } from "../../../utils/search/store";

import { clone, removePropertyFromObject } from "../../../utils";

const queryBuilderInfoSlice: StateCreator<QBInfoSlice> = (set, get) => ({
  currentQBInfo: clone(defaultQBInfo),
  updateCurrentQBInfo: (attributeKey, infoKey, value) =>
    set(() => ({
      currentQBInfo: {
        ...get().currentQBInfo,
        [attributeKey]: {
          ...get().currentQBInfo[attributeKey],
          [infoKey]: value,
        },
      },
    })),

  isQBUpdated: false,
  updateIsQBUpdated: (isQBUpdated: boolean) => set(() => ({ isQBUpdated })),

  resetCurrentQBInfo: (attributeKeyToStay, infoKey) =>
    set(() => {
      const currInfo = get().currentQBInfo;
      const newInfo: QBAttributeStates = {};
      Object.entries(currInfo).forEach(([key, value]) => {
        if (attributeKeyToStay === key) {
          newInfo[key] = value;
        } else {
          const defaultInfo = getDefaultAttributeInfo(
            ATTRIBUTE_INFO_BY_KEY[key]
          );
          newInfo[key] = { ...value, [infoKey]: defaultInfo[infoKey] };
        }
      });
      return { currentQBInfo: newInfo };
    }),
});

const queryBuilderEditorSlice: StateCreator<
  QueryBuilderState,
  [],
  [],
  QBEditorSlice
> = (set, get) => ({
  availableQBAttributes: clone(defaultAvailableQBAttributes),
  currentQBList: clone(defaultQBList),

  addToCurrentQBList: (attribute) =>
    set(() => {
      // Add the attribute to the current search attribute list
      const newQBList = [...get().currentQBList, attribute];

      // Update the selected state from the available search attributes
      const updatedAttributeGroupList = get().availableQBAttributes[
        attribute.group
      ].map((availableAttribute: AttributeWithState) => {
        if (attribute.key === availableAttribute.key) {
          return { ...availableAttribute, isSelected: true };
        }
        return availableAttribute;
      });
      const newAvailableQBAttributes = {
        ...get().availableQBAttributes,
        [attribute.group]: updatedAttributeGroupList,
      };

      // Add default attribute info
      const attributeInfo = getDefaultAttributeInfo(attribute);
      const newQBInfo = {
        ...get().currentQBInfo,
        [attribute.key]: attributeInfo,
      };

      return {
        availableQBAttributes: newAvailableQBAttributes,
        currentQBList: newQBList,
        currentQBInfo: newQBInfo,
      };
    }),

  removeToCurrentQBList: (attributeToRemoved) =>
    set(() => {
      // Remove the attribute to currently search attribute list
      const newQBList = get().currentQBList.filter(
        (attribute) => attribute.key !== attributeToRemoved.key
      );

      // Update the selected state from the available search attributes
      const updatedAttributeGroupList = get().availableQBAttributes[
        attributeToRemoved.group
      ].map((availableAttribute: AttributeWithState) => {
        if (attributeToRemoved.key === availableAttribute.key) {
          return { ...availableAttribute, isSelected: false };
        }
        return availableAttribute;
      });
      const newAvailableQBAttributes = {
        ...get().availableQBAttributes,
        [attributeToRemoved.group]: updatedAttributeGroupList,
      };

      // Remove attribute info
      const newQBInfo = removePropertyFromObject(
        get().currentQBInfo,
        attributeToRemoved.key
      );

      return {
        availableQBAttributes: newAvailableQBAttributes,
        currentQBList: newQBList,
        currentQBInfo: newQBInfo,
      };
    }),
});

const queryBuilderUtilitySlice: StateCreator<
  QueryBuilderState,
  [],
  [],
  QBUtilitySlice
> = (set, get) => ({
  // Load saved searched
  loadQBInfo: (bounds, sortList) =>
    set(() => {
      const newAvailableQBAttributes: AvailableQBAttributes = clone(
        defaultAvailableQBAttributes
      );
      const newQBList: QBAttributeList = clone(defaultQBList);
      const newQBInfo: QBAttributeStates = clone(defaultQBInfo);

      bounds.forEach((bound) => {
        const attribute = ATTRIBUTE_INFO_BY_KEY[bound.bound];

        // if attribute is not a default selected query builder attribute
        if (!(bound.bound in newQBInfo)) {
          // Update the selected state of the attribute
          const attributeGroup = newAvailableQBAttributes[attribute.group];
          for (let i = 0; i < attributeGroup.length; i++) {
            if (attributeGroup[i].key === bound.bound) {
              attributeGroup[i].isSelected = true;
              break;
            }
          }

          // Add attribute on the query builder list
          newQBList.push(attribute);

          // Add the default info of attribute to the query builder
          newQBInfo[bound.bound] = getDefaultAttributeInfo(attribute);
        }

        const { type, values } = bound.operations[0];
        const attributeValues = values.map((value) =>
          parseBoundValueByDataType(attribute, value)
        );

        newQBInfo[bound.bound].operationType = type;
        newQBInfo[bound.bound].values = attributeValues;
      });

      if (sortList && Object.keys(sortList).length) {
        Object.keys(sortList).forEach((key) => {
          if (newQBInfo[key]) {
            newQBInfo[key].sortKey = sortList[key];
          }
        });
      }

      return {
        availableQBAttributes: newAvailableQBAttributes,
        currentQBList: newQBList,
        currentQBInfo: newQBInfo,
      };
    }),

  // Clear values/states of current attributes
  clearQBValues: () =>
    set(() => {
      const newQBInfo: QBAttributeStates = {};
      get().currentQBList.forEach((attribute) => {
        newQBInfo[attribute.key] = getDefaultAttributeInfo(attribute);
      });

      return { currentQBInfo: newQBInfo };
    }),

  // Resets to default/initial query builder states
  resetQBStates: () =>
    set(() => ({
      availableQBAttributes: clone(defaultAvailableQBAttributes),
      currentQBList: clone(defaultQBList),
      currentQBInfo: clone(defaultQBInfo),
    })),
});

const currentBoundsSlice: StateCreator<CurrentBoundsSlice> = (set, get) => ({
  currentBounds: [],
  updateCurrentBounds: (currentBounds) =>
    set((state) => ({
      currentBounds,
    })),
  removeToCurrentBounds: (boundKey) =>
    set((state) => ({
      currentBounds: get().currentBounds.filter(
        (bound) => bound.bound !== boundKey
      ),
    })),
});

export {
  currentBoundsSlice,
  queryBuilderEditorSlice,
  queryBuilderInfoSlice,
  queryBuilderUtilitySlice,
};
