import { create } from "zustand";

import { SearchStates } from "../../../types/panels/searchPanel/search";

import { searchAccordionSlice } from "../common/searchAccordionSlice";
import { searchCriteriaSlice } from "../common/searchCriteriaSlice";
import {
  identifierCountSlice,
  identifierFileUploadRetrySlice,
  identifierFileUploadSlice,
  identifierPastedSlice,
  identifierSearchResetSlice,
  identifierTypeSlice,
  identifierValidationSlice,
} from "../common/searchIdentifierSlice";
import {
  currentBoundsSlice,
  queryBuilderEditorSlice,
  queryBuilderInfoSlice,
  queryBuilderUtilitySlice,
} from "../common/searchQueryBuilderSlice";
import permitsQueryBuilderSlice from "../permits/permitsQueryBuilderSlice";
import permitsAccordionSlice from "./permitsAccordionSlice";

const useSearchPermitsStore = create<SearchStates>()((...a) => ({
  ...searchAccordionSlice(...a),
  ...{
    ...permitsAccordionSlice(...a),
  },
  ...queryBuilderInfoSlice(...a),
  ...queryBuilderEditorSlice(...a),
  ...queryBuilderUtilitySlice(...a),
  ...{
    ...permitsQueryBuilderSlice.queryBuilderInfoSlice(...a),
    ...permitsQueryBuilderSlice.queryBuilderEditorSlice(...a),
    ...permitsQueryBuilderSlice.queryBuilderUtilitySlice(...a),
  },
  ...currentBoundsSlice(...a),
  ...identifierTypeSlice(...a),
  ...identifierCountSlice(...a),
  ...identifierValidationSlice(...a),
  ...identifierFileUploadSlice(...a),
  ...identifierFileUploadRetrySlice(...a),
  ...identifierPastedSlice(...a),
  ...identifierSearchResetSlice(...a),
  ...searchCriteriaSlice(...a),
}));

export default useSearchPermitsStore;
