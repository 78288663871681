import { Attribute } from "../../types/common/attributes";
import { OperationTypeKeys } from "../../types/panels/searchPanel/queryBuilder/opTypes";

import { BOOLEAN } from "../../constants/attributes";

import { stringifyBooleanValues } from "../helper";
import { getAttributeBounds } from "../search/formatter";

const parseBoundValueByDataType = (
  attribute: Attribute,
  value: string | number | boolean
) => {
  switch (attribute.dataType) {
    case BOOLEAN:
      return stringifyBooleanValues(value);
    default:
      return value;
  }
};

// Saving for reference when loading old save searches
// const parseBoundValuesByKey = (
//   attribute: Attribute,
//   values: string[] | number[]
// ) => {
//   switch (attribute.key) {
//     case RECORD_TYPE.key:
//       let recordTypeVals: RecordType[] = values as RecordType[];
//       if (
//         recordTypeVals.includes(RECORD_TYPES.WELL) &&
//         recordTypeVals.includes(RECORD_TYPES.PERMIT)
//       ) {
//         recordTypeVals = [RECORD_TYPES.WELLS_AND_PERMIT];
//       }
//       return recordTypeVals;

//     default:
//       return values;
//   }
// };

const createBoundsByKey = (
  attribute: Attribute,
  values: string[] | number[],
  operationType: OperationTypeKeys
) => {
  return getAttributeBounds(attribute, values, operationType);
};

// Saving for reference when loading old save searches
// const getRecordTypeOnBound = (bounds: Bounds) => {
//   const recordTypeBound = bounds.find(
//     (bound) => bound.bound === RECORD_TYPE.key
//   );

//   let recordType: RecordType | null = null;
//   if (!!recordTypeBound) {
//     const recordTypeBoundValues =
//       recordTypeBound.operations[0].values.join(", ");

//     if (recordTypeBoundValues === "Well, Permit") {
//       recordType = RECORD_TYPES.WELLS_AND_PERMIT;
//     } else {
//       recordType = recordTypeBoundValues as RecordType;
//     }
//   }
//   return recordType;
// };

export {
  createBoundsByKey,
  // createRecordTypeBounds,
  // getRecordTypeOnBound,
  parseBoundValueByDataType,
};
