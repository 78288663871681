import { useCallback } from "react";

import {
  ChartAxisDataFields,
  ChartColorByInfo,
  ChartModeView,
  IScatterPlotChartData,
} from "../../types/charts/chartType/chartType";
import { ChartAxis } from "../../types/charts/chartTypeSettings/chartTypeSettings";
import { RecordType } from "../../types/panels/searchPanel/search";

import {
  COMPLETION_DATE,
  CUM_BOE,
  PERMIT_APPROVAL_DATE,
  PERMIT_TOTAL_DEPTH,
} from "../../constants/attributes";
import { CHART_MODE_VIEW } from "../../constants/charts/chartModeView";
import { CHART_AXIS, CHART_SCALING_TYPE } from "../../constants/charts/charts";
import {
  SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_PERMIT_Y_AXIS_ATTRIBUTES,
} from "../../constants/charts/scatterPlotDataFields";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useChartStore from "../../store/chart/chartStore";

import { clone } from "../../utils/helper";

interface Props {
  chartId?: string;
  isChartExport?: boolean;
  chartMode?: ChartModeView;
}
interface DefaultChart {
  xAxis: ChartAxisDataFields;
  yAxis: ChartAxisDataFields[];
  colorBy: ChartColorByInfo;
  axis: ChartAxis[];
}
const useScatterPlotChartReset = ({
  chartId,
  chartMode,
  isChartExport,
}: Props) => {
  const chartData = useChartStore((state) => state.chartData);
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );

  const getScatterPlotDefault = (recordTypes: RecordType[]) => {
    // TODO: Double check if this is correct.
    const xAttribute = recordTypes.includes(RECORD_TYPES.WELL)
      ? COMPLETION_DATE.key
      : PERMIT_APPROVAL_DATE.key;
    const yAttribute = recordTypes.includes(RECORD_TYPES.WELL)
      ? CUM_BOE.key
      : PERMIT_TOTAL_DEPTH.key;
    // const xAttribute =
    //   recordType === RECORD_TYPES.WELL ||
    //   recordType === RECORD_TYPES.WELLS_AND_PERMIT
    //     ? COMPLETION_DATE.key
    //     : PERMIT_APPROVAL_DATE.key;
    // const yAttribute =
    //   recordType === RECORD_TYPES.WELL ||
    //   recordType === RECORD_TYPES.WELLS_AND_PERMIT
    //     ? CUM_BOE.key
    //     : PERMIT_TOTAL_DEPTH.key;

    const xAxisField =
      SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES.find(
        (dataField) => dataField.name === xAttribute
      ) ?? undefined;
    const yAxisField =
      SCATTER_PLOT_WELL_PERMIT_Y_AXIS_ATTRIBUTES.find(
        (dataField) => dataField.name === yAttribute
      ) ?? undefined;

    if (xAxisField && yAxisField) {
      const data: DefaultChart = {
        xAxis: xAxisField,
        yAxis: [{ ...yAxisField, axis: CHART_AXIS.LEFT }],
        colorBy: {
          toggle: false,
          attributesList: [],
          minMax: {
            min: null,
            max: null,
            hasNull: false,
          },
        },
        axis: [
          {
            name: CHART_AXIS.LEFT,
            scale: CHART_SCALING_TYPE.LINEAR,
            title: "",
          },
        ],
      };

      return data;
    }
  };

  const resetChartData = useCallback(
    (recordTypes: RecordType[], resetColorBy: boolean) => {
      //Don't reset if chart mode is on full screen since it is not possible to change record type in these modes
      if (
        chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS ||
        chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART ||
        isChartExport
      ) {
        return;
      }

      const copiedChartdata: IScatterPlotChartData = clone(
        chartData?.find((data) => data.chartId === chartId)
      );
      const newChartData = getScatterPlotDefault(recordTypes);

      if (copiedChartdata && newChartData && chartId) {
        copiedChartdata.chartXAxisDataFields = newChartData.xAxis;
        copiedChartdata.chartYAxisDataFields = newChartData.yAxis;
        copiedChartdata.chartColorBy = {
          ...newChartData.colorBy,
          toggle: resetColorBy
            ? false
            : copiedChartdata.chartColorBy?.toggle ?? false,
        };
        copiedChartdata.chartData = [];
        copiedChartdata.chartRawData = [];

        updateChartDataByKey(chartId, copiedChartdata);
      }
    },
    [chartData, chartId, chartMode, isChartExport, updateChartDataByKey]
  );

  return { resetChartData, getScatterPlotDefault };
};

export default useScatterPlotChartReset;
