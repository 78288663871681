import { MapControlsWellStyling } from "../../../types/map/mapSettings/controls";
import { RecordType } from "../../../types/panels/searchPanel/search";

import * as mapSettings from "../../../constants/map/mapSettings";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

export const getWellStylingControls = (types: RecordType[] | null) => {
  if (!types?.length) return null;
  return {
    [mapSettings.WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_WELL_PERMIT_CARD_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_WELL_PERMIT_CARD_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_WELL_PATHS_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_WELL_PATHS_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_PERMIT_SURFACE_HOLE_LOCATION_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_PERMIT_SURFACE_HOLE_LOCATION_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_PERMIT_BOTTOM_HOLE_LOCATION_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_PERMIT_BOTTOM_HOLE_LOCATION_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_APPLY_COLOR_TO_PERMITS_CONTROL.key]:
      wellStylingControlByRecordType(
        types,
        mapSettings.WELL_STYLING_APPLY_COLOR_TO_PERMITS_CONTROL.key
      ),
  };
};

export const wellStylingControlByRecordType = (
  types: RecordType[],
  setting: MapControlsWellStyling
) => {
  if (
    types.includes(RECORD_TYPES.WELL) &&
    types.includes(RECORD_TYPES.PERMIT)
  ) {
    return mapSettings.WELL_STYLING_WELLS_AND_PERMITS.map<MapControlsWellStyling>(
      (list) => list.key
    ).includes(setting);
  }
  if (types.includes(RECORD_TYPES.WELL)) {
    return mapSettings.WELL_STYLING_WELLS_ONLY.map<MapControlsWellStyling>(
      (list) => list.key
    ).includes(setting);
  }
  if (types.includes(RECORD_TYPES.PERMIT)) {
    return mapSettings.WELL_STYLING_PERMITS_ONLY.map<MapControlsWellStyling>(
      (list) => list.key
    ).includes(setting);
  }
};
