import React, { FC, useEffect, useMemo, useState } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { IdentifierFormError } from "../../../../types/panels/searchPanel/identifier/formError";
import { RecordType } from "../../../../types/panels/searchPanel/search";

import {
  IDENTIFIER_SEARCH_MODE,
  IDENTIFIER_TYPE_INFO,
  IDENTIFIER_TYPE_KEY_LIST,
} from "../../../../constants/panels/searchPanel/identifier/types";
import {
  INVALID_FILE_TYPE_ERROR_STATE,
  INVALID_FORMAT_ERROR_STATE,
} from "../../../../constants/panels/searchPanel/identifier/validation";

import useIdentifierChangeHandler from "../../../../customHooks/search/identifier/useIdentifierChangeHandler";
import useSearchStore from "../../../../customHooks/search/useSearchStore";

import LoadingBackDrop from "../../../common/LoadingBackDrop";
import IdentifierUploadFile from "./IdentifierUploadFile";

interface SearchIdentifierContentProps {
  searchRecordType: RecordType;
}

const SearchIdentifierContent: FC<SearchIdentifierContentProps> = ({
  searchRecordType,
}) => {
  //TODO: use these error flags to update isIdentifierFormInvalid State in store
  const [fileUploadFormError, setFileUploadFormError] =
    useState<IdentifierFormError>("");
  const [pastedTextError, setPastedTextError] =
    useState<IdentifierFormError>("");

  const searchStore = useSearchStore({ searchRecordType });
  const selectedIdentifier = searchStore((state) => state.selectedIdentifier);
  const searchIdentifierCount = searchStore(
    (state) => state.searchIdentifierCount
  );
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const identifierSearchPastedValueText = searchStore(
    (state) => state.identifierSearchPastedValueText
  );
  const isIdentifierFormInvalid = searchStore(
    (state) => state.isIdentifierFormInvalid
  );
  const isLoadingIdentifierFileUpload = searchStore(
    (state) => state.isLoadingIdentifierFileUpload
  );
  const updateIsIdentifierFormInvalid = searchStore(
    (state) => state.updateIsIdentifierFormInvalid
  );
  const { handleIdentifierTextOnChange } =
    useIdentifierChangeHandler(searchRecordType);

  useEffect(() => {
    updateIsIdentifierFormInvalid(
      Boolean(fileUploadFormError) || Boolean(pastedTextError)
    );
  }, [fileUploadFormError, pastedTextError]);

  const handleResetUploadErrorOnTextFieldClicked = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (isIdentifierFormInvalid && fileUploadFormError) {
      setFileUploadFormError("");
      setPastedTextError("");
    }
  };

  const selectedIdentifierInfo = useMemo(
    () => IDENTIFIER_TYPE_INFO[selectedIdentifier],
    [selectedIdentifier]
  );
  const fieldTextErrorMessage = useMemo(() => {
    if (
      isIdentifierFormInvalid &&
      pastedTextError === INVALID_FORMAT_ERROR_STATE &&
      Boolean(identifierSearchPastedValueText)
    ) {
      return selectedIdentifierInfo.messageError.format;
    }

    return "";
  }, [
    isIdentifierFormInvalid,
    pastedTextError,
    selectedIdentifierInfo,
    identifierSearchPastedValueText,
  ]);

  const fileUploadErrorMessage = useMemo(() => {
    let errorMessage = "";
    if (
      isIdentifierFormInvalid &&
      Boolean(identifierSearchUploadedFile) &&
      fileUploadFormError
    )
      switch (fileUploadFormError) {
        case INVALID_FILE_TYPE_ERROR_STATE:
          errorMessage = selectedIdentifierInfo.messageError.fileType;
          break;
        case INVALID_FORMAT_ERROR_STATE:
          errorMessage = selectedIdentifierInfo.messageError.format;
          break;

        default:
          errorMessage = "";
          break;
      }

    return errorMessage;
  }, [
    isIdentifierFormInvalid,
    fileUploadFormError,
    identifierSearchUploadedFile,
    selectedIdentifierInfo,
  ]);

  const showLoader = useMemo(
    () =>
      Boolean(identifierSearchPastedValueText) &&
      (searchIdentifierCount.isLoading || isLoadingIdentifierFileUpload),
    [
      identifierSearchPastedValueText,
      searchIdentifierCount.isLoading,
      isLoadingIdentifierFileUpload,
    ]
  );

  const disableFieldText = useMemo(
    () =>
      showLoader ||
      (Boolean(identifierSearchUploadedFile) &&
        !Boolean(identifierSearchPastedValueText)),
    [showLoader, identifierSearchUploadedFile, identifierSearchPastedValueText]
  );

  return (
    <div className="search-uwi-container">
      <FormControl fullWidth className="filter-by-identifier">
        <InputLabel id="filter-by-identifier">Filter by Identifier</InputLabel>
        <Select
          labelId="filter-by-identifier"
          disabled
          label="Filter by Identifier"
          value={selectedIdentifier}
        >
          {IDENTIFIER_TYPE_KEY_LIST.map((type, index) => (
            <MenuItem value={type} key={`${type}_${index}`}>
              {IDENTIFIER_TYPE_INFO[type].label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LoadingBackDrop
        isOpen={showLoader}
        className="identifier-textbox loader"
      />
      <TextField
        multiline
        minRows={8}
        disabled={disableFieldText}
        value={identifierSearchPastedValueText}
        label={selectedIdentifierInfo.label}
        placeholder={selectedIdentifierInfo.placeholder}
        onChange={(e) =>
          handleIdentifierTextOnChange(e.target.value, setPastedTextError)
        }
        error={
          isIdentifierFormInvalid &&
          Boolean(pastedTextError && Boolean(identifierSearchPastedValueText))
        }
        helperText={fieldTextErrorMessage}
        onClick={handleResetUploadErrorOnTextFieldClicked}
      />
      <IdentifierUploadFile
        searchRecordType={searchRecordType}
        setError={setFileUploadFormError}
      />
      {isIdentifierFormInvalid && Boolean(fileUploadFormError) && (
        <FormHelperText error>{fileUploadErrorMessage}</FormHelperText>
      )}
    </div>
  );
};

export default SearchIdentifierContent;
