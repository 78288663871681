import { useCallback, useMemo } from "react";

import {
  SavedAttributeSearch,
  SavedGeometryData,
  SearchState,
} from "../../../types/panels/savedSearchPanel/savedSearchData";
import { SearchTypes } from "../../../types/panels/searchPanel/search";

import {
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../../constants/panels/searchPanel/search";
import { FILE_UPLOAD_FOLDER } from "../../../constants/uwiSearchUpload";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useStore from "../../../store/useStore";

import { getSortPerAttribute } from "../../../utils/savedSearch/savedData";

import useSearchStore from "../../search/useSearchStore";

const useSearchData = () => {
  const authUser = useStore((state) => state.authUser);

  const polygons = useMapDrawStore((state) => state.polygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  // TEMP: temporarily set to well
  // TODO: Update this to include Permits
  const searchStore = useSearchStore({ searchRecordType: RECORD_TYPES.WELL });
  const currentBounds = searchStore((state) => state.currentBounds);
  const currentQBInfo = searchStore((state) => state.currentQBInfo);
  const identifierSearchPastedValueText = searchStore(
    (state) => state.identifierSearchPastedValueText
  );
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const updateIsFromIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIsFromIdentifierSearchUploadedFile
  );
  const updateIdentifierFileTimeStamp = searchStore(
    (state) => state.updateIdentifierFileTimeStamp
  );
  // END TEMP: temporarily set to well

  const sortPerAttribute = useMemo(
    () => getSortPerAttribute(currentQBInfo),
    [currentQBInfo]
  );

  const getGeometryData = useCallback(() => {
    let geometry: SavedAttributeSearch["geometry"] = {
      coordinates: [],
    };
    if (polygons?.length) {
      const geometryCoordinates: SavedGeometryData[] = [];
      for (const polygonData of polygons) {
        const { id, isSelected, isShapeFile, coordinates } = polygonData;
        const polygon: SavedGeometryData = {
          id,
          isSelected,
          isShapeFile,
          coordinates: [],
        };
        coordinates[0].forEach((element: number[], index: number) => {
          const coordinate = {
            i: index,
            coordinateX: element[0],
            coordinateY: element[1],
          };
          polygon.coordinates.push(coordinate);
        });
        geometryCoordinates.push(polygon);
      }
      geometry = { coordinates: geometryCoordinates };
    }
    return geometry;
  }, [polygons]);

  const searchData = useCallback(
    (searchType: SearchTypes) => {
      let searchState: SearchState = {};
      if (searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
        updateIsFromIdentifierSearchUploadedFile(false);
        searchState = {
          attributeSearch: {
            bounds: currentBounds,
            geometry: getGeometryData(),
            shapeId,
          },
          ...(Object.keys(sortPerAttribute).length ? { sortPerAttribute } : {}),
        };
      } else {
        if (
          searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH ||
          searchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH
        ) {
          const uwiList = identifierSearchPastedValueText.split("\n");
          searchState = {
            ...searchState,
            uwiSearch: { searchUWIs: uwiList },
          };
        }
        if (
          searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH ||
          searchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH
        ) {
          searchState = {
            ...searchState,
            fileUpload: {
              fileName: identifierSearchUploadedFile?.name ?? "",
              folderName: `${FILE_UPLOAD_FOLDER}/${authUser.uid}`,
            },
          };
        }
        updateIdentifierFileTimeStamp(null);
      }
      return {
        searchState,
        searchTypeToBeSaved: searchType,
      };
    },
    [
      authUser,
      currentBounds,
      shapeId,
      updateIsFromIdentifierSearchUploadedFile,
      identifierSearchUploadedFile,
      identifierSearchPastedValueText,
      sortPerAttribute,
      getGeometryData,
      updateIdentifierFileTimeStamp,
    ]
  );

  return { searchData };
};

export default useSearchData;
