import React, { useRef } from "react";

import classNames from "classnames";
import { Button } from "component-library";

import { ButtonUploadProps } from "../../types/common/common";

const ButtonUpload: React.FC<ButtonUploadProps> = ({
  label,
  disabled,
  icon,
  customIcon,
  allowedFileExtension,
  handleFileChange,
  buttonClickCallback = null,
  className = "",
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (buttonClickCallback) buttonClickCallback();
    fileInputRef.current?.click();
  };

  const iconProp = () => {
    return icon
      ? { iconLeft: icon, text: label }
      : {
          text: (
            <>
              {customIcon}
              <span>{label}</span>
            </>
          ),
        };
  };

  return (
    <>
      <input
        id="upload-file"
        type="file"
        accept={allowedFileExtension}
        ref={fileInputRef}
        onChange={handleFileChange}
        onClick={(e) => ((e.target as HTMLInputElement).value = "")}
        hidden
      />
      <Button
        type="tertiary"
        state={disabled ? "disabled" : "enabled"}
        onClick={handleButtonClick}
        className={classNames("upload-btn", {
          [className]: !!className,
        })}
        {...iconProp()}
      />
    </>
  );
};

export default ButtonUpload;
