import { useMemo } from "react";

import { SearchCriteria } from "../../../types/common/search";
import { RecordType } from "../../../types/panels/searchPanel/search";

import { ATTRIBUTES_TAB } from "../../../constants/panels/searchPanel/common/accordion";
import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";

import useStore from "../../../store/useStore";

import useSearchPanelActions from "../useSearchPanelActions";
import useSearchStore from "../useSearchStore";

interface UseIdentifierActionButtonsProps {
  searchRecordType: RecordType;
}

const useIdentifierActionButtons = ({
  searchRecordType,
}: UseIdentifierActionButtonsProps) => {
  const lastSearchedBy = useStore((state) => state.lastSearchedBy);
  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);
  const updateSavedSearchFileName = useStore(
    (state) => state.updateSavedSearchFileName
  );

  const searchStore = useSearchStore({ searchRecordType });
  const updateSearchTypeTab = searchStore((state) => state.updateSearchTypeTab);

  const isLoadingIdentifierFileUpload = searchStore(
    (state) => state.isLoadingIdentifierFileUpload
  );
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const identifierSearchUploadedFileId = searchStore(
    (state) => state.identifierSearchUploadedFileId
  );
  const identifierSearchPastedValueText = searchStore(
    (state) => state.identifierSearchPastedValueText
  );
  const identifierSearchPastedValueList = searchStore(
    (state) => state.identifierSearchPastedValueList
  );
  const updateIsFromIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIsFromIdentifierSearchUploadedFile
  );
  const isIdentifierFormInvalid = searchStore(
    (state) => state.isIdentifierFormInvalid
  );
  const isLoadingIdentifierSearchCount = searchStore(
    (state) => state.isLoadingIdentifierSearchCount
  );

  const resetIdentifierStates = searchStore(
    (state) => state.resetIdentifierStates
  );
  const {
    searchCallback,
    resetCallback,
    resetAttribsPolygons,
    hasLoadedIdentifierSavedSearch,
    hasLoadedAttribSavedSearch,
    identifierSearchType,
  } = useSearchPanelActions();

  const isSearchDisabled = useMemo(
    () =>
      isIdentifierFormInvalid ||
      isLoadingIdentifierSearchCount ||
      (!Boolean(identifierSearchUploadedFile) &&
        !Boolean(identifierSearchPastedValueText)),
    [
      isIdentifierFormInvalid,
      isLoadingIdentifierSearchCount,
      identifierSearchUploadedFile,
      identifierSearchPastedValueText,
    ]
  );

  const isResetDisabled = useMemo(
    () =>
      !Boolean(identifierSearchUploadedFile) &&
      !Boolean(identifierSearchPastedValueText),
    [identifierSearchUploadedFile, identifierSearchPastedValueText]
  );

  const handleReset = () => {
    updateSavedSearchFileName("");
    resetIdentifierStates();
    resetCallback(hasLoadedIdentifierSavedSearch);
  };

  const handleSearch = () => {
    const newSearchCriteria: Partial<SearchCriteria> = {
      drawnPolygons: [],
      polygonType: "",
      currentBounds: [],
      searchedUWIs: identifierSearchPastedValueList,
      shapeId: "",
      fileId: identifierSearchUploadedFileId ?? "",
    };

    searchCallback(newSearchCriteria, !hasLoadedAttribSavedSearch);

    if (identifierSearchUploadedFileId)
      updateIsFromIdentifierSearchUploadedFile(true);

    updateLastSearchedBy(identifierSearchType);
    resetAttribsPolygons();
  };

  return {
    isResetDisabled: isResetDisabled,
    isSaveDisabled: isSearchDisabled,
    isSearchDisabled: isSearchDisabled,
    isShowPrompt: lastSearchedBy === SEARCH_TYPES.ATTRIBUTE_SEARCH,
    hasLoadedSavedSearch: hasLoadedIdentifierSavedSearch,
    handleResetClick: handleReset,
    onClickSaveCallback: resetAttribsPolygons,
    handleSearchClick: handleSearch,
    handleCancelClick: () => updateSearchTypeTab(ATTRIBUTES_TAB),
  };
};

export default useIdentifierActionButtons;
