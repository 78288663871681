import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

// move to common components
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    textAlign: "center",
    height: "30px",
    padding: "8px 20px",
    backgroundColor: "#575757",
    color: "white",
    borderRadius: "4px",
    fontSize: "13px",
    fontWeight: 500,
    wordWrap: "normal",
    maxWidth: "700px",
  },
}));
