import { StateCreator } from "zustand";

import {
  AvailableQBAttributes,
  QBAttributeList,
  QBAttributeStates,
  QBEditorSlice,
  QBInfoSlice,
  QBUtilitySlice,
  QueryBuilderState,
} from "../../../types/panels/searchPanel/queryBuilder";

import { ATTRIBUTE_INFO_BY_KEY } from "../../../constants/attributes";
import {
  defaultAvailableQBAttributes,
  defaultQBInfo,
  defaultQBList,
} from "../../../constants/panels/searchPanel/queryBuilder/permitsStore";

import { parseBoundValueByDataType } from "../../../utils/common/boundsData";
import { getDefaultAttributeInfo } from "../../../utils/search/store";

import { clone } from "../../../utils";

const queryBuilderInfoSlice: StateCreator<Partial<QBInfoSlice>> = () => ({
  currentQBInfo: clone(defaultQBInfo),
});

const queryBuilderEditorSlice: StateCreator<
  QueryBuilderState,
  [],
  [],
  Partial<QBEditorSlice>
> = () => ({
  availableQBAttributes: clone(defaultAvailableQBAttributes),
  currentQBList: clone(defaultQBList),
});

const queryBuilderUtilitySlice: StateCreator<
  QueryBuilderState,
  [],
  [],
  Partial<QBUtilitySlice>
> = (set) => ({
  // Load saved searched
  loadQBInfo: (bounds, sortList) =>
    set(() => {
      const newAvailableQBAttributes: AvailableQBAttributes = clone(
        defaultAvailableQBAttributes
      );
      const newQBList: QBAttributeList = clone(defaultQBList);
      const newQBInfo: QBAttributeStates = clone(defaultQBInfo);

      bounds.forEach((bound) => {
        const attribute = ATTRIBUTE_INFO_BY_KEY[bound.bound];

        // if attribute is not a default selected query builder attribute
        if (!(bound.bound in newQBInfo)) {
          // Update the selected state of the attribute
          const attributeGroup = newAvailableQBAttributes[attribute.group];
          for (let i = 0; i < attributeGroup.length; i++) {
            if (attributeGroup[i].key === bound.bound) {
              attributeGroup[i].isSelected = true;
              break;
            }
          }

          // Add attribute on the query builder list
          newQBList.push(attribute);

          // Add the default info of attribute to the query builder
          newQBInfo[bound.bound] = getDefaultAttributeInfo(attribute);
        }

        const { type, values } = bound.operations[0];
        const attributeValues = values.map((value) =>
          parseBoundValueByDataType(attribute, value)
        );

        newQBInfo[bound.bound].operationType = type;
        newQBInfo[bound.bound].values = attributeValues;
      });

      if (sortList && Object.keys(sortList).length) {
        Object.keys(sortList).forEach((key) => {
          if (newQBInfo[key]) {
            newQBInfo[key].sortKey = sortList[key];
          }
        });
      }

      return {
        availableQBAttributes: newAvailableQBAttributes,
        currentQBList: newQBList,
        currentQBInfo: newQBInfo,
      };
    }),

  // Resets to default/initial query builder states
  resetQBStates: () =>
    set(() => ({
      availableQBAttributes: clone(defaultAvailableQBAttributes),
      currentQBList: clone(defaultQBList),
      currentQBInfo: clone(defaultQBInfo),
    })),
});

export default {
  queryBuilderEditorSlice,
  queryBuilderInfoSlice,
  queryBuilderUtilitySlice,
};
