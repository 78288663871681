import { IDENTIFIER_TYPE_KEY } from "../../constants/panels/searchPanel/identifier/types";
import { REGEXP_VALID_UWI_INPUT } from "../../constants/regexp";

export const hasInvalidIdentifiers = (
  text: string,
  identifierTotalCount: number,
  identifierType = IDENTIFIER_TYPE_KEY.UWI
) => {
  //TODO: Once different Identifier types are introduced, utilize identifierType to determine the regex to use
  const regExp = REGEXP_VALID_UWI_INPUT;
  const validIdentifiers = text.match(regExp) ?? [];
  return validIdentifiers.length !== identifierTotalCount;
};
