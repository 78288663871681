import { PickingInfo } from "deck.gl";

import { PopoverVirtualElement } from "@mui/material";

import { WellData } from "../../types/common/wells";
import { Feature } from "../../types/map/layers/carto";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";
import {
  PERMIT_PANEL_RIGHT_CLICK_ACTION,
  WELL_PANEL_RIGHT_CLICK_ACTION,
} from "../../constants/panels/wellPanel/wellPanel";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";

import { displayHoverCardViaMenu } from "../../utils/map/mapHover";
import { mapWellSelection } from "../../utils/map/selection/wellSelection";

const useMapDistinctionMenuEvents = () => {
  const updateMapHoverData = useMapHoverStore(
    (state) => state.updateMapHoverData
  );

  const addSelectedMapParentWellID = useMapSelectionStore(
    (state) => state.addSelectedMapParentWellID
  );
  const addSelectedBottomWellboreID = useMapSelectionStore(
    (state) => state.addSelectedBottomWellboreID
  );
  const addMapSelectedPermitIDs = useMapSelectionStore(
    (state) => state.addMapSelectedPermitIDs
  );
  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const updateWellSelectTrigger = useDataGridStore(
    (state) => state.updateWellSelectTrigger
  );

  const handleOnMouseEnter = (
    anchorEl: PopoverVirtualElement | null,
    popperRef: React.RefObject<HTMLDivElement>,
    info: PickingInfo<Feature>,
    data: WellData | null,
    idx: number
  ) => {
    if (!anchorEl) return;
    const offset = popperRef.current?.offsetWidth ?? 0;
    displayHoverCardViaMenu(
      info,
      data,
      updateMapHoverData,
      anchorEl,
      idx,
      offset
    );
  };

  const handleOnClick = (info: PickingInfo<Feature>) => {
    mapWellSelection({
      info,
      addSelectedMapParentWellID,
      addSelectedBottomWellboreID,
      addMapSelectedPermitIDs,
      toggleLayer,
      updateWellSelectTrigger,
    });
  };

  const handleOnContextMenu = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    data: WellData | null,
    info: PickingInfo<Feature>
  ) => {
    e.preventDefault();
    if (data && info.layer && info.object) {
      updateMapHoverData({
        info: data,
        layer: info.layer,
        trigger:
          data.RecordType === RECORD_TYPES.WELL
            ? WELL_PANEL_RIGHT_CLICK_ACTION
            : PERMIT_PANEL_RIGHT_CLICK_ACTION,
      });
    }

    return false;
  };

  return {
    handleOnMouseEnter,
    handleOnClick,
    handleOnContextMenu,
  };
};

export default useMapDistinctionMenuEvents;
