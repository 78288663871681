import { StateCreator } from "zustand";

import { SearchCriteriaStore } from "../../types/grid";

//we can move this into a separate folder or under search folder
const searchCriteriaSlice: StateCreator<SearchCriteriaStore> = (set, get) => ({
  initialGridSearchMade: false,
  updateInitialGridSearchMade: (initialGridSearchMade) =>
    set((state) => ({
      initialGridSearchMade,
    })),
});

export { searchCriteriaSlice };
