import { useEffect, useState } from "react";

import Feature from "ol/Feature";
import { noModifierKeys, primaryAction } from "ol/events/condition.js";
import { Geometry } from "ol/geom";
import Polygon, { fromCircle, fromExtent } from "ol/geom/Polygon";
import Draw, { createRegularPolygon } from "ol/interaction/Draw.js";
import VectorSource from "ol/source/Vector";

import { PolygonData } from "../../types/map/gis/common";

import {
  DRAW_MODE_CIRCLE,
  DRAW_MODE_NONE,
  DRAW_MODE_POLYGON,
  DRAW_MODE_RECTANGLE,
  DRAW_POLYGON_MODE,
  DRAW_SELECT_DEFAULT,
  DRAW_TO_SELECT_MODE,
} from "../../constants/map/mapSettings";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useAlertPopupStore from "../../store/map/alert/alertStore";
import useMapDrawStore from "../../store/map/draw/mapDrawStore";
import useMapStore from "../../store/map/mapStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useStore from "../../store/useStore";

import { generateId } from "../../utils/common/generateId";
import { drawToSelectDataCallback } from "../../utils/map/selection/wellSelection";

import useSelectionData from "../map/selection/useSelectionData";
import useCartoClickProps from "../map/useCartoClickProps";
import useSagaMap from "../map/useSagaMap";
import useSearchStore from "./useSearchStore";

const useDrawPolygon = () => {
  const drawLayerSource = useMapStore((state) => state.drawLayerSource);

  // Search Panel
  // TEMP: Temporarily using useSearchWellsStore
  const searchStore = useSearchStore({ searchRecordType: RECORD_TYPES.WELL });
  const searchCriteria = searchStore((state) => state.searchCriteria);

  const updateDrawToSelectMode = useStore(
    (state) => state.updateDrawToSelectMode
  );

  const addDrawnPolygon = useMapDrawStore((state) => state.addDrawnPolygon);
  const removeDrawnPolygon = useMapDrawStore(
    (state) => state.removeDrawnPolygon
  );
  const updateDrawPolygonMode = useMapDrawStore(
    (state) => state.updateDrawPolygonMode
  );

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const toggleIsOpenAppLevelNav = usePanelsStore(
    (state) => state.toggleIsOpenAppLevelNav
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  // Sets a temp source so that features do not stay on map
  const [drawTempSource, setDrawTempSource] = useState<VectorSource>();

  useEffect(() => {
    setDrawTempSource(new VectorSource({ wrapX: false }));
  }, []);

  const {
    isLoading: drawToSelectDataLoading,
    data: drawToSelectData,
    // error: drawToSelectDataError,
    getSelectionData,
  } = useSelectionData();

  const { cartoClickProps } = useCartoClickProps();
  const { map } = useSagaMap();

  useEffect(() => {
    drawToSelectDataCallback({
      drawToSelectData,
      updateAlertState,
      ...cartoClickProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawToSelectData]);

  const drawToSelectCircleCallBack = (e: any, newDrawListener: any) => {
    if (e?.feature?.getGeometry() && map) {
      map.removeInteraction(newDrawListener);
      updateDrawToSelectMode(DRAW_MODE_NONE);

      const circleGeometry = e.feature.clone().getGeometry();
      const polygon = fromCircle(circleGeometry);

      const selectedPolygon4326 = polygon.transform("EPSG:3857", "EPSG:4326");

      const drawToSelectPolygon = {
        // coordinates: selectedPolygon4326.getCoordinates(),
        coordinates: [],
        type: "Polygon",
      };

      const extent = map.getView().calculateExtent(map.getSize());

      const polygonFromExtent = fromExtent(extent).transform(
        "EPSG:3857",
        "EPSG:4326"
      );

      const viewport = {
        // coordinates: polygonFromExtent.getCoordinates(),
        type: "Polygon",
      };

      getSelectionData({
        searchCriteria,
        polygonSelect: [drawToSelectPolygon],
        viewport,
      });
    }
  };

  const drawToSelectCallback = (e: any, newDrawListener: any) => {
    if (e?.feature?.getGeometry() && map) {
      map.removeInteraction(newDrawListener);
      updateDrawToSelectMode(DRAW_MODE_NONE);

      const selectedPolygon4326 = e.feature
        .clone()
        .getGeometry()
        .transform("EPSG:3857", "EPSG:4326");

      const drawToSelectPolygon = {
        coordinates: selectedPolygon4326.getCoordinates(),
        type: "Polygon",
      };

      const extent = map.getView().calculateExtent(map.getSize());

      const polygonFromExtent = fromExtent(extent).transform(
        "EPSG:3857",
        "EPSG:4326"
      );

      const viewport = {
        // coordinates: polygonFromExtent.getCoordinates(),
        type: "Polygon",
      };

      getSelectionData({
        searchCriteria,
        polygonSelect: [drawToSelectPolygon],
        viewport,
      });
    }
  };

  const drawPolygonCallback = (e: any, newDrawListener: any) => {
    const selectedPolygon = e.feature;
    const polygonId = `Polygon_${generateId(5).toString()}`;

    const selectedPolygon4326 = selectedPolygon
      .clone()
      .getGeometry()
      .transform("EPSG:3857", "EPSG:4326");

    const newPolygon: PolygonData = {
      id: polygonId,
      coordinates: selectedPolygon4326.getCoordinates(),
      isSelected: false,
      isShapeFile: false,
    };

    selectedPolygon.setProperties(newPolygon);

    addDrawnPolygon(newPolygon);
    map?.removeInteraction(newDrawListener);
    updateDrawPolygonMode(DRAW_MODE_NONE);
    if (!isOpenAppLevelNav) {
      toggleIsOpenAppLevelNav();
    }
  };

  const removePolygonFromSource = (
    polygon: PolygonData,
    source: VectorSource<Geometry> | null
  ) => {
    if (!source) return;

    const polygonToDelete = source
      .getFeatures()
      .find((feature: any) => feature.values_.id === polygon.id);

    if (polygonToDelete) {
      source.removeFeature(polygonToDelete);
    }
  };

  const removeAllPolygonsFromSource = (
    polygons: PolygonData[],
    source: VectorSource<Geometry> | null
  ) => {
    if (!source) return;

    polygons.forEach((polygon: any) => {
      removePolygonFromSource(polygon, source);
    });
  };

  const updateDrawMode = ({
    type,
    mode,
    listener,
    externalListener,
    updateListener,
    source,
    callback,
  }: any) => {
    if (!map || (!drawTempSource && !source)) return;
    if (listener) map.removeInteraction(listener);

    // DISABLE other draw category modes
    if (externalListener && mode) map.removeInteraction(externalListener);

    // RESET draw mode of other draw category(s)
    if (type === DRAW_TO_SELECT_MODE) updateDrawPolygonMode(null);
    if (type === DRAW_POLYGON_MODE) updateDrawToSelectMode(null);

    if (mode && mode !== DRAW_MODE_NONE) {
      let newDrawListener: any;
      let geometryFunction;

      switch (mode) {
        case DRAW_MODE_RECTANGLE:
          geometryFunction = createRegularPolygon(4);
          newDrawListener = new Draw({
            source: source || drawTempSource,
            type: DRAW_SELECT_DEFAULT,
            geometryFunction,
            stopClick: true,
            condition: (e) => noModifierKeys(e) && primaryAction(e),
          });
          break;

        case DRAW_MODE_CIRCLE:
          newDrawListener = new Draw({
            source: source || drawTempSource,
            type: DRAW_MODE_CIRCLE,
            stopClick: true,
            condition: (e) => noModifierKeys(e) && primaryAction(e),
          });
          break;

        case DRAW_MODE_POLYGON:
          newDrawListener = new Draw({
            source: source || drawTempSource,
            type: DRAW_MODE_POLYGON,
            stopClick: true,
            condition: (e) => noModifierKeys(e) && primaryAction(e),
          });
          break;

        default:
          break;
      }
      updateListener(newDrawListener);
      newDrawListener.on("drawend", (e: any) =>
        callback(e, newDrawListener, source)
      );
      map.addInteraction(newDrawListener);
    }
  };

  const drawPolygonDynamically = ({ id, coordinates, isSelected }: any) => {
    const polygon = new Feature({
      type: "Polygon",
      geometry: new Polygon(coordinates).transform("EPSG:4326", "EPSG:3857"),
    });

    const newPolygon = {
      id,
      isSelected,
      //   coordinates: polygon?.getGeometry()?.getCoordinates(),
    };

    polygon.setProperties(newPolygon);

    drawLayerSource?.addFeature(polygon);
  };

  return {
    updateDrawMode,
    drawToSelectCallback,
    drawToSelectCircleCallBack,
    drawToSelectDataLoading,
    drawPolygonCallback,
    drawPolygonDynamically,

    removeDrawnPolygon,
    removePolygonFromSource,
    removeAllPolygonsFromSource,
  };
};

export default useDrawPolygon;
