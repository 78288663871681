import { Polygon, PolygonData } from "../../../types/map/gis/common";
import {
  SavedAttributeSearch,
  SavedAttributeSort,
  SavedUWIFileUpload,
  SavedUWISearch,
} from "../../../types/panels/savedSearchPanel/savedSearchData";

import { IDENTIFIER_TAB } from "../../../constants/panels/searchPanel/common/accordion";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapDrawStore from "../../../store/map/draw/mapDrawStore";

import "../../../utils/common/boundsData";
import { getPolygonType } from "../../../utils/map/draw/polygon";

import useIdentifierFileUpload from "../../search/identifier/useIdentifierFileUpload";
import useDrawPolygon from "../../search/useDrawPolygon";
import useSearchStore from "../../search/useSearchStore";

// TODO: Decoupling | Handle old saved searches and change logic to accomodate new data structure
const useLoadSearches = () => {
  // TEMP: temporarily set to well
  // TODO: Update this to include Permits
  const searchStore = useSearchStore({ searchRecordType: RECORD_TYPES.WELL });
  const updateCurrentBounds = searchStore((state) => state.updateCurrentBounds);
  const loadQBInfo = searchStore((state) => state.loadQBInfo);
  const updateSearchTypeTab = searchStore((state) => state.updateSearchTypeTab);
  const updateIsLoadingSavedSearchFileUpload = searchStore(
    (state) => state.updateIsLoadingSavedSearchFileUpload
  );
  const updateIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIdentifierSearchUploadedFile
  );
  const updateIdentifierSearchUploadedFileId = searchStore(
    (state) => state.updateIdentifierSearchUploadedFileId
  );
  const updateIsFromIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIsFromIdentifierSearchUploadedFile
  );
  const loadSavedIdentifiers = searchStore(
    (state) => state.loadSavedIdentifiers
  );
  const { getIdentifierFileIdWithRetry } = useIdentifierFileUpload(
    RECORD_TYPES.WELL
  );
  // END TEMP: temporarily set to well

  const updatePolygons = useMapDrawStore((state) => state.updatePolygons);
  const updateShapeId = useMapDrawStore((state) => state.updateShapeId);

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );

  const { drawPolygonDynamically } = useDrawPolygon();

  const loadAttributeSearch = (
    attributeSearch?: SavedAttributeSearch,
    sortList?: SavedAttributeSort
  ) => {
    const bounds = attributeSearch?.bounds ?? [];

    // Saving for reference when loading old save searches
    // const recordTypeExists = bounds?.find(
    //   (attribute) => attribute.bound === RECORD_TYPE.key
    // );
    // if (!Boolean(recordTypeExists)) {
    //   const recordType = createRecordTypeBounds(RECORD_TYPES.WELL);
    //   bounds.push(recordType);
    // }

    if (bounds?.length) {
      loadQBInfo(bounds, sortList);
      updateCurrentBounds(bounds);
    }

    // Drawn Polygons
    const geometry = attributeSearch?.geometry;
    const coordinates = geometry?.coordinates;
    const polygons: Polygon = [];
    if (coordinates?.length) {
      for (const geometryData of coordinates) {
        const { id, isSelected, isShapeFile, coordinates } = geometryData;
        const polygon: PolygonData = {
          id,
          isSelected,
          isShapeFile: !!isShapeFile,
          coordinates: [
            coordinates.map((value) => [value.coordinateX, value.coordinateY]),
          ],
        };
        polygons.push(polygon);
        drawPolygonDynamically(polygon);
      }
    }
    updatePolygons(polygons);

    const shapeId = attributeSearch?.shapeId ?? "";
    updateShapeId(shapeId);

    const drawnPolygons = polygons.filter((p) => !p.isShapeFile);
    updateIsFromIdentifierSearchUploadedFile(false);
    return {
      currentBounds: bounds,
      drawnPolygons,
      polygonType: getPolygonType(drawnPolygons),
      shapeId,
    };
  };

  const loadUWISearch = (uwiSearch?: SavedUWISearch) => {
    const searchUWIState = { loadedUWIs: {}, isMoreThanLimit: false };
    if (!uwiSearch || (uwiSearch && !uwiSearch.searchUWIs.length)) {
      return searchUWIState;
    }
    const uwis = uwiSearch.searchUWIs;

    loadSavedIdentifiers(uwis);

    // TODO: Update searchTypeTab for Permits
    updateSearchTypeTab(IDENTIFIER_TAB);
    updateIsFromIdentifierSearchUploadedFile(false);
    searchUWIState.loadedUWIs = { searchedUWIs: uwis };
    return searchUWIState;
  };

  const loadUWIFileUpload = async (
    uwiFileUpload?: SavedUWIFileUpload,
    uwiSearch?: SavedUWISearch
  ) => {
    if (!uwiFileUpload) return {};
    if (uwiSearch) loadSavedIdentifiers(uwiSearch.searchUWIs, true);
    updateIsLoadingSavedSearchFileUpload(true);
    updateInitialGridSearchMade(true);
    toggleGrid(true);
    toggleGridHeader(true);
    try {
      const fileId = await getIdentifierFileIdWithRetry(
        uwiFileUpload?.fileName
      );
      updateIsFromIdentifierSearchUploadedFile(true);
      updateIdentifierSearchUploadedFile({ name: uwiFileUpload?.fileName });
      updateIdentifierSearchUploadedFileId(fileId);
      updateIsLoadingSavedSearchFileUpload(false);
      return { fileId };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  return {
    loadAttributeSearch,
    loadUWISearch,
    loadUWIFileUpload,
  };
};

export default useLoadSearches;
