import { FC } from "react";

import { Typography } from "@mui/material";

import classNames from "classnames";

import { Attribute } from "../../../../../../types/common/attributes";
import { RecordType } from "../../../../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../../../../constants/panels/searchPanel/search";

import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import QBEditorAttributes from "./QBEditorAttributes";

interface QBEditorAvailAttributesProps {
  searchRecordType: RecordType;
}

const QBEditorAvailAttributes: FC<QBEditorAvailAttributesProps> = ({
  searchRecordType,
}) => {
  const searchStore = useSearchStore({ searchRecordType });
  const removeToCurrentBounds = searchStore(
    (state) => state.removeToCurrentBounds
  );

  const currentQBInfo = searchStore((state) => state.currentQBInfo);
  const availableQBAttributes = searchStore(
    (state) => state.availableQBAttributes
  );
  const addToCurrentQBList = searchStore((state) => state.addToCurrentQBList);
  const removeToCurrentQBList = searchStore(
    (state) => state.removeToCurrentQBList
  );
  const resetCurrentQBInfo = searchStore((state) => state.resetCurrentQBInfo);

  const handleRemoveAttribute = (attribute: Attribute) => {
    if (currentQBInfo[attribute.key].values.length) {
      resetCurrentQBInfo(attribute.key, "options");
      resetCurrentQBInfo(attribute.key, "isUpdated");
    }
    removeToCurrentQBList(attribute);
    removeToCurrentBounds(attribute.key);
  };
  return (
    <div
      className={classNames("qb-editor-avail-container", {
        permits: searchRecordType === RECORD_TYPES.PERMIT,
      })}
    >
      {Object.entries(availableQBAttributes).map(([group, attributes]) =>
        attributes.length ? (
          <div key={group} className="qb-editor-avail-group">
            <Typography>{group}</Typography>
            <QBEditorAttributes
              attributes={attributes}
              handleAddAttribute={addToCurrentQBList}
              handleRemoveAttribute={handleRemoveAttribute}
            />
          </div>
        ) : null
      )}
    </div>
  );
};

export default QBEditorAvailAttributes;
