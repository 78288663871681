import { useCallback, useMemo } from "react";

import { CUM_BOE } from "../../../constants/attributes";
import { PERMIT_SPOTS, WELL_SPOTS } from "../../../constants/constants";
import { COLOR_BY_ATTRIBUTE_LIST } from "../../../constants/map/mapSettings";
import {
  COLOR_BY_PERMIT_ATTRIBUTES,
  COLOR_BY_WELLS_AND_PERMITS_ATTRIBUTES,
} from "../../../constants/map/permitStyling";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import useRecordType from "../../common/useRecordType";

const useLayerStylingByRecordType = () => {
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const layerLegendColors = useMapSettingsStore(
    (state) => state.layerLegendColors
  );
  const applyWellColorToPermits = useMapSettingsStore(
    (state) => state.applyWellColorToPermits
  );
  const updateWellStyleColors = useMapSettingsStore(
    (state) => state.updateWellStyleColors
  );
  const updatePermitStyleColors = useMapSettingsStore(
    (state) => state.updatePermitStyleColors
  );
  const updateAllLayerStyleColors = useMapSettingsStore(
    (state) => state.updateAllLayerStyleColors
  );

  const { searchedRecordTypes } = useRecordType();

  const layerStyleKeyByRecordType = useMemo(
    () =>
      searchedRecordTypes.includes(RECORD_TYPES.WELL)
        ? WELL_SPOTS
        : PERMIT_SPOTS,
    [searchedRecordTypes]
  );

  const layerStylesByRecordType = useMemo(
    () => layerStyles[layerStyleKeyByRecordType],
    [layerStyles, layerStyleKeyByRecordType]
  );

  const layerStylesColorByRecordType = useMemo(
    () => layerStylesByRecordType.color,
    [layerStylesByRecordType]
  );

  const legendColorsByRecordType = useMemo(
    () =>
      layerLegendColors[WELL_SPOTS].length
        ? layerLegendColors[WELL_SPOTS]
        : layerLegendColors[PERMIT_SPOTS],
    [layerLegendColors]
  );

  const attributesForColoringByRecordType = useMemo(() => {
    if (
      searchedRecordTypes.includes(RECORD_TYPES.WELL) &&
      searchedRecordTypes.includes(RECORD_TYPES.PERMIT)
    ) {
      return [...COLOR_BY_ATTRIBUTE_LIST, ...COLOR_BY_PERMIT_ATTRIBUTES];
    }
    if (searchedRecordTypes.includes(RECORD_TYPES.PERMIT)) {
      return [
        ...COLOR_BY_WELLS_AND_PERMITS_ATTRIBUTES,
        ...COLOR_BY_PERMIT_ATTRIBUTES,
      ];
    }
    return COLOR_BY_ATTRIBUTE_LIST;
  }, [searchedRecordTypes]);

  const shouldApplyColorToPermits = useMemo(() => {
    if (
      searchedRecordTypes.includes(RECORD_TYPES.WELL) &&
      searchedRecordTypes.includes(RECORD_TYPES.PERMIT)
    ) {
      return applyWellColorToPermits;
    } else if (searchedRecordTypes.includes(RECORD_TYPES.PERMIT)) {
      return true;
    }
    return false;
  }, [searchedRecordTypes, applyWellColorToPermits]);

  const isAttributeSupportedByLayer = useCallback(
    (layerName: string, attributeKey: string) => {
      let attributeList = COLOR_BY_ATTRIBUTE_LIST;
      if (layerName === PERMIT_SPOTS) {
        attributeList = [
          ...COLOR_BY_WELLS_AND_PERMITS_ATTRIBUTES,
          ...COLOR_BY_PERMIT_ATTRIBUTES,
        ];
      }

      const attributeKeys = attributeList.map((attribute) => attribute.key);
      return attributeKeys.includes(attributeKey);
    },
    []
  );

  const isColorBySharedAttribute = useCallback((attributeKey: string) => {
    const sharedAttributes = COLOR_BY_WELLS_AND_PERMITS_ATTRIBUTES.map(
      (attribute) => attribute.key
    );
    return sharedAttributes.includes(attributeKey);
  }, []);

  const isColorByPermitAttribute = useCallback((attributeKey: string) => {
    const permitAttributes = COLOR_BY_PERMIT_ATTRIBUTES.map(
      (attribute) => attribute.key
    );
    return permitAttributes.includes(attributeKey);
  }, []);

  const shouldApplyColorToLayer = useCallback(
    (layerName: string, attributeKey: string) => {
      const isSupported = isAttributeSupportedByLayer(layerName, attributeKey);
      if (layerName === PERMIT_SPOTS) {
        return (
          isColorByPermitAttribute(attributeKey) ||
          (shouldApplyColorToPermits && isColorBySharedAttribute(attributeKey))
        );
      }
      return isSupported;
    },
    [
      shouldApplyColorToPermits,
      isAttributeSupportedByLayer,
      isColorByPermitAttribute,
      isColorBySharedAttribute,
    ]
  );

  const updateLayerStyleColors = useMemo(() => {
    if (
      searchedRecordTypes.includes(RECORD_TYPES.WELL) &&
      searchedRecordTypes.includes(RECORD_TYPES.PERMIT)
    ) {
      return updateAllLayerStyleColors;
    } else if (searchedRecordTypes.includes(RECORD_TYPES.PERMIT)) {
      return updatePermitStyleColors;
    }
    return updateWellStyleColors;
  }, [
    searchedRecordTypes,
    updateAllLayerStyleColors,
    updatePermitStyleColors,
    updateWellStyleColors,
  ]);

  const getTopAttributeSortBy = useCallback(
    (attributeKey: string) => {
      if (
        searchedRecordTypes.includes(RECORD_TYPES.PERMIT) ||
        isColorByPermitAttribute(attributeKey)
      ) {
        return "PermitID";
      }
      return CUM_BOE.key;
    },
    [searchedRecordTypes, isColorByPermitAttribute]
  );

  return {
    layerStyleKeyByRecordType,
    layerStylesByRecordType,
    layerStylesColorByRecordType,

    legendColorsByRecordType,

    shouldApplyColorToPermits,
    attributesForColoringByRecordType,
    updateLayerStyleColors,
    shouldApplyColorToLayer,
    isColorBySharedAttribute,
    isColorByPermitAttribute,
    getTopAttributeSortBy,
  };
};

export default useLayerStylingByRecordType;
