export const FILE_ID = "fileId";
export const SEARCH_REQUEST = "searchRequest";
export const SEARCH_TYPE = "searchType";
export const CHART_TYPE = "chartType";
export const SEARCH_BOUNDS = "bounds";
export const ATTRIBUTE_SEARCH = "attribute";
export const GEOMETRY_SERACH = "geometry";
export const SHAPE_FILE_ID = "shapeId";

export const STATUS_ERROR_FOR_RETRY = "File ID does not exist in staging table";
