import { StateCreator } from "zustand";

import {
  MapWellSelectionStates,
  SelectedKeys,
  SelectedKeysWithData,
  WellSelectionStates,
} from "../../../types/map/selection/store";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import { getUpdatedSelectedStates } from "../../../utils/map/selection/wellSelection";

import { clone } from "../../../utils";
import useDataGridStore from "../../grid/dataGridStore";
import { resetGridWellSelectionSlice } from "./gridWellSelectionSlice";

const sliceResetFns = new Set<() => void>();

const resetMapWellSelectionSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialMapWellSelectionStates = {
  selectedMapParentWellIDs: {},
  selectedBottomWellboreIDs: {},
};

const mapWellSelectionSlice: StateCreator<
  WellSelectionStates,
  [],
  [],
  MapWellSelectionStates
> = (set, get) => {
  sliceResetFns.add(() => set(clone(initialMapWellSelectionStates)));
  return {
    ...initialMapWellSelectionStates,
    updateSelectedMapParentWellIDs: (selectedMapParentWellIDs) =>
      set(() => ({ selectedMapParentWellIDs })),
    addSelectedMapParentWellID: (PWID) =>
      set((state) => {
        const familyWells = useDataGridStore
          .getState()
          .allGridData.filter(
            (data) =>
              data.ParentWellID === PWID &&
              data.RecordType === RECORD_TYPES.WELL
          );

        const newStates = getUpdatedSelectedStates(state, familyWells);
        return newStates;
      }),
    addSelectedMapParentWellIDs: (PWIDs) =>
      set((state) => {
        const familyWells = useDataGridStore
          .getState()
          .allGridData.filter(
            (data) =>
              !!PWIDs[data.ParentWellID] &&
              data.RecordType === RECORD_TYPES.WELL
          );

        const newStates = getUpdatedSelectedStates(state, familyWells);
        return newStates;
      }),
    updateSelectedBottomWellboreIDs: (selectedBottomWellboreIDs) =>
      set(() => ({ selectedBottomWellboreIDs })),
    addSelectedBottomWellboreID: (selectedBottomWellboreID) =>
      set((state) => {
        const wellBoreCompletionWells = useDataGridStore
          .getState()
          .allGridData.filter(
            (data) =>
              data.BottomWellboreID === selectedBottomWellboreID &&
              data.RecordType === RECORD_TYPES.WELL
          );

        const newStates = getUpdatedSelectedStates(
          state,
          wellBoreCompletionWells
        );
        return newStates;
      }),
    addMapSelectedPermitIDs: (selectedPermitIDs) => {
      const selectedPermitData = useDataGridStore
        .getState()
        .allGridData.filter(
          (data) =>
            selectedPermitIDs[data.WellHeaderPermitKey] &&
            data.RecordType === RECORD_TYPES.PERMIT
        );
      const permitIds: SelectedKeys = {};
      const permitData: SelectedKeysWithData = {};
      const permitIdKeys: number[] = [];

      selectedPermitData.forEach((data) => {
        if (data.PermitID) {
          const mapData = {
            WellID: data.WellID,
            PermitID: data.PermitID,
            ParentWellID: data.ParentWellID,
            BottomWellboreID: data.BottomWellboreID,
            ...(data.UWI && { UWI: data.UWI }), //make UWI optional
            RecordType: data.RecordType,
          };
          const key = data.WellHeaderPermitKey;
          permitIds[key] = data.PermitID;
          permitData[key] = mapData;
          permitIdKeys.push(data.PermitID);
        }
      });

      get().addSelectedPermitIds(permitIds, permitData, permitIdKeys);
    },

    resetAllMapSelections: () => {
      resetMapWellSelectionSlice();
      resetGridWellSelectionSlice();
    },
  };
};

export { mapWellSelectionSlice, resetMapWellSelectionSlice };
