import { SearchTypesMapper } from "../../../types/panels/searchPanel/search";

export const SEARCH_TYPES: SearchTypesMapper = {
  ATTRIBUTE_SEARCH: "Attribute Search",
  IDENTIFIER_PASTED_SEARCH: "Identifier Search",
  IDENTIFIER_FILE_SEARCH: "File Upload Search",
  IDENTIFIER_CONVERTED_FILE_SEARCH: "Identifier Converted File Search",
};

export const IDENTIFIER_SEARCH_TYPES = [
  SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH,
  SEARCH_TYPES.IDENTIFIER_FILE_SEARCH,
  SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH,
];

export const RECORD_TYPES = {
  WELL: "Well",
  PERMIT: "Permit",
} as const;

//Saving for reference when handling old saved searches
// IDENTIFIER_PASTED_SEARCH: "UWI Search",
//   IDENTIFIER_FILE_SEARCH: "File Upload Search",
//   IDENTIFIER_CONVERTED_FILE_SEARCH: "UWI Converted File Search",
