import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Plot from "react-plotly.js";

import { Data } from "plotly.js";

import { ScatterPlotColorByProps } from "../../../types/charts/chartHooks/scatterPlotData";
import {
  AxisRange,
  ChartAxisType,
  IChartTypeInfo,
  IScatterPlotChartData,
  ITraceData,
  ScatterPlotChartProps,
  ScatterPlotWellChartData,
} from "../../../types/charts/chartType/chartType";
import { Modules } from "../../../types/modularity/modules";

import {
  DATE,
  FLOAT,
  INTEGER,
  SPECIAL,
  TEXT,
} from "../../../constants/attributes";
import { CHART_MODE_VIEW } from "../../../constants/charts/chartModeView";
import { CHART_AXIS, CHART_TYPES } from "../../../constants/charts/charts";
import { SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES } from "../../../constants/charts/scatterPlotDataFields";

//Store
import useChartStore from "../../../store/chart/chartStore";
import useMapSelectionStore from "../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../store/modularity/modularityStore";
import useStore from "../../../store/useStore";

//CustomHooks
import useAnalysisChartRefetch from "../../../customHooks/charts/useAnalysisChartRefetch";
import { useChartData } from "../../../customHooks/charts/useChartData";
import { useChartTopAttribute } from "../../../customHooks/charts/useChartTopAttributes";
import { useScatterPlotChartData } from "../../../customHooks/charts/useScatterPlotChartData";
import useScatterPlotChartReset from "../../../customHooks/charts/useScatterPlotChartReset";
import { useScatterPlotColorByChartData } from "../../../customHooks/charts/useScatterPlotColorByChartData";
import useRecordType from "../../../customHooks/common/useRecordType";
import useExportToExcel from "../../../customHooks/exports/useExportToExcel";
import { useScatterPlotExport } from "../../../customHooks/exports/useScatterPlotExport";
import usePrevious from "../../../customHooks/usePrevious";

import {
  config,
  fontLayout,
  generalLayout,
  legendLayout,
  marginLayout,
  xAxisLayout,
  yAxisLayout,
} from "../../../utils/charts/layouts/ScatterPlotLayout";
import { truncateString } from "../../../utils/stringUtils";

import { clone } from "../../../utils";
import LoadingBackDrop from "../../common/LoadingBackDrop";
import { TGSLogo } from "../../common/icons";
import ChartTypeHeader from "./chartTypeHeader/ChartTypeHeader";

const ScatterPlotChart: React.FC<ScatterPlotChartProps> = ({
  chartType,
  chartId,
  onChartFullScreenClose,
  chartMode,
  isChartExport = false,
}) => {
  //Hooks
  const { refetch, updateRefetch } = useAnalysisChartRefetch({
    chartMode,
    chartId,
    chartType,
  });

  const {
    isLoading,
    data,
    getChartData,
    createTrace,
    formatXTicks,
    createDefaultTrace,
    mapTraceData,
  } = useScatterPlotChartData();
  const { getChartTopAttributes, data: topAttributes } = useChartTopAttribute();
  const { createNumericOrDateTrace, partitions, createVarCharTrace } =
    useScatterPlotColorByChartData();

  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );

  const chartData = useChartStore((state) => state.chartData);
  const getDataFlag = useChartStore((state) => state.chartDataRefetchFlag);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );

  const isFullScreenAnalysisPane = useModularityStore(
    (state) => state.isFullScreenAnalysisPane
  );
  const isFullScreenIndividualChart = useModularityStore(
    (state) => state.isFullScreenIndividualChart
  );
  const fullScreenChartId = useModularityStore(
    (state) => state.fullScreenChartId
  );
  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const [plotlyLayout, setPlotlyLayout] = useState<any>();
  const [currentTickData, setCurrentTickData] = useState<number[]>([]);
  const [chartRendered, setChartRendered] = useState<boolean>(false);
  const [plotlyDataInfo, setPlotlyDataInfo] = useState<Data[]>([]);
  const [currentChartData, setCurrentChartData] = useState<
    IScatterPlotChartData | undefined
  >(undefined);
  const [isTraceLoading, setIsTraceLoading] = useState(false);
  const chartRef = useRef<any>();
  const [shouldFormatXTicks, setShouldFormatXTicks] = useState<boolean>(false);
  const { onLegendClick } = useChartData();
  const { parseDataForExport, generateExportFileName } = useScatterPlotExport();
  const { exportToExcel } = useExportToExcel();
  const { searchedRecordTypes } = useRecordType();
  const { resetChartData } = useScatterPlotChartReset({
    chartId,
    chartMode,
    isChartExport,
  });
  const prevRecordTypes = usePrevious(searchedRecordTypes);

  useEffect(() => {
    if (!chartId) return;
    const chart = chartData?.find((data) => data.chartId === chartId) as
      | IScatterPlotChartData
      | undefined;
    if (chart) {
      setCurrentChartData(chart);
      if (chart.chartData.length) {
        setShouldFormatXTicks(
          !formatXTicks(chart.chartData, chart.chartXAxisDataFields?.dataType)
        );
      } else {
        setShouldFormatXTicks(false);
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (refetch && currentChartData) {
      getChartData(currentChartData);
    }
  }, [refetch]);

  const updateModuleRefetch = () => {
    const chartModule: Modules | undefined = (clone(modules) as Modules[]).find(
      (module) => module.module === chartId
    );

    if (chartModule) {
      chartModule.refetch = false;

      updateModuleDataByKey(chartId, chartModule);
    }

    updateRefetch(false);
  };

  const updateChartTrace = (chartInfo?: IChartTypeInfo) => {
    if (chartInfo) {
      updateChartDataByKey(chartId, chartInfo);
    }

    updateModuleRefetch();

    if (getDataFlag.refetch) {
      updateGetDataFlag({ chartId: "", refetch: false });
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    if (
      data?.length &&
      currentChartData?.chartColorBy &&
      (currentChartData.chartColorBy.attibuteDataType === SPECIAL ||
        currentChartData?.chartColorBy.attibuteDataType === TEXT) &&
      currentChartData.chartColorBy.attributeKey &&
      topAttributes
    ) {
      const chartDataProps: ScatterPlotColorByProps = {
        data,
        xAxisField: currentChartData.chartXAxisDataFields,
        yAxisFields: currentChartData.chartYAxisDataFields,
        chartColorByInfo: currentChartData.chartColorBy,
      };
      const trace = createVarCharTrace(topAttributes, chartDataProps);

      const modifiedChartData: IChartTypeInfo[] = clone(chartData).map(
        (chart: IChartTypeInfo) => {
          if (
            chart.chartId === chartId &&
            chart.objectType === CHART_TYPES.SCATTER_PLOT
          ) {
            chart.chartData = trace;
            chart.chartRawData = data;

            //reset partitions
            if (chart.chartColorBy) {
              chart.chartColorBy.minMax = {
                min: null,
                max: null,
                hasNull: false,
              };
              chart.chartColorBy.attributesList = topAttributes;
            }
          }
          return chart;
        }
      );
      const updatedChartData: IChartTypeInfo | undefined =
        modifiedChartData.find((data) => data.chartId === chartId);

      updateChartTrace(updatedChartData);
    }
  }, [topAttributes]);

  useEffect(() => {
    if (!data) {
      setPlotlyDataInfo([]);
      return;
    }

    if (!selectedGridDataKeys.length) {
      updateModuleRefetch();
      return;
    }

    setIsTraceLoading(true);
    if (
      (currentChartData?.chartColorBy?.attibuteDataType === SPECIAL ||
        currentChartData?.chartColorBy?.attibuteDataType === TEXT) &&
      currentChartData.chartColorBy.attributeKey &&
      currentChartData?.chartColorBy?.toggle
    ) {
      getChartTopAttributes(
        currentChartData.chartColorBy.attributeKey,
        currentChartData.chartType
      );
      return;
    }

    //this is where we format chart trace
    let transformedScatterData: ITraceData[] = [];
    if (currentChartData?.chartColorBy?.toggle) {
      const chartDataProps: ScatterPlotColorByProps = {
        data,
        xAxisField: currentChartData.chartXAxisDataFields,
        yAxisFields: currentChartData.chartYAxisDataFields,
        chartColorByInfo: currentChartData.chartColorBy,
      };
      transformedScatterData = createNumericOrDateTrace(chartDataProps);
    } else {
      transformedScatterData = createTrace(
        data,
        currentChartData?.chartXAxisDataFields,
        currentChartData?.chartYAxisDataFields ?? []
      );
    }

    const modifiedChartData: IChartTypeInfo[] = clone(chartData).map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.SCATTER_PLOT
        ) {
          chart.chartData = transformedScatterData;
          chart.chartRawData = data;
        }
        return chart;
      }
    );
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    updateChartTrace(updatedChartData);
  }, [data]);

  useEffect(() => {
    if (!partitions) {
      return;
    }
    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.SCATTER_PLOT &&
          chart.chartColorBy
        ) {
          chart.chartColorBy.minMax = partitions;
        }
        return chart;
      }
    );

    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }
  }, [partitions]);

  useEffect(() => {
    if (!currentChartData?.chartData.length || !currentChartData) {
      const defaultTrace: Data[] = [];

      currentChartData?.chartYAxisDataFields.forEach((field) => {
        const trace = createDefaultTrace(field.displayName, field.axis);
        defaultTrace.push(trace);
      });

      setPlotlyDataInfo(defaultTrace);
      return;
    }

    if (
      ((fullScreenChartId === chartId &&
        isFullScreenIndividualChart &&
        chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART) ||
        (isFullScreenAnalysisPane &&
          chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS)) &&
      currentChartData?.chartData
    ) {
      const copiedChartData: ITraceData[] = clone(currentChartData.chartData);

      const mappedData: Data[] = copiedChartData?.map((trace: ITraceData) => {
        const traceName = `${trace.id} ${
          trace.unit !== "" ? `, ${trace.unit}` : ""
        }`;
        const mappedObjData = mapTraceData(
          trace,
          traceName,
          currentChartData.chartYAxisDataFields.find(
            (field) => field.name === trace.attributeId
          )?.axis
        );

        return mappedObjData;
      });

      setPlotlyDataInfo(mappedData);
    } else if (
      currentChartData?.chartData &&
      chartMode === CHART_MODE_VIEW.DEFAULT
    ) {
      const copiedChartData: ITraceData[] = clone(currentChartData.chartData);

      const mappedData: Data[] = copiedChartData?.map((trace: ITraceData) => {
        const traceName = !isChartExport
          ? `${truncateString(trace.id, 12)} ${
              trace.unit !== "" ? `, ${trace.unit}` : ""
            }`
          : `${trace.id} ${trace.unit !== "" ? `, ${trace.unit}` : ""}`;

        const mappedObjData = mapTraceData(
          trace,
          traceName,
          currentChartData.chartYAxisDataFields.find(
            (field) => field.name === trace.attributeId
          )?.axis
        );

        return mappedObjData;
      });

      setPlotlyDataInfo(mappedData);
    }
  }, [
    currentChartData,
    chartMode,
    isFullScreenAnalysisPane,
    isFullScreenIndividualChart,
    fullScreenChartId,
  ]);

  useEffect(() => {
    //Triggers when reLayout throws a callback [NOTE: Even on new generation, it triggers]
    const layout = plotlyLayout;
    let layoutCount = 0;
    //Check if layout contains the right axis values so we count the length, length should be more than 1. [Expected: 4, 2 items per axis]
    if (layout) {
      layoutCount = Object.keys(layout).length;
    }
    if (layoutCount > 1) {
      const xAxisLower = layout?.["xaxis.range[0]"];
      const xAxisUpper = layout?.["xaxis.range[1]"];
      const yAxisLower = layout?.["yaxis.range[0]"];
      const yAxisUpper = layout?.["yaxis.range[1]"];
      const yAxis2Lower = layout?.["yaxis2.range[0]"];
      const yAxis2Upper = layout?.["yaxis2.range[1]"];
      const xLegend = layout?.["legend.x"];
      const yLegend = layout?.["legend.y"];

      const copiedChartData: IChartTypeInfo[] = clone(chartData);

      const newChartData = copiedChartData.map((chart) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.SCATTER_PLOT
        ) {
          let mappedXRange: AxisRange = [undefined, undefined];
          if (xAxisLower && xAxisUpper) {
            mappedXRange = [xAxisLower, xAxisUpper];
          } else {
            if (chart.chartRange?.xRange) {
              mappedXRange = chart.chartRange?.xRange;
            }
          }

          let mappedYRange: AxisRange = [undefined, undefined];
          if (yAxisLower && yAxisUpper) {
            mappedYRange = [yAxisLower, yAxisUpper];
          } else {
            if (chart.chartRange?.xRange) {
              mappedYRange = chart.chartRange?.yRange;
            }
          }

          let mappedY2Range: AxisRange | undefined = [undefined, undefined];
          if (yAxis2Lower && yAxis2Upper) {
            mappedY2Range = [yAxis2Lower, yAxis2Upper];
          } else {
            if (chart.chartRange?.xRange) {
              mappedY2Range = chart.chartRange?.yRange2;
            }
          }

          let mappedXLegend = undefined;
          if (xLegend) {
            mappedXLegend = xLegend;
          } else {
            if (chart.chartRange?.xLegend) {
              mappedXLegend = chart.chartRange?.xLegend;
            }
          }

          let mappedYLegend = undefined;
          if (yLegend) {
            mappedYLegend = yLegend;
          } else {
            if (chart.chartRange?.yLegend) {
              mappedYLegend = chart.chartRange?.yLegend;
            }
          }

          chart.chartRange = {
            xRange: mappedXRange,
            yRange: mappedYRange,
            ...(mappedY2Range && {
              yRange2: mappedY2Range,
            }),
            autorange: false,
            ...(mappedXLegend && {
              xLegend: mappedXLegend,
            }),
            ...(mappedYLegend && {
              yLegend: mappedYLegend,
            }),
          };
        }
        return chart;
      });

      const updatedChartData: IChartTypeInfo | undefined = newChartData.find(
        (data) => data.chartId === chartId
      );

      if (updatedChartData) {
        updateChartDataByKey(chartId, updatedChartData);
      }
    }
  }, [plotlyLayout]);

  useEffect(() => {
    if (fetchedSavedSearchTrigger) return;

    if (!selectedGridDataKeys.length) {
      setPlotlyDataInfo([]);

      // TODO: Double check if this is correct.
      // TEMP: comparing via stringified arrays
      if (
        JSON.stringify(searchedRecordTypes) === JSON.stringify(prevRecordTypes)
      ) {
        resetChartData(searchedRecordTypes, true);
        return;
      }
      // if (
      //   (searchedRecordType === RECORD_TYPES.WELL &&
      //     prevRecordType !== RECORD_TYPES.WELL) ||
      //   (searchedRecordType === RECORD_TYPES.PERMIT &&
      //     prevRecordType !== RECORD_TYPES.PERMIT) ||
      //   (searchedRecordType === RECORD_TYPES.WELLS_AND_PERMIT &&
      //     prevRecordType !== RECORD_TYPES.WELLS_AND_PERMIT)
      // ) {
      //   resetChartData(searchedRecordType, true);
      //   return;
      // }

      const modifyChartData = clone(chartData);
      const updatedChartData = modifyChartData.find(
        (data: IChartTypeInfo) => data.chartId === chartId
      );
      updatedChartData.chartData = [];
      updatedChartData.chartRawData = [];

      //reset partitions
      if (
        updatedChartData.objectType === CHART_TYPES.SCATTER_PLOT &&
        updatedChartData.chartColorBy
      ) {
        updatedChartData.chartColorBy.minMax = {
          min: null,
          max: null,
          hasNull: false,
        };
        updatedChartData.chartColorBy.attributesList = [];
      }

      updateChartDataByKey(chartId, updatedChartData);
    }
  }, [selectedGridDataKeys, searchedRecordTypes, fetchedSavedSearchTrigger]);

  const onChartRelayout = (figure: any) => {
    if (isChartExport) {
      setChartRendered(true);
    }
    if (figure?.autosize) {
      //on initialization, first value of the callBack is just {autosize: true} so we should negate the call
      return;
    }
    setPlotlyLayout(figure);
  };

  const handleResetChart = () => {
    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.SCATTER_PLOT
        ) {
          chart.chartRange = undefined;
        }
        return chart;
      }
    );
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }
  };

  const chartExportTitle: JSX.Element = useMemo(() => {
    if (!isChartExport) return <></>;

    return (
      <div className="chart-name">
        {currentChartData?.title === ""
          ? chartType + "_" + chartId + " | "
          : currentChartData?.title + " | "}
        <div className="chart-type">{chartType}</div>
      </div>
    );
  }, [isChartExport, currentChartData]);

  const chartExportLogo: JSX.Element = useMemo(() => {
    if (!isChartExport) return <></>;
    return (
      <div className="tgs-logo-watermark">
        <TGSLogo />
      </div>
    );
  }, [isChartExport]);

  const getScaling = useCallback(
    (yAxis: ChartAxisType) => {
      return currentChartData?.chartAxis.find((axis) => axis.name === yAxis)
        ?.scale;
    },
    [currentChartData?.chartAxis]
  );

  const getYAxisLabel = useCallback(
    (yAxis: ChartAxisType) => {
      const filteredYAxisFields = currentChartData?.chartYAxisDataFields.filter(
        (field) => field.axis === yAxis
      );
      const chartAxis = currentChartData?.chartAxis.find(
        (axis) => axis.name === yAxis
      );
      if (chartAxis?.title) return chartAxis?.title;

      if (filteredYAxisFields?.length === 1) {
        return filteredYAxisFields[0].displayName; //get the field display name
      }

      return "";
    },
    [currentChartData?.chartYAxisDataFields, currentChartData?.chartAxis]
  );

  const showLegend = useMemo(() => {
    if (!currentChartData) {
      return false;
    }
    return (
      currentChartData?.chartYAxisDataFields?.length > 1 ||
      Boolean(currentChartData.chartColorBy?.toggle)
    );
  }, [
    currentChartData?.chartYAxisDataFields,
    currentChartData?.chartColorBy?.toggle,
  ]);

  useEffect(() => {
    setIsTraceLoading(false);
  }, [plotlyDataInfo]);

  const legendTitle = useMemo(() => {
    let titleText = "";

    if (
      currentChartData?.chartColorBy?.attributeKey &&
      currentChartData.chartColorBy.toggle
    ) {
      titleText =
        SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES.find(
          (attr) => attr.key === currentChartData.chartColorBy?.attributeKey
        )?.label ?? "";
    }

    return titleText;
  }, [
    currentChartData?.chartColorBy?.attributeKey,
    currentChartData?.chartColorBy?.toggle,
  ]);

  const onExportData = useCallback(() => {
    const fileName = generateExportFileName(
      chartType,
      chartId,
      currentChartData?.title
    );

    const dataForExport = parseDataForExport(
      currentChartData?.chartRawData as ScatterPlotWellChartData[] | null,
      currentChartData?.chartXAxisDataFields,
      currentChartData?.chartYAxisDataFields,
      currentChartData?.chartColorBy
    );

    exportToExcel(dataForExport, fileName);
  }, [
    currentChartData?.chartRawData,
    currentChartData?.chartYAxisDataFields,
    currentChartData?.chartXAxisDataFields,
    currentChartData?.title,
    currentChartData?.chartColorBy,
  ]);

  return (
    <>
      {!refetch && !isLoading && !isTraceLoading ? (
        <>
          <ChartTypeHeader
            chartId={chartId}
            isChartExport={isChartExport}
            chartMode={chartMode}
            onChartFullScreenClose={onChartFullScreenClose}
            chartRendered={chartRendered}
            chartRef={chartRef}
            onChartExportData={onExportData}
          />
          <div
            className="plot-chart-container scatter-plot-chart"
            id={chartId}
            ref={chartRef}
          >
            {chartExportTitle}
            <Plot
              onDoubleClick={handleResetChart}
              divId={chartType}
              className="plot-chart"
              useResizeHandler={true}
              data={plotlyDataInfo}
              config={config}
              layout={{
                ...generalLayout(
                  chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS ||
                    chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART,
                  showLegend
                ),
                margin: {
                  ...marginLayout,
                },
                font: {
                  ...fontLayout,
                },
                legend: {
                  ...legendLayout(
                    currentChartData?.chartRange,
                    chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS ||
                      chartMode ===
                        CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART ||
                      isChartExport,
                    legendTitle
                  ),
                  ...(currentChartData?.chartColorBy?.toggle &&
                    (currentChartData.chartColorBy.attibuteDataType === FLOAT ||
                      currentChartData.chartColorBy.attibuteDataType ===
                        INTEGER ||
                      currentChartData.chartColorBy.attibuteDataType ===
                        DATE) && {
                      tracegroupgap: 1,
                      traceorder: "reversed",
                    }),
                },
                xaxis: {
                  ...xAxisLayout(
                    currentChartData?.chartRange,
                    currentChartData?.chartXAxisDataFields?.displayName,
                    currentChartData?.chartXAxisDataFields?.dataType,
                    shouldFormatXTicks
                  ),
                },
                yaxis: {
                  ...yAxisLayout(
                    getScaling(CHART_AXIS.LEFT),
                    currentChartData?.chartRange,
                    currentTickData,
                    getYAxisLabel(CHART_AXIS.LEFT),
                    CHART_AXIS.LEFT
                  ),
                },
                yaxis2: {
                  ...yAxisLayout(
                    getScaling(CHART_AXIS.RIGHT),
                    currentChartData?.chartRange,
                    currentTickData,
                    getYAxisLabel(CHART_AXIS.RIGHT),
                    CHART_AXIS.RIGHT
                  ),
                  overlaying: "y",
                  side: "right",
                },
              }}
              onRelayout={(figure: any) => {
                onChartRelayout(figure);
              }}
              onLegendClick={(e) => {
                onLegendClick(chartId, e, true);
                return true;
              }}
            />
            {isChartExport && chartExportLogo}
          </div>
        </>
      ) : (
        <LoadingBackDrop
          className={`plot-chart-area ${
            fullScreenChartId === chartId &&
            isFullScreenIndividualChart &&
            chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART
              ? "full-screen-chart"
              : ""
          } loader`}
          isOpen
        />
      )}
    </>
  );
};

export default ScatterPlotChart;
