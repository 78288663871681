import {
  DateValidationError,
  LocalizationProvider,
  DateField as MUIDateField,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FieldChangeHandler } from "@mui/x-date-pickers/internals";

import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";

interface DateFieldProps {
  label: string;
  value: Dayjs | Dayjs[] | null;
  handleChange: FieldChangeHandler<any, DateValidationError>;
  handleError: (error: DateValidationError, value: any) => void;
  handleBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  className?: string;
}

const DateField: React.FC<DateFieldProps> = ({
  label,
  value,
  handleChange,
  handleError,
  handleBlur = () => {
    return;
  },
  minDate = dayjs("01/01/1800"),
  maxDate = dayjs("12/31/2099"),
  className,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDateField
        label={label}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChange}
        onError={handleError}
        variant="outlined"
        onBlur={handleBlur}
        className={classNames(
          "custom-textfield has-placeholder w-100",
          className
        )}
        InputLabelProps={{ shrink: true }}
      />
    </LocalizationProvider>
  );
};

export default DateField;
