export const FILE_EXTENSIONS = {
  TEXT: ".txt",
  CSV: ".csv",
  EXCEL: ".xlsx",
} as const;

export const FILE_TYPES = {
  TEXT: "text/plain",
  CSV: "text/csv",
};
